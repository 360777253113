import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row, Modal} from 'react-bootstrap';
import './Signup.scss';
import Panel from '../../components/Panel';
import FormCampInput from '../../components/FormCampInput';
import z from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {regex} from '../../constants/Regexs';
import {
  useInvestigatorSignupMutation,
  useSearchInvestigatorMutation,
} from '../../redux/services/auth.api';
import {useNavigate} from 'react-router-dom';
import Paths from '../../constants/Paths';
import {toast} from 'react-toastify';
import {ServiceResponse} from '../../types/Dtos/ServiceResponse';
import {
  OutInvestigatorSignupDto,
  OutSearchInvestigatorDto,
} from '../../types/Dtos/UserDtos';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectConvocation,
  updateConvocation,
} from '../../redux/slice/inv.slice';
import {useGetCurrentConvocationMutation} from '../../redux/services/inv.api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields},
    reset,
    //watch, //get the current value of the fields if needed
  } = useForm<SignupInfo>({
    resolver: zodResolver(singupSchema),
    mode: 'onSubmit',
  });
  const [investigatorSignup, {isLoading: isSignupLoading}] =
    useInvestigatorSignupMutation();

  const [searchInvstigator, {isLoading: isSearchInvesigatorLoading}] =
    useSearchInvestigatorMutation();

  const [userInfo, setUserInfoValues] = useState<SignupInfo | undefined>(
    undefined,
  );
  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;
  const [modalShow, setModalShow] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [user, setUser] = useState<UserConfirmation | undefined>(undefined);

  //const onSubmitYes: SubmitHandler<SignupInfo> = data => handleSignupSubmit(data);

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const onSubmit = () => {
    if (userInfo) handleSignupSubmit(userInfo);
  };

  const handleFindUser = async (sendData: SignupInfo) => {
    const responseData = await searchInvstigator(sendData);
    let userData = undefined;
    if ('data' in responseData) {
      userData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error
        .data as ServiceResponse<OutSearchInvestigatorDto>;
    }

    if (userData?.success) {
      if (userData.data) {
        setUser({...userData.data.user, email: userData.data.email});
        setUserInfoValues(sendData);
        setModalShow(true);
      } else {
        toast.error(userData.message);
      }
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleSignupSubmit = async (sendData: SignupInfo) => {
    const responseData = await investigatorSignup(sendData);

    let signUpData = undefined;
    if ('data' in responseData) {
      signUpData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error
        .data as ServiceResponse<OutInvestigatorSignupDto>; //cambiar esto?
    }

    if (signUpData?.success) {
      toast.success(
        'Se ha registrado de manera exitosa\nSe le ha enviado un correo de confirmación',
        {
          position: 'top-right',
        },
      );
      navigate(Paths.LOGIN);
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleCancel = () => {
    setModalShow(false);
    setUserInfoValues(undefined);
    setAccepted(false);
    reset();
  };

  useEffect(() => {
    checkConvocation();
  }, []);

  return (
    <>
      <Container fluid className="p-0 signup-container main-container">
        <Container fluid className="panel-container h-100 d-flex flex-column">
          <Row className="h-100">
            <Panel
              xs={12}
              lg={12}
              overrideContainerProps={{fluid: true, className: 'panel-signup'}}
              overridePanelStyle={{background: '#FFFFFFEE'}}>
              <div className="d-flex flex-column justify-content-center h-100 p-3">
                <Row xs={12}>
                  {currentConvocation && !isRegistrationActive && (
                    <>
                      <Col
                        xs={{offset: 2, span: 8}}
                        className="closed-convocation-notif p-2">
                        <FontAwesomeIcon icon={faCircleExclamation} /> &nbsp; La
                        fecha de registro de la convocatoria ha terminado.
                      </Col>
                    </>
                  )}
                  <p className="text-center mb-1 login-text">
                    Registro de Investigador
                  </p>
                  <p className="text-secondary m-4 texto-info w-75">
                    Para crear tu cuenta en ProACTI, ingresa el número de
                    empleado y contraseña de SIASE
                  </p>
                </Row>
                <Row xs={12} className="px-3">
                  <Col className="d-flex flex-row align-items-end px-3" xs={12}>
                    <Form
                      id="signupForm"
                      onSubmit={handleSubmit(handleFindUser)}
                      className="w-100">
                      <FormCampInput
                        name="employeeNumber"
                        displayName="Número de Empleado"
                        inputProps={{
                          type: 'text',
                          ...register('employeeNumber'),
                          disabled: !isRegistrationActive,
                        }}
                        autoComplete="username"
                        errors={errors}
                        touchedFields={touchedFields}
                      />
                      <FormCampInput
                        name="password"
                        displayName="Contraseña"
                        formGroupClassName=""
                        inputProps={{
                          type: 'password',
                          ...register('password'),
                          disabled: !isRegistrationActive,
                        }}
                        autoComplete="current-password"
                        errors={errors}
                        touchedFields={touchedFields}
                      />
                    </Form>
                  </Col>
                </Row>
                <div className="d-flex flex-row col-12 pt-2 pe-3 align-items-center">
                  <Button
                    className="ms-auto"
                    variant="primary"
                    type="submit"
                    form="signupForm"
                    disabled={
                      isSearchInvesigatorLoading || !isRegistrationActive
                    }>
                    Buscar
                  </Button>
                </div>
                <Row className="px-3 pt-3 texto-info">
                  <Col className="d-flex flex-row" xs={12}>
                    <a href="/PROACTI.pdf" target="_blank">
                      Convocatoria ProACTI 2024
                    </a>
                    <a
                      className="ms-auto"
                      href="/aviso_privacidad.pdf"
                      target="_blank">
                      Aviso de Privacidad
                    </a>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Row>
        </Container>
      </Container>
      {/* <LoadingSpinner
        show={isSearchInvesigatorLoading || isSignupLoading}
        spinnerColor="white"
        backgroundColor="#0005"
      /> */}
      <Modal show={modalShow} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Si su información es correcta, acepte el aviso de privacidad y dé
            clic en registrar
          </p>
          <FormCampInput
            size={'sm'}
            displayName="Número de empleado"
            name="employeeNumber2"
            inputProps={{
              value: user?.employeeNumber,
              disabled: true,
              className: 'inv-pro-input',
            }}
          />
          <FormCampInput
            size={'sm'}
            displayName="Nombre"
            name="name"
            inputProps={{
              value: user?.name,
              disabled: true,
              className: 'inv-pro-input',
            }}
          />
          <FormCampInput
            size={'sm'}
            displayName="Primer Apellido"
            name="firstSurname"
            inputProps={{
              value: user?.firstSurname,
              disabled: true,
              className: 'inv-pro-input',
            }}
          />
          <FormCampInput
            size={'sm'}
            displayName="Segundo Apellido"
            name="secondSurname"
            inputProps={{
              value: user?.secondSurname,
              disabled: true,
              className: 'inv-pro-input',
            }}
          />
          <FormCampInput
            size={'sm'}
            displayName="Correo Institucional"
            name="email"
            inputProps={{
              value: user?.email,
              disabled: true,
              className: 'inv-pro-input',
            }}
          />
          <Row>
            <Col xs={12}>
              <Form.Check
                checked={accepted}
                id="privacyCheckId"
                type="checkbox"
                inline
                onChange={event => {
                  setAccepted(event.target.checked);
                }}
              />
              <Form.Label htmlFor="privacyCheckId">
                Acepto el{' '}
                <a href="/aviso_privacidad.pdf" target="_blank">
                  Aviso de Privacidad
                </a>
              </Form.Label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            disabled={!accepted || isSignupLoading}
            onClick={() => {
              onSubmit();
            }}>
            Registrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const singupSchema = z.object({
  employeeNumber: z
    .string()
    .toUpperCase()
    .trim()
    .min(1, {message: 'Campo Obligatorio'})
    .max(9, {message: 'Número de empleado no puede tener más de 9 carácteres'})
    .refine(
      value => regex.onlyAlphaNumericValues.test(value),
      'Solo se aceptan carácteres alfanuméricos',
    ),
  // Por el momento estos son los requerimientos de las contraseñas
  // TODO: Talvez no se debe de poner estas validaciones EN EL FRONT END durante el LOG IN
  // Si se tiene que crear una contraseña, entonces si.
  password: z
    .string()
    .max(16, {message: 'Contraseña debe de tener un maximo de 16 carácteres'})
    .min(7, {message: 'Contraseña debe de tener un minimo de 7 carácteres'}),
  // TODO: Temporary password strenght check disabled for testing
  // .refine(
  //   value => regex.includesUpperChar.test(value),
  //   'Contraseña debe incluir mayusculas',
  // )
  // .refine(
  //   value => regex.includesLowerChar.test(value),
  //   'Contraseña debe incluir minusculas',
  // )
  // .refine(
  //   value => regex.includesNumbers.test(value),
  //   'Contraseña debe incluir números',
  // )
  // .refine(
  //   value => regex.includesNewSiaseSymbols.test(value),
  //   'Contraseña debe incluir carácteres especiales validos',
  // ),
});

type SignupInfo = {employeeNumber: string; password: string};

type UserConfirmation = {
  employeeNumber: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  email: string;
};

export default Signup;

import React, {useEffect, useState} from 'react';
import {
  Button,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from 'react-bootstrap';
import './FinancialBreakdown.css';
import {
  useGetCurrentConvocationMutation,
  useGetUserCurrentProjectMutation,
  useGetUserFinancialBreakdownMutation,
  useGetUserParticipationStageMutation,
  useUpdateFinancialBreakdownMutation,
} from '../../../redux/services/inv.api';
import {useDispatch, useSelector} from 'react-redux';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {toast} from 'react-toastify';
import {ProjectRubric} from '../../../types/Rubric';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import {useBlocker, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import {MexCurrency} from '../../../constants/MexCurrency';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';

const RubricoRow = ({
  rubrico: {rubricId, name, amount, tooltip},
  editValueRubrico,
  disabled,
}: RubricoRowProp) => {
  return (
    <tr key={rubricId}>
      <td>
        {name}
        &nbsp;
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip className="tooltip">{tooltip}</Tooltip>}>
          <FontAwesomeIcon color="blue" icon={faCircleInfo} />
        </OverlayTrigger>
      </td>
      <td>
        <Form.Group className="mb-3" controlId={`${rubricId}-amount-Id`}>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              disabled={disabled}
              value={`${amount ?? 0}`}
              onChange={e => {
                const auxValue = e.target.value.replace(/^0+/, '');
                const value = auxValue.length > 0 ? auxValue : '0';

                editValueRubrico(rubricId, 'amount', `${parseFloat(value)}`);
              }}
              type={'number'}
              min="0.00"
              max="50000.00"
              step="0.01"
            />
          </InputGroup>
        </Form.Group>
      </td>
    </tr>
  );
};

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook
// ... However, with the way this page works, we need to analize how to go about this page

const FinancialBreakdown = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentProject = useSelector(selectProject);
  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const isClosed = currentProject?.isClosed;

  const [loading, setLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const [supportAmount, setsupportAmount] = useState(50000);

  const [financialBreakdownInfo, setFinancialBreakdownInfo] =
    useState<FinancialBreakdownInfo>({
      rubrics: [],
    });

  const [lastFinancialBreakdownInfo, setLastFinancialBreakdownInfo] =
    useState<FinancialBreakdownInfo>({
      rubrics: [],
    });

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [currentAmount, setCurrentAmount] = useState(0);

  const [showVentanaNoElegible, setShowVentanaNoElegible] = useState(false);

  const [warnings, setWarnings] = useState<Warning[]>([]);

  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();

  const [
    getUserFinancialBreakdown,
    {isLoading: isGetUserFinancialBreakdownLoading},
  ] = useGetUserFinancialBreakdownMutation();
  const [
    updateFinancialBreakdown,
    {isLoading: isUpdateFinancialBreakdownLoading},
  ] = useUpdateFinancialBreakdownMutation();

  const [
    getUserParticipationStage,
    {isLoading: isGetUserCurrentStudentsLoading},
  ] = useGetUserParticipationStageMutation();

  const onSubmit = () => {
    if (isDirty) handleUpdateFinancialBreakdownSubmit();
    else toast.warning('Debe tener cambios antes de poder guardar.');
  };

  const handleGetRubric = async () => {
    const responseData = await getUserFinancialBreakdown(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        setCurrentAmount(data.totalAmount);

        setFinancialBreakdownInfo(data);
        setLastFinancialBreakdownInfo(data);
        setsupportAmount(data.supportAmount);
        return;
      }
    }
  };

  const handleUpdateFinancialBreakdownSubmit = async () => {
    if (isClosed) return;
    if (currentProject == undefined || currentProject.projectId == undefined)
      return;

    const responseData = await updateFinancialBreakdown({
      projectId: currentProject.projectId,
      rubrics: financialBreakdownInfo.rubrics,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        let total = 0;
        if (financialBreakdownInfo) {
          data.map(rub => {
            total += rub.amount ?? 0;
          });

          setCurrentAmount(total);
          setFinancialBreakdownInfo({rubrics: [...data]});
          setLastFinancialBreakdownInfo({rubrics: [...data]});
        }
      }
      setIsDirty(false);
      //toast.info('Rubrica actualizada!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const onCancel = () => {
    setIsDirty(false);
    setFinancialBreakdownInfo(lastFinancialBreakdownInfo);
  };

  const getCurrentWithoutSelectedRubricoAmount = (id: string) => {
    let amount = 0;

    financialBreakdownInfo.rubrics.map(rubrico => {
      if (id !== rubrico.rubricId) {
        amount += rubrico.amount ?? 0;
      }
    });

    return amount;
  };

  const editValueRubrico = (
    id: string,
    name: keyof ProjectRubric,
    value: string,
  ) => {
    setIsDirty(true);

    let amount = 0;
    const totalAmountNoSelected = getCurrentWithoutSelectedRubricoAmount(id);
    let overBudget = false;
    const resultAux = {
      rubrics: financialBreakdownInfo.rubrics.map(rubrico => {
        let aux = rubrico;
        let actualValue = parseFloat(value) < 0 ? '0' : value;

        /* TODO: Make a switch/if statementes to allow rules, current it will be hardcoded */
        if (name == 'amount') {
          actualValue =
            actualValue.length === 0 ? '0' : `${parseFloat(actualValue)}`;

          if (parseFloat(actualValue) > supportAmount) {
            actualValue = `${supportAmount}`;
          }

          if (
            id == rubrico.rubricId &&
            parseFloat(actualValue) + totalAmountNoSelected > supportAmount
          ) {
            actualValue = `${supportAmount - totalAmountNoSelected}`;
          }
        }
        //After Rules value assigment
        if (id === rubrico.rubricId) {
          aux = {
            ...aux,
            [name]:
              name == 'amount'
                ? parseFloat(actualValue.length === 0 ? '0' : actualValue)
                : actualValue,
          };
        }

        //TotalAmount Calculation
        if (name == 'amount') {
          amount += aux.amount ?? 0;
          overBudget = amount > supportAmount;
        }

        return {...aux, amount: Number(aux.amount)};
      }),
    };

    if (!overBudget) {
      setFinancialBreakdownInfo({...resultAux});
      setCurrentAmount(Number(amount));
    } else {
      setFinancialBreakdownInfo({
        rubrics: financialBreakdownInfo.rubrics.map(rubrico => {
          return {...rubrico, amount: 0};
        }),
      });
      setCurrentAmount(0);
    }
  };

  const loadForm = () => {
    if (loading) {
      setFinancialBreakdownInfo({
        rubrics: [],
      });
    }
  };
  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      await handleGetParticipationStage();
      await handleGetRubric();
      setLoading(false);
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));

        await handleGetParticipationStage();
        await handleGetRubric();
        setLoading(false);
        return;
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir el proyecto.',
      );
    }
    //TODO: Warn user to register a project first to precede.
    toast.error(
      'Es necesario registrar el Nombre del Proyecto para continuar.',
    );
    navigate(Paths.investigator.REGISTERPROJECT);

    return;
  };

  const handleGetParticipationStage = async () => {
    const responseData = await getUserParticipationStage(undefined);

    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (!resultData?.data) {
        toast.error('Debe seleccionar una etapa de participacion primero.');
        navigate(Paths.investigator.PARTICIPATIONSTAGE);
      }
    } else {
      toast.error(resultData?.message ?? 'Etapa no encontrada.');
      navigate(Paths.investigator.PARTICIPATIONSTAGE);
    }
  };

  useEffect(() => {
    loadForm();
    checkConvocation();
    checkProject();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <div className="d-flex flex-column h-100 w-100 p-2">
            <div className="d-flex flex-row w-100 justify-content-center">
              <p className="text-center mb-3 title-text">Desglose Financiero</p>
            </div>

            <Form>
              <Form.Group className="mb-3">
                <Form.Text className="text-muted">
                  Monto de Apoyo: {`${MexCurrency.format(supportAmount)}`}
                </Form.Text>
              </Form.Group>
              {/* Table */}
              <Form.Group
                className="mb-3"
                controlId="financialBreakdownTableId">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Rubro</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financialBreakdownInfo.rubrics.map(rubrico => {
                      return (
                        <RubricoRow
                          disabled={isClosed || !isRegistrationActive}
                          rubrico={{...rubrico}}
                          editValueRubrico={editValueRubrico}
                        />
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      <td>{`${MexCurrency.format(currentAmount)}`}</td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Text className="text-muted">
                  Total: {`${MexCurrency.format(currentAmount)}`}
                </Form.Text>
              </Form.Group> */}

              {warnings.length > 0 &&
                warnings.map(({text}) => {
                  return (
                    <Form.Group className="mb-1">
                      <Form.Text className="text-danger">{text}</Form.Text>
                    </Form.Group>
                  );
                })}

              {/* Botones */}
              <div className="d-flex flex-row">
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowVentanaNoElegible(true);
                  }}>
                  Rubros no Elegibles
                </Button>
                <Button
                  disabled={!isDirty || isClosed || !isRegistrationActive}
                  className="ms-auto"
                  variant="danger"
                  //type="submit"
                  onClick={() => {
                    onCancel();
                  }}>
                  Cancelar
                </Button>
                <Button
                  disabled={
                    !isDirty ||
                    isUpdateFinancialBreakdownLoading ||
                    isClosed ||
                    !isRegistrationActive
                  }
                  variant="success"
                  //type="submit"
                  className="ms-3"
                  //href="/investigatorSignup"
                  onClick={() => {
                    onSubmit();
                  }}>
                  Guardar
                </Button>
              </div>

              {/*  */}
            </Form>
            <div className="text-center pb-3">
              <Button
                className="ms-3"
                onClick={() => {
                  navigate(Paths.investigator.FINALIZEREGISTER);
                }}>
                Siguiente
              </Button>
            </div>
          </div>
        )}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>

      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estas seguro que deseas salir y perder los cambios que han hechos?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      <Modal
        show={showVentanaNoElegible}
        onHide={() => setShowVentanaNoElegible(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Rubros no elegibles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Los siguientes rubros no son elegibles para apoyo, por lo que se
            debe considerar que se evaluarán las propuestas y, de haber un gasto
            que incluya uno de los siguientes, se desestimará el proyecto:
          </p>
          <ol type="a">
            <li>
              Honorarios de consultores en áreas como emprendimiento,
              comercialización o transferencia de tecnología: no se podrán
              destinar recursos para contratar consultores en áreas como
              emprendimiento, comercialización o transferencia de tecnología.
            </li>
            <li>
              Honorarios de personal científico y/o del equipo de trabajo: no
              son elegibles los honorarios pagados al personal científico o del
              equipo que postula la propuesta, ya sea que realice actividades
              directas o indirectas encaminadas al alcance de los objetivos del
              proyecto.
            </li>
            <li>
              Adecuaciones de infraestructura: no se podrán realizar inversiones
              destinadas a la adecuación de laboratorios y/o plantas piloto que
              tengan relación directa y/o indirecta con el objeto del proyecto.
            </li>
            <li>
              Difusión de resultados: inscripción a congresos o gastos de
              publicación en revistas especializadas o de otra índole, ni el
              diseño, elaboración o distribución de cartillas técnicas,
              pósteres, publicidad, entre otros.
            </li>
            <li>
              Gastos de administración: las erogaciones o gastos tales como
              salarios del personal administrativo, materiales y suministros de
              oficina, imprevistos y servicios generales no forman parte de los
              rubros a financiar por esta convocatoria.
            </li>
            <li>
              Software administración: no se reconocerá la adquisición de
              licencias de uso general como paquetes de software de oficina,
              manejo de bases de datos, redes sociales y similares.
            </li>
            <li>
              Todo lo relacionado con pasajes o viáticos para estancias o
              congresos.
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row w-100 justify-content-center">
          <Button
            variant="primary"
            onClick={() => {
              setShowVentanaNoElegible(false);
            }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type FinancialBreakdownInfo = {
  rubrics: ProjectRubric[];
};

type RubricoRowProp = {
  rubrico: ProjectRubric;
  editValueRubrico: (
    id: string,
    name: keyof ProjectRubric,
    value: string,
  ) => void;
  disabled?: boolean;
};

type Warning = {
  text: string;
};

export default FinancialBreakdown;

import {faFile, IconDefinition} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Form,
  FormControlProps,
  FormLabelProps,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {FieldErrors, FieldValues} from 'react-hook-form';
import {downloadPDF} from '../helpers/ValidateFile';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

function FormCampFile<Type, ErrorType extends FieldValues, TouchedType>({
  name,
  displayName,
  className = 'mb-3',
  size,
  accept = 'application/pdf',
  inputProps,
  labelProps,
  controlId,
  errors,
  touchedFields,
  file,
  displayIcon,
  displayIconTooltip,
  displayIconColor,
  displayIconSize,
  displayTooltipClassName = 'tooltip',
}: FormCampFileProp<Type, ErrorType, TouchedType>) {
  return (
    <Form.Group className={className} controlId={controlId ?? (name as string)}>
      {displayName && (
        <>
          <Form.Label {...labelProps}>
            {displayName}{' '}
            {displayIcon && displayIconTooltip ? (
              <>
                &nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className={displayTooltipClassName}>
                      {displayIconTooltip}
                    </Tooltip>
                  }>
                  <FontAwesomeIcon
                    color={displayIconColor}
                    icon={displayIcon}
                    size={displayIconSize ?? 'xs'}
                  />
                </OverlayTrigger>
              </>
            ) : displayIcon ? (
              <>
                &nbsp;
                <FontAwesomeIcon
                  color={displayIconColor}
                  icon={displayIcon}
                  size={displayIconSize ?? 'xs'}
                />
              </>
            ) : (
              <></>
            )}
          </Form.Label>{' '}
          &nbsp;&nbsp;
          {file?.documentFile &&
            file.documentFile !== '' &&
            file?.documentName &&
            file.documentName !== '' && (
              <>
                <FontAwesomeIcon
                  id="docDownload"
                  icon={faFile}
                  color="blue"
                  cursor={'pointer'}
                  onClick={() =>
                    downloadPDF(
                      file.documentFile ? file.documentFile : '',
                      file.documentName,
                    )
                  }
                />
                &nbsp;
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    downloadPDF(
                      file.documentFile ? file.documentFile : '',
                      file.documentName,
                    );
                  }}>
                  {file?.documentName}
                </a>
              </>
            )}
        </>
      )}
      <Form.Control
        type="file"
        size={size}
        accept={accept}
        isValid={
          touchedFields &&
          errors &&
          touchedFields[name as unknown as keyof TouchedType] &&
          !errors[name as keyof ErrorType]
        }
        isInvalid={
          touchedFields &&
          errors &&
          touchedFields[name as unknown as keyof TouchedType] &&
          !!errors[name as keyof ErrorType]
        }
        {...inputProps}
      />
      {errors && errors[name as keyof ErrorType] && (
        <Form.Label className="text-danger" as="small" size="sm">
          {errors[name]?.message as string}
        </Form.Label>
      )}
      {errors && !errors[name as keyof ErrorType] && <br />}
    </Form.Group>
  );
}

type FormCampFileProp<Type, ErrorType extends FieldValues, TouchedType> = {
  controlId?: string;
  name: keyof Type;
  className?: string;
  size?: 'sm' | 'lg' | undefined;
  displayName?: string;
  labelProps?: FormLabelProps;
  inputProps?: FormControlProps;
  accept?: string;
  errors?: FieldErrors<ErrorType>;
  touchedFields?: Partial<Readonly<TouchedType>>;
  file?: {documentFile?: string; documentName?: string};
  //
  displayIcon?: IconDefinition;
  displayIconColor?: string;
  displayIconTooltip?: string;
  displayIconSize?: SizeProp;
  displayTooltipClassName?: string;
};

export default FormCampFile;

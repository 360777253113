import React from 'react';
import {Container, Spinner} from 'react-bootstrap';

const LoadingSpinner = ({
  backgroundColor = '#0005',
  spinnerColor = 'white',
  show = false,
}: SpinnerProps) => {
  return (
    <>
      {show && (
        <div
          className={`d-flex h-100 w-100 align-content-center`}
          style={{
            backgroundColor,
            zIndex: 1000,
            position: 'absolute',
            top: 0,
            left: 0,
          }}>
          <Container
            fluid
            className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{color: spinnerColor}}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Container>
        </div>
      )}
    </>
  );
};

type SpinnerProps = {
  backgroundColor?: string;
  spinnerColor?: string;
  show: boolean;
};

export default LoadingSpinner;

import React, {useEffect, useReducer, useRef, useState} from 'react';
import {Button, Form, Modal, Row, Spinner} from 'react-bootstrap';
import {useForm, SubmitHandler} from 'react-hook-form';
import z from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import './InvestigatorProfile.css';
import FormCampInput from '../../../components/FormCampInput';
import FormCampSelect, {SelectItem} from '../../../components/FormCampSelect';
import {regex} from '../../../constants/Regexs';
import {
  useGetAcademicBodiesFromDependenciesMutation,
  useGetCitiesMutation,
  useGetCountriesMutation,
  useGetCurrentConvocationMutation,
  useGetGradesMutation,
  useGetInvestigatorProfileMutation,
  useGetLinesOfResearchFromAcademicBodyMutation,
  useGetStatesMutation,
  useUpdateInvestigatorProfileMutation,
} from '../../../redux/services/inv.api';
import {Position} from '../../../types/Position';
import {Dependency} from '../../../types/Depedency';
import {useDispatch, useSelector} from 'react-redux';
import {selectUser} from '../../../redux/slice/auth.slice';
import {Director} from '../../../types/Director';
import {AcademicBody} from '../../../types/AcademicBody';
import {useBlocker} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateInvestigator,
} from '../../../redux/slice/inv.slice';
import {InUpdateInvestigatorProfileDto} from '../../../types/Dtos/InvDtos';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons';
import {RequieredForFinalizing} from '../../../constants/RequieredForFinalizing';

const InvestigatorProfileMockup = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const dispatch = useDispatch();

  const focusRef = useRef<HTMLFormElement>(null);

  const currentProject = useSelector(selectProject);
  const currentConvocation = useSelector(selectConvocation);
  const isClosed = currentProject?.isClosed;

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const {
    register,
    handleSubmit,
    formState: {errors, isDirty},
    reset,
    watch, //get the current value
    setValue,
  } = useForm<InvestigatorInfo>({
    resolver: zodResolver(investigatorProfileSchema),
    mode: 'onSubmit',
  });
  const watchCountry = watch('countryId');
  const watchState = watch('stateId');
  const watchDependency = watch('dependencyId');
  const watchDirector = watch('directorId');
  const watchAcademicBodyId = watch('academicBodyId');
  const watchPosition = watch('position');
  const watchLineOfResearchId = watch('lineOfResearchId');
  const watchGradeId = watch('gradeId');

  const currentUser = useSelector(selectUser);

  const [loading, setLoading] = useState(true);
  const [showCancel, setShowCancel] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [uneditateFormValues, setUneditateFormValues] = useState<
    InvestigatorInfo | undefined
  >(undefined);

  const [investigatorPositions, setInvestigatorPositions] = useState<
    Position[]
  >([]);

  const [investigatorDependencies, setInvestigatorDependencies] = useState<
    Dependency[]
  >([]);
  const [directors, setDirectors] = useState<Director[]>([]);
  const [dependencies, setDependencies] = useState<Dependency[]>([]);
  const [academicBodies, setAcademicBodies] = useState<AcademicBody[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);

  //
  const [dependenciesValues, setSelectDependenciesValues] = useState<
    SelectItem[]
  >([]);
  const [directorsValues, setDirectorsValues] = useState<SelectItem[]>([]);
  const [positionsValues, setPositionsValues] = useState<SelectItem[]>([]);
  const [academicBodiesValues, setAcademicBodiesValues] = useState<
    SelectItem[]
  >([]);
  const [linesOfResearchValues, setLinesOfResearchValues] = useState<
    SelectItem[]
  >([]);
  const [titlesValues, setTitlesValues] = useState<SelectItem[]>([]);
  const [gradesValues, setGradesValues] = useState<SelectItem[]>([]);
  const [CountryValues, setCountryValues] = useState<SelectItem[]>([]);
  const [StateValues, setStateValues] = useState<SelectItem[]>([]);
  const [CityValues, setCityValues] = useState<SelectItem[]>([]);

  const [getInvetigatorProfile, {isLoading: isInvestigationProfileLoading}] =
    useGetInvestigatorProfileMutation();
  const [getCountries, {isLoading: isGetCountriesLoading}] =
    useGetCountriesMutation();
  const [getStates, {isLoading: isGetStatesLoading}] = useGetStatesMutation();
  const [getCities, {isLoading: isGetCitiesLoading}] = useGetCitiesMutation();
  const [
    getAcademicBodiesFromDependencies,
    {isLoading: isGetAcademicBodiesFromDependenciesLoading},
  ] = useGetAcademicBodiesFromDependenciesMutation();
  const [
    getLinesOfResearchFromAcademicBody,
    {isLoading: isGetLinesOfResearchFromAcademicBodyLoading},
  ] = useGetLinesOfResearchFromAcademicBodyMutation();
  const [getGrades, {isLoading: isGetGrade}] = useGetGradesMutation();

  const [
    sendUpdateInvestigatorProfile,
    {isLoading: isSendUpdateInvestigatorProfileLoading},
  ] = useUpdateInvestigatorProfileMutation();

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const loadFormValues = async () => {
    const temp = await getInvestigatorProfileAsync();
    const empty: SelectItem = {value: '-1', label: 'Selecciona...'};

    let result: InvestigatorInfo | undefined = undefined;

    if (temp) {
      const {
        inv,
        directors: tempDirectors,
        dependencias: tempDependencias,
        puestos: tempPositions,
        titles,
      } = temp;
      setDependencies(tempDependencias);
      setInvestigatorDependencies(tempDependencias);
      setDirectors(tempDirectors);
      setPositions(tempPositions);

      const dependencia = tempDependencias.find(
        ({dependencyId}) => dependencyId === inv.dependencyId,
      );

      const director = tempDirectors.find(
        ({codeDependency}) => codeDependency === dependencia?.code,
      );

      // Get all this info before reaching the form, this way the information will be there
      //and the select will have the correct information

      if (inv.dependencyId > 0)
        await getAcademicBodiesFromDependenciesAsync(inv.dependencyId, false);

      if (inv.academicBodyId > 0) {
        await getLinesOfResearchFromAcademicBodyAsync(inv.academicBodyId);
        setValue('academicBodyId', inv.academicBodyId.toString());
        setValue(
          'lineOfResearchId',
          `${inv.lineOfResearchId !== 0 ? inv.lineOfResearchId : '-1'}`,
        );
      }
      await getGradesAsync();
      await getCountriesAsync();
      await getStateAsync(inv.countryId);
      await getCityAsync(inv.stateId);

      const aux: SelectItem[] = tempDependencias.map(dependency => {
        return {
          value: dependency.dependencyId.toString(),
          label: dependency.name,
        };
      });

      if (tempDependencias.length === 0) {
        toast.warning('Ha habido un error cargando sus dependencias.');
        const emptyDependency: SelectItem = {
          value: '-1',
          label: 'No dependencias disponibles...',
        };
        setSelectDependenciesValues([emptyDependency]);
      } else {
        setSelectDependenciesValues([empty, ...aux]);
      }

      setDirectorsValues(
        tempDirectors.map(director => {
          return {
            value: director.directorId.toString(),
            label: `${director.title} ${director.name} ${director.firstSurname} ${director.secondSurname}`,
          };
        }),
      );

      setTitlesValues(
        titles.map(title => {
          return {
            value: title.titleId.toString(),
            label: title.title,
          };
        }),
      );

      const auxPosVal = tempPositions
        .filter(
          position => position.dependencia.split('|')[0] === dependencia?.code,
        )
        .map(position => {
          return {
            value: position.puesto,
            label: position.puesto,
          };
        });
      setPositionsValues([empty, ...auxPosVal]);

      result = {
        ...inv,
        name: currentUser?.name ?? '',
        firstSurname: currentUser?.firstSurname ?? '',
        secondSurname: currentUser?.secondSurname ?? '',
        employeeNumber: currentUser?.employeeNumber ?? '',
        countryId: `${inv.countryId}`,
        stateId: `${inv.stateId}`,
        cityId: `${inv.cityId}`,
        directorId: `${director?.directorId ?? 0}`,
        lineOfResearchId: `${
          inv.lineOfResearchId !== undefined && inv.lineOfResearchId > 0
            ? inv.lineOfResearchId
            : '-1'
        }`,
        academicBodyId: `${
          inv.academicBodyId !== undefined && inv.academicBodyId > 0
            ? inv.academicBodyId
            : '-1'
        }`,
        gradeId: `${
          inv.gradeId !== undefined && inv.gradeId > 0 ? inv.gradeId : '-1'
        }`,
        dependencyId: `${inv.dependencyId === 0 ? '-1' : inv.dependencyId}`,
        levelSNI: inv.levelSNI,
        titleId: inv.titleId,
      };

      dispatch(updateInvestigator({...inv}));
      return result;
    }

    // This is an empty object
    result = {
      name: '',
      firstSurname: '',
      secondSurname: '',
      rfc: '',
      curp: '',
      street: '',
      streetNumber: '',
      betweenStreet1: '',
      betweenStreet2: '',
      neighborhood: '',
      countryId: '',
      stateId: '',
      cityId: '',
      postCode: '',
      dependencyId: '',
      directorId: '',
      employeeNumber: '',
      position: '',
      levelSNI: '',
      lineOfResearchId: '',
      academicBodyId: '',
      gradeId: '',
      email1: '',
      cellphone: '',
      contactPhone: '',
    };

    return result;
  };

  const onSubmit: SubmitHandler<InvestigatorInfo> = data =>
    handleUpdateInvestigatorProfileSubmit(data);

  const handleUpdateInvestigatorProfileSubmit = async (
    requestData: InvestigatorInfo,
  ) => {
    // TODO: Make backend Update Investigator Profile

    const superData: InUpdateInvestigatorProfileDto = {
      ...requestData,
      countryId: Number(requestData.countryId),
      stateId: Number(requestData.stateId),
      cityId: Number(requestData.cityId),
      lineOfResearchId:
        requestData.lineOfResearchId != '-1'
          ? Number(requestData.lineOfResearchId)
          : undefined,
      academicBodyId:
        requestData.academicBodyId != '-1'
          ? Number(requestData.academicBodyId)
          : undefined,
      gradeId: Number(requestData.gradeId),
      dependencyId:
        requestData.dependencyId != '-1'
          ? Number(requestData.dependencyId)
          : undefined,
      position: requestData.position != '-1' ? requestData.position : undefined,
      email1: requestData.email1,
    };

    const responseData = await sendUpdateInvestigatorProfile({
      ...superData,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        dispatch(updateInvestigator({...resultData?.data.inv}));
      }
      setUneditateFormValues(requestData);
      setEditMode(false);
      reset(requestData);
      //toast.info('Investigador Actualizado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await bootstrapSequence();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const getInvestigatorProfileAsync = async () => {
    const responseData = await getInvetigatorProfile(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        const {puestos, dependencias} = data;

        setInvestigatorPositions(puestos);
        setInvestigatorDependencies(dependencias);

        return data;
      }
    }

    return undefined;
  };

  const getCountriesAsync = async () => {
    const responseData = await getCountries(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        const aux: SelectItem[] = data.map(({countryId, name}) => {
          const temp: SelectItem = {
            value: countryId.toString(),
            label: name,
          };
          return temp;
        });

        setCountryValues([...aux]);
      }
    }
  };

  const getStateAsync = async (countryId: number) => {
    const responseData = await getStates(countryId);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        const aux: SelectItem[] = data.map(({stateId, name}) => {
          const temp: SelectItem = {
            value: stateId.toString(),
            label: name,
          };
          return temp;
        });

        setStateValues([...aux]);
      }
    }
  };

  const getCityAsync = async (stateId: number) => {
    const responseData = await getCities(stateId);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        const aux: SelectItem[] = data.map(({cityId, name}) => {
          const temp: SelectItem = {
            value: cityId.toString(),
            label: name,
          };
          return temp;
        });

        setCityValues([...aux]);
      }
    }
  };

  const getDirector = ({
    dependencyId: auxDependencyId,
    code,
  }: {
    dependencyId: number;
    code?: string;
  }) => {
    let auxCode: string | undefined = code;

    if (!auxCode) {
      const dependencia = investigatorDependencies.find(
        ({dependencyId}) => dependencyId === auxDependencyId,
      );
      auxCode = dependencia?.code;
    }

    return directors.find(({codeDependency}) => codeDependency === auxCode);
  };

  const getAcademicBodiesFromDependenciesAsync = async (
    dependencyId: number,
    resetValues = true,
  ) => {
    const responseData = await getAcademicBodiesFromDependencies(dependencyId);
    let resultData = undefined;

    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        const temporal: SelectItem = {
          value: '-1',
          label: 'Selecciona...',
        };
        const aux: SelectItem[] = data.map(({academicBodyId, name}) => {
          const temp: SelectItem = {
            value: academicBodyId.toString(),
            label: name,
          };
          return temp;
        });
        setAcademicBodiesValues([temporal, ...aux]);
        setAcademicBodies([...data]);
      }
    } else {
      const temporal: SelectItem = {
        value: '-1',
        label: 'Selecciona...',
      };
      setAcademicBodiesValues([temporal]);
      setAcademicBodies([]);
    }

    if (resetValues) {
      setValue(
        'academicBodyId',
        uneditateFormValues?.academicBodyId !== watchAcademicBodyId
          ? '-1'
          : watchAcademicBodyId,
      );
      setValue(
        'gradeId',
        uneditateFormValues?.gradeId !== watchGradeId ? '-1' : watchGradeId,
      );
      setValue(
        'lineOfResearchId',
        uneditateFormValues?.lineOfResearchId !== watchLineOfResearchId
          ? '-1'
          : watchLineOfResearchId,
      );
    }
  };

  const getLinesOfResearchFromAcademicBodyAsync = async (
    academicBodyId: number,
  ) => {
    const responseData = await getLinesOfResearchFromAcademicBody(
      academicBodyId,
    );
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData && resultData?.success) {
      if (resultData?.data) {
        const temporal: SelectItem = {
          value: '-1',
          label: 'N/A',
        };
        const data = resultData?.data;

        const aux: SelectItem[] = data.map(({lineOfResearchId, name}) => {
          const temp: SelectItem = {
            value: lineOfResearchId.toString(),
            label: name,
          };
          return temp;
        });

        setLinesOfResearchValues([temporal, ...aux]);
      }
    } else {
      const temporal: SelectItem = {
        value: '-1',
        label: 'N/A',
      };

      setLinesOfResearchValues([temporal]);
      setValue('lineOfResearchId', '-1');
    }
  };

  const getGradesAsync = async () => {
    const responseData = await getGrades(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        const temporal: SelectItem = {
          value: '-1',
          label: 'N/A',
        };

        const aux: SelectItem[] = data.map(({gradeId, name}) => {
          const temp: SelectItem = {
            value: gradeId.toString(),
            label: name,
          };
          return temp;
        });

        setGradesValues([temporal, ...aux]);
      }
    }
  };

  const changeAcademicBodieDependantValues = async (
    selectedAcademicBodyId: number,
  ) => {
    if (selectedAcademicBodyId > 0) {
      const auxAB = academicBodies.find(
        ({academicBodyId}) => academicBodyId === selectedAcademicBodyId,
      );
      if (auxAB) setValue('gradeId', `${auxAB.gradeId}`);

      await getLinesOfResearchFromAcademicBodyAsync(selectedAcademicBodyId);

      setValue('lineOfResearchId', `-1`);
    } else if (selectedAcademicBodyId == -1) {
      setValue('gradeId', `-1`);
      setValue('lineOfResearchId', `-1`);
      const temporal: SelectItem = {
        value: '-1',
        label: 'N/A',
      };
      setLinesOfResearchValues([temporal]);
    }
  };

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const bootstrapSequence = async () => {
    const aux = await loadFormValues();
    setUneditateFormValues(aux);
    setLoading(false);
    reset(aux);
  };

  useEffect(() => {
    checkConvocation();
    bootstrapSequence();
  }, []);

  useEffect(() => {
    if (watchCountry && watchCountry !== '' && parseInt(watchCountry) > 0) {
      getStateAsync(parseInt(watchCountry));
    }
  }, [watchCountry]);

  useEffect(() => {
    if (watchState && watchState !== '' && parseInt(watchState) > 0) {
      getCityAsync(parseInt(watchState));
    }
  }, [watchState]);

  useEffect(() => {
    if (
      watchDependency &&
      watchDependency !== '' &&
      parseInt(watchDependency)
    ) {
      const director = getDirector({dependencyId: parseInt(watchDependency)});

      setValue('directorId', `${director?.directorId || 0}`);
      getAcademicBodiesFromDependenciesAsync(parseInt(watchDependency));
      //
      const dependency = dependencies.find(
        aux => aux.dependencyId === parseInt(watchDependency),
      );

      const auxPositionsValues: SelectItem[] = positions
        .filter(pos => pos.dependencia.split('|')[0] === dependency?.code)
        .map(position => {
          return {
            value: position.puesto,
            label: position.puesto,
          };
        });

      //empty the select
      if (parseInt(watchDependency) === -1) {
        setPositionsValues([]);
        setValue('position', '');
        return;
      }

      //Set the proper positions if they are in a dependency
      if (positions.length > 0) {
        setPositionsValues(auxPositionsValues);
        setValue('position', auxPositionsValues[0].value);
      } else {
        //Otherwise, set a default
        const temporal: SelectItem = {
          value: 'No cuenta con cargo(s) en esta dependencia',
          label: 'No cuenta con cargo(s) en esta dependencia',
        };
        setPositionsValues([temporal]);
        setValue('position', temporal.value);
      }
    }
  }, [watchDependency]);

  useEffect(() => {
    if (watchAcademicBodyId && watchAcademicBodyId !== '') {
      changeAcademicBodieDependantValues(parseInt(watchAcademicBodyId));
    }
  }, [watchAcademicBodyId]);

  //All these useeffect force a re-render
  useEffect(() => {
    setValue('position', watchPosition);
  }, [positionsValues]);

  useEffect(() => {
    setValue('dependencyId', watchDependency);
  }, [dependenciesValues]);

  useEffect(() => {
    setValue('directorId', watchDirector);
  }, [directorsValues]);

  useEffect(() => {
    setValue('academicBodyId', watchAcademicBodyId);
    setValue('lineOfResearchId', watchLineOfResearchId);
    setValue('gradeId', watchGradeId);
  }, [academicBodiesValues]);

  useEffect(() => {
    setValue('lineOfResearchId', watchLineOfResearchId);
  }, [linesOfResearchValues]);

  useEffect(() => {
    setValue('gradeId', watchGradeId);
  }, [gradesValues]);

  const handleEditClick = () => {
    if (editMode || isClosed || !isRegistrationActive) return;
    //focusRef.current?.scrollIntoView({behavior: 'smooth'});
    focusRef.current?.scrollIntoView();
    setEditMode(true);
  };

  const handleCancelClick = () => {
    if (!editMode) return;

    setShowCancel(true);
  };

  const handleCancelExit = () => {
    setShowCancel(false);
  };

  const handleCancel = async () => {
    reset(uneditateFormValues);
    setShowCancel(false);
    setEditMode(false);
  };

  const inputDisables = isInvestigationProfileLoading || !editMode;

  return (
    <>
      <div className="investigator-profile d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <Form
            ref={focusRef}
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex flex-column h-100 w-100 p-2">
            <Row xs={12} className="px-5">
              <p className="text-center mb-3 login-text">Mi Perfil</p>
            </Row>
            {/* Investigador */}
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">Información personal</Form.Text>
            </Form.Group>

            <FormCampSelect
              name="titleId"
              displayName="Título"
              inputProps={{
                ...register('titleId'),
                disabled: inputDisables,
              }}
              values={titlesValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Nombre"
              name="name"
              inputProps={{
                disabled: true,
                ...register('name'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Primer Apellido"
              name="firstSurname"
              inputProps={{
                disabled: true,
                ...register('firstSurname'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Segundo Apellido"
              name="secondSurname"
              inputProps={{
                disabled: true,
                ...register('secondSurname'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="RFC"
              name="rfc"
              inputProps={{
                disabled: true,
                ...register('rfc'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="CURP"
              name="curp"
              inputProps={{
                disabled: true,
                ...register('curp'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Correo Institucional"
              name="emailEdu"
              inputProps={{
                disabled: true,
                ...register('emailEdu'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Correo Alternativo"
              name="email1"
              inputProps={{
                disabled: inputDisables,
                ...register('email1'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <Form.Group className="mb-3">
              <Form.Text className="text-muted">Domicilio fiscal</Form.Text>
            </Form.Group>

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              //
              {...RequieredForFinalizing}
              //
              displayName="Calle"
              name="street"
              inputProps={{
                disabled: inputDisables,
                ...register('street'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              //
              {...RequieredForFinalizing}
              //
              displayName="Número"
              name="streetNumber"
              inputProps={{
                disabled: inputDisables,
                type: 'number',
                ...register('streetNumber'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">Entre las calles</Form.Text>
            </Form.Group>
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Entre calle 1"
              name="betweenStreet1"
              inputProps={{
                disabled: inputDisables,
                ...register('betweenStreet1'),
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Entre calle 2"
              name="betweenStreet2"
              inputProps={{
                disabled: inputDisables,
                ...register('betweenStreet2'),
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              //
              {...RequieredForFinalizing}
              //
              displayName="Colonia"
              name="neighborhood"
              inputProps={{
                disabled: inputDisables,
                ...register('neighborhood'),
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampSelect
              name="countryId"
              //
              {...RequieredForFinalizing}
              //
              displayName="País"
              inputProps={{
                ...register('countryId'),
                disabled: isGetCountriesLoading || inputDisables,
              }}
              values={CountryValues}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampSelect
              name="stateId"
              //
              {...RequieredForFinalizing}
              //
              displayName="Estado"
              inputProps={{
                disabled: isGetStatesLoading || inputDisables,
                ...register('stateId'),
              }}
              values={StateValues}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampSelect
              name="cityId"
              //
              {...RequieredForFinalizing}
              //
              displayName="Ciudad"
              inputProps={{
                disabled: isGetCitiesLoading || inputDisables,
                ...register('cityId'),
              }}
              values={CityValues}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              //
              {...RequieredForFinalizing}
              //
              displayName="C.P"
              name="postCode"
              inputProps={{
                disabled: inputDisables,
                type: 'number',
                ...register('postCode'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <Form.Group className="mb-3">
              <Form.Text className="text-muted" as="small">
                Nota: El domicilio es el que designa para escuchar y recibir
                todo tipo de notificaciones legales
              </Form.Text>
            </Form.Group>

            {/* Dependencia */}
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">Dependencia</Form.Text>
            </Form.Group>

            <FormCampSelect
              name="dependencyId"
              //
              {...RequieredForFinalizing}
              //
              displayName="Dependencia"
              defaultValue={watchDependency ?? '-1'}
              inputProps={{
                className: 'inv-pro-input',
                disabled: inputDisables,
                placeholder: watchDependency ?? '',
                ...register('dependencyId'),
              }}
              values={dependenciesValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampSelect
              name="directorId"
              displayName="Director(a)"
              inputProps={{
                disabled: true,
                className: 'inv-pro-input',
                ...register('directorId'),
              }}
              values={directorsValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            {/* Informacion Laboral */}
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">Información laboral</Form.Text>
            </Form.Group>
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Número de Empleado"
              name="employeeNumber"
              inputProps={{
                disabled: true,
                type: 'number',
                ...register('employeeNumber'),
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            {/* <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="Puesto"
              name="position"
              inputProps={{
                disabled: true,
                ...register('position'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            /> */}
            <FormCampSelect
              name="position"
              //
              {...RequieredForFinalizing}
              //
              displayName="Puesto"
              defaultValue={watchPosition ?? '-1'}
              inputProps={{
                disabled: inputDisables,
                placeholder: watchPosition ?? '',
                ...register('position'),
              }}
              values={positionsValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              displayName="NIVEL SNI"
              name="levelSNI"
              inputProps={{
                disabled: true,
                ...register('levelSNI'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampSelect
              name="academicBodyId"
              displayName="Cuerpo Académico"
              defaultValue={watchAcademicBodyId ?? '-1'}
              inputProps={{
                disabled: inputDisables,
                placeholder: watchAcademicBodyId ?? '',
                ...register('academicBodyId'),
              }}
              values={academicBodiesValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampSelect
              name="gradeId"
              displayName="Grado de Formación de Cuerpo Académico"
              defaultValue={watchGradeId ?? '-1'}
              inputProps={{
                disabled: true,
                placeholder: watchGradeId ?? '',
                ...register('gradeId'),
              }}
              values={gradesValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampSelect
              name="lineOfResearchId"
              displayName="Línea de Investigación"
              defaultValue={watchLineOfResearchId ?? '-1'}
              inputProps={{
                disabled: inputDisables,
                placeholder: watchLineOfResearchId ?? '',
                ...register('lineOfResearchId'),
              }}
              values={linesOfResearchValues}
              errors={errors}
              // touchedFields={touchedFields}
            />

            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              //
              {...RequieredForFinalizing}
              //
              displayName="Celular"
              name="cellphone"
              inputProps={{
                disabled: inputDisables,
                ...register('cellphone'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />
            <FormCampInput
              formGroupClassName=""
              size={'sm'}
              //
              {...RequieredForFinalizing}
              //
              displayName="Teléfono de Contacto"
              name="contactPhone"
              inputProps={{
                disabled: inputDisables,
                ...register('contactPhone'),
                className: 'inv-pro-input',
              }}
              errors={errors}
              // touchedFields={touchedFields}
            />

            {/* Informacion Personal */}

            {/* Buttons */}
            <div className="d-flex flex-row pb-3">
              <Button
                variant="warning"
                onClick={() => handleEditClick()}
                disabled={
                  (!isInvestigationProfileLoading && editMode) ||
                  isClosed ||
                  !isRegistrationActive
                }>
                Editar
              </Button>
              <Button
                variant="danger"
                className="ms-auto"
                onClick={() => handleCancelClick()}
                disabled={!isInvestigationProfileLoading && !editMode}>
                Cancelar
              </Button>
              <Button
                variant="success"
                className="ms-3"
                type="submit"
                disabled={
                  (!isInvestigationProfileLoading && !editMode) ||
                  isSendUpdateInvestigatorProfileLoading ||
                  !isDirty
                }>
                Guardar
              </Button>
            </div>
          </Form>
        )}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>

      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estás seguro que deseas salir y perder los cambios que se han
            hecho?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      <Modal show={showCancel} onHide={handleCancelExit}>
        <Modal.Header closeButton>
          <Modal.Title>¿Salir?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro que deseas cancelar la edición y perder los cambios
          hechos?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelExit}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={handleCancel}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const investigatorProfileSchema = z.object({
  name: z.string().trim().min(1, {message: 'Campo Obligatorio'}),
  firstSurname: z.string().trim().min(1, {message: 'Campo Obligatorio'}),
  secondSurname: z.string().trim().min(1, {message: 'Campo Obligatorio'}), // TODO: remove this requirment
  rfc: z.string().trim().min(1, {message: 'Campo Obligatorio'}),
  curp: z.string().trim().min(1, {message: 'Campo Obligatorio'}),
  street: z
    .string()
    .trim()
    .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
      message: 'Caracteres no validos',
    })
    .max(30, {message: 'Límite: 30 carácteres'})
    .optional()
    .or(z.literal('')),
  streetNumber: z
    .string()
    .trim()
    .regex(regex.onlyAlphaNumericValuesAndSpace)
    .max(10, {message: 'Límite: 10 carácteres'})
    .optional()
    .or(z.literal('')),
  betweenStreet1: z
    .string()
    .trim()
    .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
      message: 'Caracteres no validos',
    })
    .max(30, {message: 'Límite: 30 carácteres'})
    .optional()
    .or(z.literal('')),
  betweenStreet2: z
    .string()
    .trim()
    .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
      message: 'Caracteres no validos',
    })
    .max(30, {message: 'Límite: 30 carácteres'})
    .optional()
    .or(z.literal('')),
  neighborhood: z
    .string()
    .trim()
    .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
      message: 'Caracteres no validos',
    })
    .max(50, {message: 'Límite: 50 carácteres'})
    .optional()
    .or(z.literal('')),
  countryId: z.string().regex(regex.onlyNumbers).optional().or(z.literal('')),
  stateId: z.string().regex(regex.onlyNumbers).optional().or(z.literal('')),
  cityId: z.string().regex(regex.onlyNumbers).optional().or(z.literal('')),
  postCode: z
    .string()
    .trim()
    .regex(regex.onlyNumbers, {message: 'Solamente Numeros'})
    .max(5, {message: 'Límite: 5 carácteres'})
    .optional()
    .or(z.literal('')),
  dependencyId: z
    .string()
    .regex(regex.onlyNumbers, {message: 'Dependencia es obligatoria.'})
    .or(z.literal('')),
  directorId: z
    .string()
    .regex(regex.onlyNumbers)
    .or(z.literal(''))
    .or(z.literal('-1')),
  employeeNumber: z
    .string()
    .toUpperCase()
    .trim()
    .regex(regex.onlyAlphaNumericValues, {
      message: 'Sólo se aceptan carácteres alfanuméricos',
    })
    .min(1, {message: 'Campo Obligatorio'})
    .max(9, {message: 'Número de empleado no puede tener más de 9 carácteres'}),
  position: z.string().regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
    message: 'Posicion es obligatoria.',
  }),
  levelSNI: z
    .string()
    .trim()
    .max(30, {message: 'Límite: 30 carácteres'})
    .optional(),
  lineOfResearchId: z
    .string()
    .regex(regex.onlyNumbers)
    .optional()
    .or(z.literal(''))
    .or(z.literal('-1')),
  academicBodyId: z
    .string()
    .regex(regex.onlyNumbers)
    .optional()
    .or(z.literal(''))
    .or(z.literal('-1')),
  gradeId: z
    .string()
    .regex(regex.onlyNumbers)
    .optional()
    .or(z.literal(''))
    .or(z.literal('-1')),
  emailEdu: z
    .string()
    .trim()
    .regex(regex.emailEdu, {
      message: 'Dirección de correo electronico no valido',
    })
    .max(100, {message: 'Límite: 100 carácteres'})
    .optional()
    .or(z.literal('')),
  email1: z
    .string()
    .trim()
    .regex(regex.email, {message: 'Dirección de correo electronico no valido'})
    .max(100, {message: 'Límite: 100 carácteres'})
    .optional()
    .or(z.literal('')),
  cellphone: z
    .string()
    .trim()
    //.regex(regex.cellphone, {message: 'Numero Telefonico no valido'})
    .regex(regex.onlyNumbers, {message: 'Numero Telefonico no valido'})
    .min(10, {message: 'Numero Telefonico no valido'})
    .max(10, {message: 'Límite: 10 carácteres'})
    .optional()
    .or(z.literal('')),
  contactPhone: z
    .string()
    .trim()
    //.regex(regex.cellphone, {message: 'Numero Telefonico no valido'})
    .regex(regex.onlyNumbers, {message: 'Numero Telefonico no valido'})
    .min(10, {message: 'Numero Telefonico no valido'})
    .max(10, {message: 'Límite: 10 carácteres'})
    .optional()
    .or(z.literal('')),
  titleId: z.coerce.number().default(1).optional(),
});

type InvestigatorInfo = z.infer<typeof investigatorProfileSchema>;

export default InvestigatorProfileMockup;

import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../store';
import {User} from '../../types/User';
import {Investigator} from '../../types/Investigator';
//import {history} from '../../helpers/history';
//const env = process.env;

interface AuthState {
  user?: User;
  id?: number;
  usu?: string;
  token?: string;
  error?: string;
}

interface LoginPayload {
  user: User;
  usu?: string;
  id?: number;
  token?: string;
}

const initialState: AuthState = {
  user: undefined,
  usu: undefined,
  id: 0,
  token: undefined,
  error: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // TODO: include the token/Usu alongside the user, in a single payloadAction
    loginAuth: (state, action: PayloadAction<LoginPayload>) => {
      state.user = action.payload.user;
      state.usu = action.payload.usu;
      state.id = action.payload.id;
      state.token = action.payload.token;
      //
      localStorage.setItem('user', JSON.stringify(action.payload.user) ?? '');
      localStorage.setItem('usu', action.payload.usu ?? '');
      localStorage.setItem('id', action.payload.id?.toString() ?? '');
      localStorage.setItem('token', action.payload.token ?? '');
    },
    loadAuthInfo: state => {
      if (state.token) return;

      const auxUser = localStorage.getItem('user') ?? undefined;
      const auxUsu = localStorage.getItem('usu') ?? undefined;
      const auxId = localStorage.getItem('id') ?? undefined;
      const auxToken = localStorage.getItem('token') ?? undefined;

      if (auxUser) state.user = JSON.parse(auxUser);
      if (auxUsu) state.usu = auxUsu;
      if (auxId) state.id = Number.parseInt(auxId);
      if (auxToken) state.token = auxToken;
    },
    logout: state => {
      state.user = undefined;
      state.usu = undefined;
      state.id = undefined;
      state.token = undefined;
      localStorage.removeItem('user');
      localStorage.removeItem('usu');
      localStorage.removeItem('id');
      localStorage.removeItem('token');
    },
  },
});

//We use this as a way to join extra actions, however we don't have them in this slice just yet
const authActions = {...authSlice.actions};

export const {logout, loginAuth, loadAuthInfo} = authActions;
export const selectUser = (state: RootState) => state.auth.user;
export const selectError = (state: RootState) => state.auth.error;

export default authSlice.reducer;

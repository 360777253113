import React from 'react';
import './NavigationBar.scss';

import {Container, Navbar, Nav} from 'react-bootstrap';
import Paths from '../../../constants/Paths';
import {useNavigate} from 'react-router-dom';

const NavigationBar = () => {
  const navigate = useNavigate();
  return (
    <Navbar bg="light" expand="lg" className="main-header top-bar">
      <Container className="h-75" fluid>
        <Navbar.Brand
          href={'https://investigacion.uanl.mx/proacti/'}
          target="_blank"
          className="d-flex flex-row">
          <h1 className="brand-text me-3 mb-0">ProACTI</h1>

          <div className="d-flex flex-row d-none d-sm-flex my-0 py-0 align-items-center">
            <div className="vr me-3 black-vertical-bar" />
            <p className="top-bar-subtitle-text my-0 py-0">
              PROGRAMA DE APOYO A LA
              <br />
              CIENCIA, TECNOLOGÍA E INNOVACIÓN
            </p>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-2">
            <Nav.Link
              href={Paths.LOGIN}
              onClick={e => {
                e.preventDefault();
                navigate(Paths.LOGIN);
              }}>
              Iniciar Sesión
            </Nav.Link>
            <Nav.Link
              href={Paths.SIGNUP}
              onClick={e => {
                e.preventDefault();
                navigate(Paths.SIGNUP);
              }}>
              Registro
            </Nav.Link>
            <Nav.Link href="/ProACTI-1.pdf" target="_blank">
              Guia
            </Nav.Link>
            {/*
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;

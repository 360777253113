import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../store';
import {Investigator} from '../../types/Investigator';
import {Project} from '../../types/Project';
import {Objective} from '../../types/Objective';
import {Convocation} from '../../types/Convocation';
//import {history} from '../../helpers/history';
//const env = process.env;

interface InvState {
  investigator?: Investigator;
  project?: Project;
  objectives?: Objective[];
  convocation?: Convocation;
}

const initialState: InvState = {
  investigator: undefined,
  project: undefined,
  objectives: undefined,
  convocation: undefined,
};

export const invSlice = createSlice({
  name: 'inv',
  initialState,
  reducers: {
    updateInvestigator: (state, action: PayloadAction<Investigator>) => {
      state.investigator = action.payload;
    },
    updateProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    updateObjectives: (state, action: PayloadAction<Objective[]>) => {
      state.objectives = action.payload;
    },
    invLogout: state => {
      state.investigator = undefined;
      state.project = undefined;
      state.objectives = undefined;
      state.convocation = undefined;
    },
    invalidateConvocation: state => {
      state.convocation = undefined;
    },
    updateConvocation: (state, action: PayloadAction<Convocation>) => {
      state.convocation = action.payload;
    },
  },
});

//We use this as a way to join extra actions, however we don't have them in this slice just yet
const authActions = {...invSlice.actions};

export const {
  updateInvestigator,
  updateProject,
  updateObjectives,
  invLogout,
  updateConvocation,
  invalidateConvocation,
} = authActions;
export const selectInvestigator = (state: RootState) => state.inv.investigator;
export const selectProject = (state: RootState) => state.inv.project;
export const selectObjectives = (state: RootState) => state.inv.objectives;
export const selectConvocation = (state: RootState) => state.inv.convocation;
export default invSlice.reducer;

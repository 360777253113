import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {Button, Form, Modal} from 'react-bootstrap';
import FormCampInput from '../../../components/FormCampInput';
import {useDispatch, useSelector} from 'react-redux';
import {Objective} from '../../../types/Objective';
import {
  useCreateObjectiveMutation,
  useUpdateObjectiveMutation,
} from '../../../redux/services/inv.api';
import {
  invalidateConvocation,
  selectConvocation,
  selectObjectives,
  selectProject,
  updateObjectives,
} from '../../../redux/slice/inv.slice';
import {toast} from 'react-toastify';
import {PickBiggestDate, PickSmallestDate} from '../../../helpers/dateHandle';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import {useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const ObjectiveModal = ({
  show,
  setShow,
  selectedObjective,
}: ObjectiveModalProps) => {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectProject);
  const currentConvocation = useSelector(selectConvocation);
  const isClosed = currentProject?.isClosed;
  const currentObjectives = useSelector(selectObjectives);
  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
    watch,
  } = useForm<ObjectiveModalInfo>({
    resolver: zodResolver(ObjectiveModalSchema),
    mode: 'onSubmit',
  });
  const watchDateStart = watch('dateStart');
  const watchDateEnd = watch('dateEnd');
  const navigate = useNavigate();

  const [createObjective, {isLoading: isCreateObjectiveLoading}] =
    useCreateObjectiveMutation();
  const [updateObjective, {isLoading: isUpdateObjectiveLoading}] =
    useUpdateObjectiveMutation();

  const handleCreateObjective = async (sendData: Objective) => {
    if (isClosed) return;
    const responseData = await createObjective(sendData);
    let objectiveData = undefined;
    if ('data' in responseData) {
      objectiveData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (objectiveData?.success) {
      if (objectiveData?.data) {
        // TODO: Handle Data
        const data = objectiveData?.data;
        let aux: Objective[];
        if (currentObjectives) {
          aux = [...currentObjectives, data];
        } else {
          aux = [data];
        }
        dispatch(updateObjectives(aux));

        setShow(false);
      }
      //toast.success('Objectivo creado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          navigate(Paths.investigator.OBJECTIVES);
          handleClose();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleUpdateObjective = async (sendData: Objective) => {
    if (isClosed) return;
    const responseData = await updateObjective(sendData);
    let objectiveData = undefined;
    if ('data' in responseData) {
      objectiveData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (objectiveData?.success) {
      if (objectiveData?.data) {
        // TODO: Handle Data
        const data = objectiveData?.data;
        let aux: Objective[];
        if (currentObjectives) {
          aux = currentObjectives.map(objective => {
            if (objective.objectiveId !== data.objectiveId) return objective;

            return {
              ...objective,
              name: data.name,
              dateStart: data.dateStart,
              dateEnd: data.dateEnd,
            };
          });

          dispatch(updateObjectives(aux));
        }
        setShow(false);
      }
      //toast.success('Objectivo actualizado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          navigate(Paths.investigator.OBJECTIVES);
          handleClose();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const onSubmit: SubmitHandler<ObjectiveModalInfo> = data => {
    if (selectedObjective)
      handleUpdateObjective({
        ...data,
        projectId: currentProject?.projectId || 0,
        objectiveId: selectedObjective.objectiveId,
        dateStart: new Date(data.dateStart),
        dateEnd: new Date(data.dateEnd),
      });
    else
      handleCreateObjective({
        ...data,
        objectiveId: 0,
        projectId: currentProject?.projectId || 0,
        dateStart: new Date(data.dateStart),
        dateEnd: new Date(data.dateEnd),
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      if (selectedObjective) {
        const dateStart = new Date(selectedObjective.dateStart);
        const dateEnd = new Date(selectedObjective.dateEnd);
        reset({
          ...selectedObjective,
          dateStart: dateStart.toISOString().split('T')[0],
          dateEnd: dateEnd.toISOString().split('T')[0],
        });
      }
    }
  }, [show]);

  const minStartDate = PickSmallestDate([currentConvocation?.registerStart]);
  const maxStartDate = PickSmallestDate([
    watchDateEnd,
    currentConvocation?.endDate,
  ]);

  const minEndDate = PickSmallestDate([
    watchDateStart,
    selectedObjective?.dateStart,
    currentConvocation?.registerStart,
  ]);

  const maxEndDate = PickBiggestDate([currentConvocation?.endDate]);

  return (
    <>
      {show && (
        <Modal
          show={show}
          onHide={() => {
            handleClose();
          }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedObjective ? 'Actualizar ' : 'Crear '}Objetivo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id="ObjectiveForm" onSubmit={handleSubmit(onSubmit)}>
              <FormCampInput
                formGroupClassName="mb-3"
                name="name"
                displayName="Nombre Objetivo"
                inputProps={{
                  as: 'textarea',
                  //style: {resize: 'none', height: '4em'},

                  ...register('name'),
                  disabled: isClosed,
                }}
                errors={errors}
                touchedFields={touchedFields}
              />
              <div className="d-flex justify-content-between">
                <FormCampInput
                  name="dateStart"
                  displayName="Fecha Inicio"
                  inputProps={{
                    type: 'date',
                    ...register('dateStart'),
                    disabled: isClosed,
                  }}
                  min={
                    minStartDate
                      ? new Date(minStartDate).toISOString().split('T')[0]
                      : undefined
                  }
                  max={
                    maxStartDate
                      ? new Date(maxStartDate).toISOString().split('T')[0]
                      : undefined
                  }
                  errors={errors}
                  touchedFields={touchedFields}
                />

                <FormCampInput
                  name="dateEnd"
                  displayName="Fecha Final"
                  inputProps={{
                    type: 'date',
                    ...register('dateEnd'),
                    disabled: isClosed,
                  }}
                  min={
                    minEndDate
                      ? new Date(minEndDate).toISOString().split('T')[0]
                      : undefined
                  }
                  max={
                    maxEndDate
                      ? new Date(maxEndDate).toISOString().split('T')[0]
                      : undefined
                  }
                  errors={errors}
                  touchedFields={touchedFields}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              disabled={isCreateObjectiveLoading || isUpdateObjectiveLoading}
              variant="secondary"
              onClick={() => {
                handleClose();
              }}>
              Cancelar
            </Button>
            <Button
              disabled={
                isCreateObjectiveLoading ||
                isUpdateObjectiveLoading ||
                !isDirty ||
                isClosed
              }
              className="ms-auto"
              form="ObjectiveForm"
              variant="success"
              type="submit">
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const ObjectiveModalSchema = z.object({
  name: z.string().trim().min(1, {message: 'Campo Obligatorio'}).max(150, {
    message: 'Nombre no puede superar 150 caracteres.',
  }),
  dateStart: z.coerce.date().or(z.string()),
  dateEnd: z.coerce.date().or(z.string()),
});

type ObjectiveModalInfo = z.infer<typeof ObjectiveModalSchema>;

type ObjectiveModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedObjective?: Objective;
};

export default ObjectiveModal;

import React, {useEffect, useState} from 'react';
import './ParticipationStage.css';
import Panel from '../../../components/Panel';
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import FormCampFile from '../../../components/FormCampFile';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {
  useCreateParticipationStageMutation,
  useGetUserParticipationStageMutation,
  useGetUserCurrentProjectMutation,
  useUpdateParticipationStageMutation,
  useGetCurrentConvocationMutation,
} from '../../../redux/services/inv.api';
import {ProjectParticipationStage} from '../../../types/ProjectParticipationStage';
import {ParticipationStageEnum} from '../../../constants/ParticipationState';
import {z} from 'zod';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {downloadPDF, validateFile} from '../../../helpers/ValidateFile';
import {useBlocker, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile} from '@fortawesome/free-regular-svg-icons';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';

const ParticipationStageScreen = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const navigate = useNavigate();

  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
    watch,
    setValue,
  } = useForm<ParticipationStageScreenInfo>({
    resolver: zodResolver(participationStageScreenSchema),
    mode: 'onSubmit',
  });

  const dispatch = useDispatch();
  const currentProject = useSelector(selectProject);

  const isClosed = currentProject?.isClosed;

  const watchInputFileList = watch('inputFileList');
  const watchDocumentFile = watch('documentFile');
  const watchDocumentName = watch('documentName');
  const watchParticipationStage = watch('participationStageId');

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [loading, setLoading] = useState(true);

  const [fileExists, setFileExists] = useState<boolean>(false);

  const [participationStageExisted, setParticipationStageExisted] =
    useState<boolean>(false);

  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();
  const [
    getUserParticipationStage,
    {isLoading: isGetUserCurrentStudentsLoading},
  ] = useGetUserParticipationStageMutation();
  const [
    registerParticipationStage,
    {isLoading: isRegisterParticipationStageLoading},
  ] = useCreateParticipationStageMutation();
  const [
    updateParticipationStage,
    {isLoading: isUpdateParticipationStageLoading},
  ] = useUpdateParticipationStageMutation();

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      //reset();
      await handleGetParticipationStage();
      setLoading(false);
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));

        await handleGetParticipationStage();
        setLoading(false);
        return;
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al intentar conseguir el proyecto.',
      );
    }
    //TODO: Warn user to register a project first to precede.
    toast.error(
      'Es necesario registrar el Nombre del Proyecto para continuar.',
    );
    navigate(Paths.investigator.REGISTERPROJECT);
    return;
  };

  const handleGetParticipationStage = async () => {
    const responseData = await getUserParticipationStage(undefined);

    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        reset({...data});
        setFileExists(data.fileExists);
        setParticipationStageExisted(true);

        return;
      }
    }
  };

  const handleRegisterParticipationStageSubmit = async (
    sendData: ProjectParticipationStage,
  ) => {
    if (isClosed) return;
    const responseData = await registerParticipationStage({
      ...sendData,
      projectId: currentProject?.projectId || 0,
    });

    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData.data;

        setParticipationStageExisted(true);
        reset({...data}, {keepValues: true});
        //toast.info('Registrado!');
        toast.success('Su información se guardo correctamente');
      }
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleUpdateParticipationStageSubmit = async (
    sendData: ProjectParticipationStage,
  ) => {
    if (isClosed) return;
    if (currentProject == undefined || currentProject.projectId == undefined)
      return;

    const responseData = await updateParticipationStage({
      ...sendData,
      projectId: currentProject?.projectId || 0,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData.data;

        setParticipationStageExisted(true);
        reset({...data}, {keepValues: true});
        //toast.info('Etapa de participacion actualizada!');
        toast.success('Su información se guardo correctamente');
      }
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const isStage = (stage: ParticipationStageEnum): string => {
    return watchParticipationStage === stage ? 'success' : 'primary';
  };
  const isStageText = (stage: ParticipationStageEnum): string => {
    return watchParticipationStage === stage ? 'Seleccionada' : 'Elegir';
  };

  const onSubmit: SubmitHandler<ParticipationStageScreenInfo> = data =>
    onSubmitStage(data);

  const onSubmitStage = (sendData: ParticipationStageScreenInfo) => {
    if (
      !sendData.documentFile &&
      watchParticipationStage === ParticipationStageEnum.Etapa3
    ) {
      toast.error(
        'La Carta de Interés es obligatoria para la etapa 3 seleccionada',
      );
      return;
    }

    if (participationStageExisted)
      handleUpdateParticipationStageSubmit({
        ...sendData,
        fileExists,
        projectId: currentProject?.projectId ?? 0,
      });
    else
      handleRegisterParticipationStageSubmit({
        ...sendData,
        fileExists,
        projectId: currentProject?.projectId ?? 0,
      });
  };

  useEffect(() => {
    checkConvocation();
    checkProject();
  }, []);

  useEffect(() => {
    if (watchInputFileList && watchInputFileList[0] !== undefined) {
      try {
        const file: File = watchInputFileList[0];

        const handlValidateFile = async (file: File) => {
          const result = await validateFile({file});

          if (result.error || !result.textFile || result.textFile === '') {
            toast.warning(result.error ?? 'Error desconocido con el pdf.');
            setValue('documentFile', undefined);
            setValue('inputFileList', undefined);
            return;
          }

          //toast.success('Archivo valido!');
          setValue('documentFile', result.textFile);
          setValue('documentName', result.fileName);

          setFileExists(true);
        };

        handlValidateFile(file);
      } catch (e) {
        const ex = (e as Error) ?? undefined;

        if (ex) {
          toast.error(
            'Ha ocurrido un problema, favor de intentarlo de nuevo.',
            {},
          );
        }

        console.log(ex);
      }
    }
  }, [watchInputFileList]);

  const tooltip = (
    <Tooltip id="button-tooltip" style={{position: 'fixed'}}>
      {watchDocumentName}
    </Tooltip>
  );

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <Form
            className="d-flex flex-column h-100 w-100 p-2"
            onSubmit={handleSubmit(onSubmit)}>
            <Row xs={12} className="px-5">
              <p className="text-center mb-3 title-text">
                Etapa de Participación
              </p>
            </Row>

            <div className="mb-4 d-flex flex-row align-items-center">
              <p className="mb-0 ms-auto me-2">Carta de Interés:</p>
              {watchDocumentFile &&
                watchDocumentFile !== '' &&
                watchDocumentName &&
                watchDocumentName !== '' && (
                  <>
                    <OverlayTrigger
                      placement={'left'}
                      delay={{show: 250, hide: 400}}
                      overlay={tooltip}>
                      <FontAwesomeIcon
                        icon={faFile}
                        color="blue"
                        cursor={'pointer'}
                        onClick={() =>
                          downloadPDF(
                            watchDocumentFile ? watchDocumentFile : '',
                            watchDocumentName,
                          )
                        }
                      />
                    </OverlayTrigger>
                    &nbsp;
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        downloadPDF(
                          watchDocumentFile ? watchDocumentFile : '',
                          watchDocumentName,
                        );
                      }}>
                      {watchDocumentName}
                    </a>
                    &nbsp; &nbsp;
                  </>
                )}

              <FormCampFile
                className=""
                size="sm"
                name={'inputFileList'}
                inputProps={{
                  ...register('inputFileList'),
                  disabled: isClosed || !isRegistrationActive,
                }}
              />
            </div>

            <Row className="h-100 mb-3">
              <Panel
                panelClassName="h-100 overflow-auto p-3 d-flex flex-column"
                overrideContainerProps={{fluid: true, className: 'h-100'}}>
                <h4 className="text-center">Etapa 1</h4>
                <ul>
                  <li>
                    Nivel 1: Investigación Aplicada-tecnología básica.
                    Observación y reporte de principios básicos
                  </li>
                  <li>
                    Nivel 2: Validación conceptual. Concepto de la
                    tecnología/innovación social o formulación de la aplicación
                  </li>
                  <li>
                    Nivel 3: Validación conceptual. Prueba de concepto II.
                  </li>
                </ul>
                <p>Carta de interés deseable.</p>
                <div className="text-center mt-auto">
                  <Button
                    disabled={isClosed || !isRegistrationActive}
                    variant={isStage(ParticipationStageEnum.Etapa1)}
                    onClick={() => {
                      setValue(
                        'participationStageId',
                        ParticipationStageEnum.Etapa1,
                        {shouldDirty: true},
                      );
                    }}>
                    {isStageText(ParticipationStageEnum.Etapa1)}
                  </Button>
                </div>
              </Panel>
              <Panel
                panelClassName="h-100 overflow-auto p-3 d-flex flex-column"
                overrideContainerProps={{fluid: true, className: 'h-100'}}>
                <h4 className="text-center">Etapa 2</h4>
                <ul>
                  <li>
                    Nivel 4: Desarrollo tecnológico / Modelo de innovación
                    social. Validación de componentes o sistema en un ambiente
                    laboratorio.
                  </li>
                  <li>
                    Nivel 5: Desarrollo tecnológico / Modelo de innovación
                    social. Componentes integrados a manera que la configuración
                    del sistema sea similar a su aplicación final. Su
                    operatividad es aún a nivel laboratorio.
                  </li>
                  <li>
                    Nivel 6: Demostración tecnológica o del modelo de innovación
                    social. Sistema de ingeniería en validación en ambiente en
                    condiciones relevantes a las reales operativas. Innovación
                    social en validación en ambiente en condiciones relevantes a
                    las reales operativas. Aun a nivel prototipo/Modelo de
                    Innovación social.
                  </li>
                </ul>
                <p>Carta de interés se tomará en cuenta para evaluación.</p>
                <div className="text-center mt-auto">
                  <Button
                    disabled={isClosed || !isRegistrationActive}
                    variant={isStage(ParticipationStageEnum.Etapa2)}
                    onClick={() => {
                      setValue(
                        'participationStageId',
                        ParticipationStageEnum.Etapa2,
                        {shouldDirty: true},
                      );
                    }}>
                    {isStageText(ParticipationStageEnum.Etapa2)}
                  </Button>
                </div>
              </Panel>
              <Panel
                panelClassName="h-100 overflow-auto p-3 d-flex flex-column"
                overrideContainerProps={{fluid: true, className: 'h-100'}}>
                <h4 className="text-center">Etapa 3</h4>
                <ul>
                  <li>
                    Nivel 7: Comisionamiento de sistemas.Prototipo/Modelo de
                    Innovación social completo demostrando en ambiente
                    relevante.
                  </li>
                  <li>
                    Nivel 8: Comisionamiento de sistemas. Sistema final completo
                    y evaluado a través de prueba y demostración.
                  </li>
                  <li>
                    Nivel 9: Operación del sistema. Operación del sistema.
                  </li>
                </ul>
                <p>Carta de interés obligatoria.</p>
                <div className="text-center mt-auto">
                  <Button
                    variant={isStage(ParticipationStageEnum.Etapa3)}
                    disabled={isClosed || !isRegistrationActive}
                    onClick={() => {
                      setValue(
                        'participationStageId',
                        ParticipationStageEnum.Etapa3,
                        {shouldDirty: true},
                      );
                    }}>
                    {isStageText(ParticipationStageEnum.Etapa3)}
                  </Button>
                </div>
              </Panel>
            </Row>

            <Row className="pb-3">
              <Col xs={{offset: 5, span: 3}}>
                <Button
                  hidden={isDirty}
                  variant="primary"
                  onClick={() =>
                    navigate(Paths.investigator.FINANCIALBREAKDOWN)
                  }>
                  Siguiente
                </Button>
                <Button
                  hidden={!isDirty}
                  disabled={
                    watchParticipationStage === undefined ||
                    isRegisterParticipationStageLoading ||
                    isUpdateParticipationStageLoading ||
                    !isRegistrationActive
                  }
                  variant="success"
                  type="submit">
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>

      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estás seguro que deseas salir y perder los cambios que han hecho?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

const participationStageScreenSchema = z.object({
  participationStageId: z
    .number()
    .min(ParticipationStageEnum.Etapa1)
    .max(ParticipationStageEnum.Etapa3),
  documentFile: z.string().max(31457280).optional(),
  documentName: z.string().max(500).optional(),
  inputFileList: z.any().optional(),

  projectParticipationStageId: z.number().default(0),
  projectId: z.number().default(0),
});

type ParticipationStageScreenInfo = z.infer<
  typeof participationStageScreenSchema
>;

export default ParticipationStageScreen;

import type {RootState} from '../redux/store';
import {User} from '../types/User';

export function getToken(state: unknown): string | undefined {
  return (state as RootState).auth.token || '';
}

export function getUser(state: unknown): User | undefined {
  return (state as RootState).auth.user || undefined;
}

export function getUsu(state: unknown): string | undefined {
  return (state as RootState).auth.usu || undefined;
}

export function getId(state: unknown): number | undefined {
  return (state as RootState).auth.id || undefined;
}

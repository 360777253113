import React from 'react';
import {useDispatch, useStore} from 'react-redux';
import {Container} from 'react-bootstrap';
import {Navigate, Outlet} from 'react-router-dom';
import {loadAuthInfo} from '../redux/slice/auth.slice';
import {getUser} from '../helpers/authTools';
import {Role} from '../constants/Role';
import Paths from '../constants/Paths';

const ProtectedRoutes = ({
  authRole,
  sideBar,
  navBar,
  bottomBar,
  container,
  wrapper,
}: ProtectedRoutesProps) => {
  const dispatch = useDispatch();
  const store = useStore();

  const getRoute = (role: Role) => {
    let result = Paths.LOGIN;

    switch (role) {
      case Role.Administrator:
        result = Paths.admin.HUB;
        break;
      case Role.Investigator:
        result = Paths.investigator.HUB;
        break;
      case Role.Assistant:
        break;
      case Role.Auditor:
        break;
    }

    return result;
  };

  const getProtectedRouteResult = () => {
    dispatch(loadAuthInfo());
    const user = getUser(store.getState());

    if (!authRole) {
      if (user) return <Navigate to={getRoute(user.roleId)} replace />;

      return resultRoute;
    }

    if (user) {
      let navigateRoute: string | undefined = undefined;
      if (user?.roleId !== authRole) navigateRoute = getRoute(user.roleId);

      return navigateRoute ? (
        <Navigate to={navigateRoute} replace />
      ) : (
        resultRoute
      );
    } else {
      return <Navigate to="/login" replace />;
    }
  };

  const prepRoute = (
    <>
      {sideBar}
      {navBar}
      {bottomBar}
      {container && (
        <Container fluid className="p-0 hub-container main-container">
          <Container fluid className="h-100 d-flex flex-column">
            <Outlet />
          </Container>
        </Container>
      )}
      {!container && <Outlet />}
    </>
  );

  const resultRoute = wrapper ? (
    <div className="main-wrapper main-wrapper-hub">{prepRoute}</div>
  ) : (
    prepRoute
  );

  return <>{getProtectedRouteResult()}</>;
  //return resultRoute;
};

type ProtectedRoutesProps = {
  authRole?: Role;
  sideBar?: JSX.Element;
  navBar?: JSX.Element;
  bottomBar?: JSX.Element;
  container?: boolean;
  wrapper?: boolean;
};

export default ProtectedRoutes;

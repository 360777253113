import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import './../Report.scss';
import './FinancialBreakdown.scss';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGetFinancialBreakdownReportInformationMutation} from '../../../redux/services/inv.api';
import {OutFinancialBreakdownReportDto} from '../../../types/Dtos/InvDtos';
import {MexCurrency} from '../../../constants/MexCurrency';
import Paths from '../../../constants/Paths';

const FinancialBreakdownReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');

  const refHeader = useRef<HTMLElement>(null);
  const refFooter = useRef<HTMLElement>(null);
  const refPDF = useRef<HTMLDivElement>(null);
  const refSignatures = useRef<HTMLDivElement>(null);

  const [
    getFinancialBreakdownReportInformation,
    {isLoading: isGetFinancialBreakdownReportInformationLoading},
  ] = useGetFinancialBreakdownReportInformationMutation();

  const [reportInfo, setFinancialBreakdownInfo] = useState<
    OutFinancialBreakdownReportDto | undefined
  >(undefined);

  const [headerSize, setHeaderSize] = useState<number>(0);
  const [footerSize, setFooterSize] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);

  const getFinancialBreakdownAsync = async () => {
    if (!projectId) return false;
    const responseData = await getFinancialBreakdownReportInformation(
      Number.parseInt(projectId),
    );

    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data; //TODO: Es esto correcto?
        let aux = 0;
        data.rubric &&
          data.rubric.map(rubric => {
            if (rubric.amount) aux = aux + rubric.amount;
          });
        setTotal(aux);

        setFinancialBreakdownInfo({
          ...data,
          date: new Date(data.date),
          director: {
            ...data.director,
            title: data.director.title.toLowerCase(),
            name: data.director.name.toLowerCase(),
            firstSurname: data.director.firstSurname.toLowerCase(),
            secondSurname: data.director.secondSurname.toLowerCase(),
          },
          user: {
            ...data.user,
            name: data.user.name.toLowerCase(),
            firstSurname: data.user.firstSurname.toLowerCase(),
            secondSurname: data.user.secondSurname.toLowerCase(),
          },
          investigator: {
            ...data.investigator,
            title: data.investigator.title.toLowerCase(),
          },
        });

        return true;
      }
    }

    return false;
  };

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);

  useEffect(() => {
    const load = async () => {
      if (!(await getFinancialBreakdownAsync())) {
        navigate(Paths.LOGIN);
      }
    };
    load();
  }, []);

  useEffect(() => {
    //fetchData();
    console.log(headerSize);
    forceUpdate();
  }, [headerSize]);

  useEffect(() => {
    //fetchData();
    console.log(footerSize);
    forceUpdate();
  }, [footerSize]);

  return (
    <>
      <div className="pdf d-flex flex-column" ref={refPDF}>
        {!isGetFinancialBreakdownReportInformationLoading && reportInfo && (
          <div
            className="px-4 d-flex flex-column"
            style={{
              //padding: 0,
              margin: 0,
              paddingBottom: refFooter.current?.clientHeight || 0,
            }}>
            <table className="tablePDF">
              <thead>
                <div
                  style={{
                    padding: 0,
                    margin: 0,
                    height: refHeader.current?.clientHeight || 0,
                  }}>
                  &nbsp;
                </div>
              </thead>
              <tbody>
                <div className="d-flex flex-column">
                  <p>
                    Nombre del Proyecto:&nbsp;
                    {reportInfo?.project.title}
                  </p>
                  <p className="ms-auto">
                    Clave del Proyecto:&nbsp;
                    {reportInfo?.project.projectKey}
                  </p>
                </div>

                <div className="w-100">
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="report-table-container report-head">
                    <thead className="report-head">
                      <tr>
                        <th>Rubro:</th>
                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody className="report-table">
                      {reportInfo?.rubric &&
                        reportInfo.rubric.map(rubric => {
                          if (rubric.amount)
                            return (
                              <>
                                <tr>
                                  <td>{rubric.name}</td>
                                  <td>{`${MexCurrency.format(
                                    rubric.amount,
                                  )}`}</td>
                                </tr>
                              </>
                            );
                        })}

                      <tr>
                        <td>Total</td>
                        <td>{`${MexCurrency.format(total)}`}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <Row className="firma-container" ref={refSignatures}>
                  <Col xs={6}>
                    <div className="Firma">
                      <p>__________________________________________</p>
                      <p className="text-capitalize texto-firma">
                        {`${reportInfo?.director.title} ${reportInfo?.director.name} ${reportInfo?.director.firstSurname} ${reportInfo?.director.secondSurname}`}
                      </p>
                      <p className="texto-firma">DIRECTOR (A)</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="Firma">
                      <p>__________________________________________</p>
                      <p className="text-capitalize texto-firma">
                        {`${reportInfo?.investigator.title} ${reportInfo?.user.name} ${reportInfo?.user.firstSurname} ${reportInfo?.user.secondSurname}`}
                      </p>
                      <p className="texto-firma">RESPONSABLE DEL PROYECTO</p>
                    </div>
                  </Col>
                </Row>
              </tbody>
              <tfoot>
                <div style={{height: refFooter.current?.clientHeight || 0}}>
                  &nbsp;
                </div>
              </tfoot>
            </table>

            <header
              className="px-3 pt-3"
              ref={refHeader}
              onLoad={() => {
                setHeaderSize(refHeader.current?.clientHeight || 0);
              }}>
              <div className="tablaTitulo pb-4 w-100 d-flex flex-row" id="test">
                <div className="mx-2">
                  <img width="125" height="62.5" src="\UANL_Logo.png"></img>
                </div>
                <div className="mx-2">
                  <p>
                    SECRETARÍA DE INVESTIGACIÓN CIENTÍFICA Y DESARROLLO
                    TECNOLÓGICO
                  </p>

                  <p>Dirección de Investigación</p>
                  <p>Programa de Apoyo a la Ciencia, Tecnología e Innovación</p>
                  <br />
                  <p className="desgloseTitulo">Desglose Financiero</p>
                  <p className="letrasChicas">{`Fecha de finalizacion de registro del proyecto: ${reportInfo?.date.toLocaleDateString(
                    'es-MX',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    },
                  )}`}</p>
                </div>
                <div className="image-header-right mx-2">
                  <img width="75" height="75" src="\UANL90.png"></img>
                </div>
              </div>
            </header>

            <footer
              className="px-3 pb-4 true-footer"
              ref={refFooter}
              onLoad={() => {
                setFooterSize(refFooter.current?.clientHeight || 0);
              }}>
              <p className="letrasChicas">
                *Este documento debe ser entregado con firmas originales y con
                el sello de la dependencia.
              </p>
            </footer>
          </div>
        )}
      </div>
    </>
  );
};

export default FinancialBreakdownReport;

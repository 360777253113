import React, {useEffect, useState} from 'react';
import './Objectives.scss';
import Panel from '../../../components/Panel';
import {Button, Form, Modal, Row, Spinner, Table} from 'react-bootstrap';
import FormCampInput from '../../../components/FormCampInput';
import ObjectiveModal from './ObjectiveModal';

import {Objective} from '../../../types/Objective';
import {Activity} from '../../../types/Activity';
import {useDispatch, useSelector} from 'react-redux';
import {
  invalidateConvocation,
  selectConvocation,
  selectObjectives,
  selectProject,
  updateConvocation,
  updateObjectives,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {
  useDeleteActivityMutation,
  useDeleteObjectiveMutation,
  useGetCurrentConvocationMutation,
  useGetObjectivesActivitiesMutation,
  useGetUserCurrentProjectMutation,
  useUpdateObjectivesDescriptionMutation,
} from '../../../redux/services/inv.api';
import {z} from 'zod';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {InUpdateObjectivesDescriptionDto} from '../../../types/Dtos/InvDtos';
import {Project} from '../../../types/Project';
import RowObjective from './RowObjective';
import RowActivity from './RowActivity';
import {toast} from 'react-toastify';
import {useBlocker, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import ActivityModal from './ActivityModal';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import TooltipWrap from '../../../components/TooltipWrap';
import {RequieredForFinalizing} from '../../../constants/RequieredForFinalizing';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const Objectives = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const navigate = useNavigate();

  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
  } = useForm<ObjectiveInfo>({
    resolver: zodResolver(ObjectiveSchema),
    mode: 'onSubmit',
  });

  const dispatch = useDispatch();
  const currentProject = useSelector(selectProject);
  const currentObjectives = useSelector(selectObjectives);
  const isClosed = currentProject?.isClosed;

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [loading, setLoading] = useState(true);
  const [showObjective, setShowObjective] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [showActivityDelete, setShowActivityDelete] = useState(false);
  const [showObjectiveDelete, setShowObjectiveDelete] = useState(false);

  const [selectedObjective, setSelectedObjective] = useState<
    Objective | undefined
  >(undefined);
  const [selectedActivity, setSelectedActivity] = useState<
    Activity | undefined
  >(undefined);
  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();
  const [
    updateObjectivesDescription,
    {isLoading: isUpdateObjectivesDescriptionLoading},
  ] = useUpdateObjectivesDescriptionMutation();

  const [
    getObjectivesActivities,
    {isLoading: isGetObjectivesActivitiesLoading},
  ] = useGetObjectivesActivitiesMutation();

  const [deleteObjective, {isLoading: isDeleteObjectiveLoading}] =
    useDeleteObjectiveMutation();

  const [deleteActivity, {isLoading: isDeleteActivityLoading}] =
    useDeleteActivityMutation();

  useEffect(() => {
    checkConvocation();
    checkProject();
  }, []);

  useEffect(() => {
    if (currentProject)
      reset({generalObjective: currentProject.generalObjective});
  }, [currentProject]);

  useEffect(() => {
    if (!showObjective && !showActivity) {
      setSelectedObjective(undefined);
      setSelectedActivity(undefined);
    }
  }, [showObjective, showActivity]);

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      reset({generalObjective: currentProject.generalObjective});
      await handleGetObjectivesActivities(currentProject);
      setLoading(false);
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));
        reset(data);
        await handleGetObjectivesActivities(data);
        setLoading(false);
        return;
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir el proyecto.',
      );
    }
    //TODO: Warn user to register a project first to precede.
    toast.error(
      'Es necesario registrar el Nombre del Proyecto para continuar.',
    );
    navigate(Paths.investigator.REGISTERPROJECT);
    return;
  };

  const handleGetObjectivesActivities = async (project: Project) => {
    const responseData = await getObjectivesActivities(project.projectId);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateObjectives(data));
        return;
      }
    } else {
      toast.error(
        resultData?.message ??
          'Error intentando conseguir los objetivos/actividades',
      );
    }
  };

  const ShowCreateActivityModal = (objective: Objective) => {
    setSelectedObjective(objective);
    setShowActivity(true);
  };

  const ShowUpdateActivityModal = (
    objective: Objective,
    activity: Activity,
  ) => {
    setSelectedObjective(objective);
    setSelectedActivity(activity);
    setShowActivity(true);
  };

  const ShowDeleteActivityModal = (
    objective: Objective,
    activity: Activity,
  ) => {
    setSelectedObjective(objective);
    setSelectedActivity(activity);
    setShowActivityDelete(true);
  };

  const ShowDeleteObjectiveModal = (objective: Objective) => {
    setSelectedObjective(objective);
    setShowObjectiveDelete(true);
  };

  const ShowCreateObjectiveModal = () => {
    setSelectedObjective(undefined);
    setShowObjective(true);
  };

  const ShowUpdateObjectiveModal = (objective: Objective) => {
    setSelectedObjective(objective);
    setShowObjective(true);
  };

  const onSubmit: SubmitHandler<ObjectiveInfo> = data => {
    handleUpdateObjectivesDescriptionSubmit({
      ...data,
      projectId: currentProject?.projectId || 0,
    });
  };

  const handleUpdateObjectivesDescriptionSubmit = async (
    sendData: InUpdateObjectivesDescriptionDto,
  ) => {
    if (isClosed) return;
    const responseData = await updateObjectivesDescription(sendData);
    let objectiveData = undefined;
    if ('data' in responseData) {
      objectiveData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }

    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (objectiveData?.success) {
      if (objectiveData?.data) {
        dispatch(updateProject(objectiveData.data));
      }
      reset({}, {keepValues: true});
      //toast.info('Descripcion actualizada!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleDeleteObjective = async (sendData: Objective) => {
    if (isClosed) return;
    const responseData = await deleteObjective(sendData);
    let objectiveData = undefined;
    if ('data' in responseData) {
      objectiveData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }

    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (objectiveData?.success) {
      // TODO: Handle Data
      let aux: Objective[];
      if (currentObjectives) {
        aux = currentObjectives.filter(
          objective => objective.objectiveId !== sendData.objectiveId,
        );

        dispatch(updateObjectives(aux));
      }
      setShowObjectiveDelete(false);
      setSelectedObjective(undefined);
      //toast.info('Objetivo borrado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleDeleteActivity = async (sendData: Activity) => {
    if (isClosed) return;
    const responseData = await deleteActivity(sendData);
    let activityData = undefined;
    if ('data' in responseData) {
      activityData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }

    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (activityData?.success) {
      // TODO: Handle Data
      let aux: Objective[];
      if (currentObjectives) {
        aux = currentObjectives.map(objective => {
          if (objective.objectiveId !== sendData.objectiveId) return objective;

          const superAux = objective.activities?.filter(
            activity => activity.activityId !== sendData.activityId,
          );

          return {...objective, activities: superAux};
        });

        dispatch(updateObjectives(aux));
      }
      setShowActivityDelete(false);
      setSelectedActivity(undefined);
      //toast.info('Actividad borrada!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <div className="d-flex flex-column h-100 w-100 p-2">
            <Row xs={12} className="px-5">
              <p className="text-center mb-3 login-text">
                Objetivos y Actividades
              </p>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row xs={12} className="px-5">
                <div className="d-flex flex-row col-12 pt-2 pe-3 align-items-center">
                  <FormCampInput
                    formGroupClassName="w-100"
                    name="generalObjective"
                    {...RequieredForFinalizing}
                    displayName="Objetivo General"
                    max="3000"
                    displayCount
                    inputProps={{
                      as: 'textarea',
                      style: {height: '6em'},
                      ...register('generalObjective'),
                      disabled: isClosed || !isRegistrationActive,
                    }}
                    errors={errors}
                    //touchedFields={touchedFields}
                  />
                  <div className="ms-3 mt-auto d-block">
                    <Button
                      variant="success"
                      type="submit"
                      className="d-block"
                      disabled={
                        isUpdateObjectivesDescriptionLoading ||
                        !isDirty ||
                        !isRegistrationActive
                      }>
                      Guardar
                    </Button>
                    <br />
                  </div>
                </div>
                <div className="d-flex flex-row mb-1">
                  <TooltipWrap toolTip="Nuevo Objetivo">
                    <Button
                      disabled={isClosed || !isRegistrationActive}
                      className="ms-auto"
                      onClick={() => {
                        ShowCreateObjectiveModal();
                      }}>
                      Nuevo
                    </Button>
                  </TooltipWrap>
                </div>

                <Form.Group
                  className="mb-3"
                  controlId="financialBreakdownTableId">
                  <Table striped bordered hover>
                    <thead style={{color: 'white'}}>
                      <tr>
                        <th className="w-50">Objetivos y Actividades</th>
                        <th>Fecha Inicial</th>
                        <th>Fecha Final</th>
                        <th style={{width: '15%'}}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentObjectives &&
                        currentObjectives.map(objective => {
                          return (
                            <>
                              <RowObjective
                                objective={objective}
                                ShowCreateActivityModal={
                                  ShowCreateActivityModal
                                }
                                ShowUpdateObjectiveModal={
                                  ShowUpdateObjectiveModal
                                }
                                ShowDeleteObjectiveModal={
                                  ShowDeleteObjectiveModal
                                }
                              />

                              {objective.activities &&
                                objective.activities.map(activity => {
                                  return (
                                    <>
                                      <RowActivity
                                        objective={objective}
                                        activity={activity}
                                        ShowUpdateActivityModal={
                                          ShowUpdateActivityModal
                                        }
                                        ShowDeleteActivityModal={
                                          ShowDeleteActivityModal
                                        }
                                      />
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="text-center pb-3">
                    <Button
                      className="ms-3"
                      onClick={() => {
                        navigate(Paths.investigator.INSTITUTESCOLLABS);
                      }}>
                      Siguiente
                    </Button>
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </div>
        )}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>

      {showObjective && (
        <ObjectiveModal
          show={showObjective}
          setShow={setShowObjective}
          selectedObjective={selectedObjective}
        />
      )}

      {showActivity && (
        <ActivityModal
          show={showActivity}
          setShow={setShowActivity}
          selectedObjective={selectedObjective}
          selectedActivity={selectedActivity}
        />
      )}

      {/*Modal de salir!*/}
      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estas seguro que deseas salir y perder los cambios que han hechos?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {/*Modal de borrar OBJETIVO!*/}
      {showObjectiveDelete && (
        <Modal
          show={showObjectiveDelete}
          onHide={() => {
            setShowObjectiveDelete(false);
            return;
          }}>
          <Modal.Header closeButton>
            <Modal.Title>
              Borrar Objetivo
              {selectedObjective
                ? ' ' + selectedObjective.name
                : ' No encontrado'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Estas por borrar este objetivo ¿estas seguro?</p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              // disabled={isCreateObjectiveLoading || isUpdateObjectiveLoading}
              variant="secondary"
              onClick={() => {
                setShowObjectiveDelete(false);
                return;
              }}>
              No
            </Button>
            <Button
              disabled={isDeleteObjectiveLoading}
              className="ms-auto"
              form="ObjectiveForm"
              variant="primary"
              onClick={() => {
                if (selectedObjective) handleDeleteObjective(selectedObjective);
                return;
              }}>
              Si
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/*Modal de borrar Actividad!*/}
      {showActivityDelete && (
        <Modal
          show={showActivityDelete}
          onHide={() => {
            setShowActivityDelete(false);
            return;
          }}>
          <Modal.Header closeButton>
            <Modal.Title>
              Borrar Actividad
              {selectedActivity
                ? ' ' + selectedActivity.name
                : ' No encontrada'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Estas por borrar esta actividad ¿estas seguro?</p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              // disabled={isCreateObjectiveLoading || isUpdateObjectiveLoading}
              variant="secondary"
              onClick={() => {
                setShowActivityDelete(false);
                return;
              }}>
              No
            </Button>
            <Button
              disabled={isDeleteActivityLoading}
              className="ms-auto"
              form="ObjectiveForm"
              variant="primary"
              onClick={() => {
                if (selectedActivity) handleDeleteActivity(selectedActivity);
                return;
              }}>
              Si
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const ObjectiveSchema = z.object({
  generalObjective: z
    .string()
    .trim()
    .min(1, {message: 'Campo Obligatorio'})
    .max(3000, {
      message: 'Descripcion no puede superar 3000 caracteres.',
    }),
});

type ObjectiveInfo = z.infer<typeof ObjectiveSchema>;

export default Objectives;

const Paths = {
  LANDING: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  investigator: {
    HUB: '/Hub',
    PROFILE: '/Profile',
    FINALIZEREGISTER: '/FinalizeRegister',
    FINANCIALBREAKDOWN: '/FinancialBreakdown',
    INSTITUTESCOLLABS: '/InstitutesCollabs',
    OBJECTIVES: '/Objectives',
    PARTICIPATIONSTAGE: '/ParticipationStage',
    REGISTERPROJECT: '/RegisterProject',
    STUDENTSINFORMATION: '/StudentsInformation',
  },
  admin: {
    LOGIN: '/admin',
    HUB: '/admin/hub',
    PROJECTLIST: '/admin/ProjectList',
  },
  report: {
    FINANCIALBREAKDOWN: '/report/financialbreakdown',
    OBJECTIVES: '/report/Objectives',
    HELPREQUEST: '/report/HelpRequest',
  },
};
export default Paths;

import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import './../Report.scss';
import './HelpRequest.scss';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGetHelpRequestReportInformationMutation} from '../../../redux/services/inv.api';
import {OutHelpRequestReportDto} from '../../../types/Dtos/InvDtos';
import Paths from '../../../constants/Paths';

const HelpRequestReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');

  const refHeader = useRef<HTMLElement>(null);
  const refFooter = useRef<HTMLElement>(null);
  const refPDF = useRef<HTMLDivElement>(null);
  const refSignatures = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLTableElement>(null);
  const refContentHeader = useRef<HTMLTableSectionElement>(null);

  const [
    getHelpRequestReportInformation,
    {isLoading: isGetHelpRequestReportInformationLoading},
  ] = useGetHelpRequestReportInformationMutation();

  const [reportInfo, setHelpRequestInfo] = useState<
    OutHelpRequestReportDto | undefined
  >(undefined);

  const [headerSize, setHeaderSize] = useState<number>(0);
  const [footerSize, setFooterSize] = useState<number>(0);

  const [EtapaParticipacionTexto, setEtapaParticipacionTexto] = useState<
    string | undefined
  >(undefined);

  const getHelpRequestAsync = async () => {
    if (!projectId) return false;

    const responseData = await getHelpRequestReportInformation(
      Number.parseInt(projectId),
    );

    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data; //TODO: Es esto correcto?
        const stage = data.participationStageId;
        setHelpRequestInfo({
          ...data,
          date: new Date(data.date),
          director: {
            ...data.director,
            name: data.director.name.toLowerCase(),
            firstSurname: data.director.firstSurname.toLowerCase(),
            secondSurname: data.director.secondSurname.toLowerCase(),
          },
          user: {
            ...data.user,
            name: data.user.name.toLowerCase(),
            firstSurname: data.user.firstSurname.toLowerCase(),
            secondSurname: data.user.secondSurname.toLowerCase(),
          },
        });
        switch (stage) {
          case 1:
            setEtapaParticipacionTexto('Etapa 1');
            break;

          case 2:
            setEtapaParticipacionTexto('Etapa 2');
            break;

          case 3:
            setEtapaParticipacionTexto('Etapa 3');
            break;

          default:
            break;
        }

        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const load = async () => {
      if (!(await getHelpRequestAsync())) {
        navigate(Paths.LOGIN);
      }
    };
    load();
  }, []);

  // const pdfHeight = refPDF.current?.clientHeight || 0;
  // const contentHeight = refContent.current?.clientHeight || 0;
  // const contentHeaderHeight = refContentHeader.current?.clientHeight || 0;
  // const headerHeight = refHeader.current.clientHeight || 0;
  // const footerHeight = refFooter.current.clientHeight || 0;
  // const signatureHeight = refSignatures.current?.clientHeight || 0;

  // const allocatedSize = pdfHeight - headerHeight - footerHeight ?? 0;

  // const modResult = allocatedSize % contentHeight;

  // const contentTotalHeaderHeight =
  //   contentHeaderHeight *
  //   Math.max(Math.floor(contentHeight / allocatedSize) - 1, 0);

  // const spaceBetweenContentAndSignature =
  //   modResult - signatureHeight - contentTotalHeaderHeight;

  // const cal =
  //   modResult + (allocatedSize - signatureHeight - contentTotalHeaderHeight);

  // const resCalculation =
  //   spaceBetweenContentAndSignature > 0 && contentHeight < allocatedSize
  //     ? spaceBetweenContentAndSignature
  //     : cal;

  // console.log({
  //   pdfHeight,
  //   contentHeight,
  //   headerHeight,
  //   footerHeight,
  //   signatureHeight,
  //   allocatedSize,
  //   modResult,
  //   cal,
  //   resCalculation,
  //   spaceBetweenContentAndSignature,
  // });
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);

  useEffect(() => {
    //fetchData();
    console.log(headerSize);
    forceUpdate();
  }, [headerSize]);

  useEffect(() => {
    //fetchData();
    console.log(footerSize);
    forceUpdate();
  }, [footerSize]);

  return (
    <>
      <div className="pdf d-flex flex-column" ref={refPDF}>
        {!isGetHelpRequestReportInformationLoading && reportInfo && (
          <div
            className="px-4 d-flex flex-column"
            style={{
              //padding: 0,
              margin: 0,
              paddingBottom: refFooter.current?.clientHeight || 0,
            }}>
            <header
              className="px-3 pt-3"
              ref={refHeader}
              onLoad={() => {
                setHeaderSize(refHeader.current?.clientHeight || 0);
              }}>
              <div className="tablaTitulo pb-4 w-100 d-flex flex-row" id="test">
                <div className="mx-2">
                  <img width="125" height="62.5" src="\UANL_Logo.png"></img>
                </div>
                <div className="mx-2">
                  <p>
                    SECRETARÍA DE INVESTIGACIÓN CIENTÍFICA Y DESARROLLO
                    TECNOLÓGICO
                  </p>

                  <p>Dirección de Investigación</p>
                  <p>Programa de Apoyo a la Ciencia, Tecnología e Innovación</p>
                  <br />
                  <p className="desgloseTitulo">Solicitud de apoyo</p>
                  <p className="letrasChicas">{`Fecha de finalizacion de registro del proyecto: ${reportInfo?.date.toLocaleDateString(
                    'es-MX',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    },
                  )}`}</p>
                </div>
                <div className="image-header-right mx-2">
                  <img width="75" height="75" src="\UANL90.png"></img>
                </div>
              </div>
            </header>

            <footer
              className="px-3 pb-4 true-footer"
              ref={refFooter}
              onLoad={() => {
                setFooterSize(refFooter.current?.clientHeight || 0);
              }}>
              <p className="letrasChicas">
                *Este documento debe ser entregado con firmas originales y con
                el sello de la dependencia.
              </p>
            </footer>

            {refHeader.current && refFooter.current && (
              <>
                <table className="tablePDF">
                  <thead>
                    <div
                      style={{
                        padding: 0,
                        margin: 0,
                        height: refHeader.current?.clientHeight || 0,
                      }}>
                      &nbsp;
                    </div>
                  </thead>
                  <tbody>
                    <div className="w-100">
                      <Table
                        striped
                        bordered
                        hover
                        responsive
                        className="report-table-container report-head"
                        ref={refContent}>
                        <thead
                          className="report-head"
                          ref={refContentHeader}
                          style={{
                            marginTop: refHeader.current?.clientHeight || 0,
                          }}>
                          <tr>
                            <th colSpan={2} className="text-center">
                              INFORMACIÓN DEL PROYECTO QUE PARTICIPA EN LA
                              CONVOCATORIA
                            </th>
                          </tr>
                        </thead>
                        <tbody className="report-table">
                          <tr>
                            <td>Clave del proyecto:</td>
                            <td>{reportInfo?.project.projectKey}</td>
                          </tr>
                          <tr>
                            <td>Nombre de Investigador:</td>
                            <td className="text-capitalize">
                              {reportInfo.investigator.title
                                ? `${reportInfo.investigator.title} `
                                : ''}
                              {reportInfo?.user.name +
                                ' ' +
                                reportInfo?.user.firstSurname +
                                ' ' +
                                reportInfo?.user.secondSurname}
                            </td>
                          </tr>
                          <tr>
                            <td>Número de empleado:</td>
                            <td>{reportInfo?.user.employeeNumber}</td>
                          </tr>
                          <tr>
                            <td className="text-wrap">Nombre del proyecto:</td>
                            <td>{reportInfo?.project.title}</td>
                          </tr>
                          <tr>
                            <td>Etapa en que participa:</td>
                            <td>{EtapaParticipacionTexto}</td>
                          </tr>
                          <tr>
                            <td>Área de conocimiento:</td>
                            <td>{reportInfo?.investigationArea.name}</td>
                          </tr>
                          <tr>
                            <td>
                              Dependencia donde se llevó a cabo el proyecto:
                            </td>
                            <td>{reportInfo?.dependency.name}</td>
                          </tr>
                          <tr>
                            <td>Departamento o laboratorio:</td>
                            <td>{reportInfo?.project.departmentName}</td>
                          </tr>
                          {reportInfo?.students &&
                            reportInfo?.students?.length > 0 && (
                              <>
                                <tr>
                                  <td rowSpan={3}>
                                    Estudiantes que participaron en el proyecto:
                                  </td>
                                  <td className="students-row">
                                    {(reportInfo?.students[0] &&
                                      reportInfo?.students[0].name) ??
                                      '-'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="students-row">
                                    {(reportInfo?.students[1] &&
                                      reportInfo?.students[1].name) ??
                                      '-'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="students-row">
                                    {(reportInfo?.students[2] &&
                                      reportInfo?.students[2].name) ??
                                      '-'}
                                  </td>
                                </tr>
                              </>
                            )}
                          {!reportInfo?.students ||
                            (reportInfo?.students?.length === 0 && (
                              <>
                                <tr>
                                  <td rowSpan={3}>
                                    Estudiantes que participaron en el proyecto:
                                  </td>
                                  <td className="students-row">{'-'}</td>
                                </tr>
                                <tr>
                                  <td className="students-row">{'-'}</td>
                                </tr>
                                <tr>
                                  <td className="students-row">{'-'}</td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </div>

                    {/* {resCalculation > 0 && (
                  <div
                    className="w-100"
                    style={{
                      height: resCalculation,
                    }}
                  />
                )} */}

                    <Row className="firma-container" ref={refSignatures}>
                      <Col xs={6}>
                        <div className="Firma">
                          <p>__________________________________________</p>
                          <p className="text-capitalize texto-firma">
                            {`${reportInfo?.director.title} ${reportInfo?.director.name} ${reportInfo?.director.firstSurname} ${reportInfo?.director.secondSurname}`}
                          </p>
                          <p className="texto-firma">DIRECTOR (A)</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="Firma">
                          <p>__________________________________________</p>
                          <p className="text-capitalize texto-firma">
                            {`${reportInfo?.investigator.title} ${reportInfo?.user.name} ${reportInfo?.user.firstSurname} ${reportInfo?.user.secondSurname}`}
                          </p>
                          <p className="texto-firma">
                            RESPONSABLE DEL PROYECTO
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </tbody>
                  <tfoot className="footer-table-report">
                    <div
                      style={{
                        height: refFooter.current?.clientHeight || 0,
                      }}></div>
                  </tfoot>
                </table>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HelpRequestReport;

import {configureStore} from '@reduxjs/toolkit';
import authReducer from './slice/auth.slice';
import invReducer from './slice/inv.slice';
import authApi from './services/auth.api';
import invApi from './services/inv.api';
import {rtkQueryErrorLogger} from './middleware/ErrorCatchingMiddleware';
import {createStateSyncMiddleware, initMessageListener} from 'redux-state-sync';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    inv: invReducer,
    [authApi.reducerPath]: authApi.reducer,
    [invApi.reducerPath]: invApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(createStateSyncMiddleware({whitelist: ['auth']}))
      .concat(authApi.middleware)
      .concat(invApi.middleware),
});

initMessageListener(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {Button, Form, Modal} from 'react-bootstrap';
import FormCampInput from '../../../components/FormCampInput';
import {useDispatch, useSelector} from 'react-redux';
import {Objective} from '../../../types/Objective';
import {
  useCreateActivityMutation,
  useUpdateActivityMutation,
} from '../../../redux/services/inv.api';
import {
  invalidateConvocation,
  selectObjectives,
  selectProject,
  updateObjectives,
} from '../../../redux/slice/inv.slice';
import {Activity} from '../../../types/Activity';
import {toast} from 'react-toastify';
import {PickBiggestDate, PickSmallestDate} from '../../../helpers/dateHandle';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import Paths from '../../../constants/Paths';
import {useNavigate} from 'react-router-dom';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const ActivityModal = ({
  show,
  setShow,
  selectedObjective,
  selectedActivity,
}: ActivityModalProps) => {
  const dispatch = useDispatch();

  const currentProject = useSelector(selectProject);
  const isClosed = currentProject?.isClosed;

  const currentObjectives = useSelector(selectObjectives);
  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
    watch,
  } = useForm<ActivityModalInfo>({
    resolver: zodResolver(ActivityModalSchema),
    mode: 'onSubmit',
  });
  const watchDateStart = watch('dateStart');
  const watchDateEnd = watch('dateEnd');
  const navigate = useNavigate();

  const [createActivity, {isLoading: isCreateObjectiveLoading}] =
    useCreateActivityMutation();
  const [updateActivity, {isLoading: isUpdateObjectiveLoading}] =
    useUpdateActivityMutation();

  const handleCreateActivity = async (sendData: Activity) => {
    if (isClosed) return;
    const responseData = await createActivity(sendData);
    let activityData = undefined;
    if ('data' in responseData) {
      activityData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (activityData?.success) {
      if (activityData?.data) {
        // TODO: Handle Data
        const data = activityData?.data;

        let aux: Objective[];

        if (currentObjectives) {
          aux = currentObjectives.map(objective => {
            if (data.objectiveId !== objective.objectiveId) return objective;

            if (objective.activities) {
              const superAux: Activity[] = [...objective.activities, data];
              return {...objective, activities: [...superAux]};
            } else return {...objective, activities: [data]};
          });
        } else {
          aux = [];
        }

        dispatch(updateObjectives(aux));

        setShow(false);
      }
      //toast.info('Actividad creada!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          navigate(Paths.investigator.OBJECTIVES);
          handleClose();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleUpdateActivity = async (sendData: Activity) => {
    if (isClosed) return;
    const responseData = await updateActivity(sendData);
    let activityData = undefined;
    if ('data' in responseData) {
      activityData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (activityData?.success) {
      if (activityData?.data) {
        // TODO: Handle Data
        const data = activityData?.data;

        let aux: Objective[];
        if (currentObjectives) {
          aux = currentObjectives.map(objective => {
            if (objective.objectiveId !== data.objectiveId) return objective;

            const superAux: Activity[] = objective.activities?.map(activity => {
              if (activity.activityId !== data.activityId) return activity;

              return {...data};
            }) || [data];

            return {
              ...objective,
              activities: superAux,
            };
          });

          dispatch(updateObjectives(aux));
        }
        setShow(false);
      }
      //toast.info('Actividad actualizada!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          navigate(Paths.investigator.OBJECTIVES);
          handleClose();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const onSubmit: SubmitHandler<ActivityModalInfo> = data => {
    if (selectedObjective && selectedActivity)
      handleUpdateActivity({
        ...data,
        activityId: selectedActivity.activityId,
        objectiveId: selectedObjective.objectiveId,
        dateStart: new Date(data.dateStart),
        dateEnd: new Date(data.dateEnd),
      });
    else
      handleCreateActivity({
        ...data,
        objectiveId: selectedObjective?.objectiveId || 0,
        activityId: 0,
        dateStart: new Date(data.dateStart),
        dateEnd: new Date(data.dateEnd),
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      if (selectedActivity) {
        const dateStart = new Date(selectedActivity.dateStart);
        const dateEnd = new Date(selectedActivity.dateEnd);
        reset({
          ...selectedActivity,
          dateStart: dateStart.toISOString().split('T')[0],
          dateEnd: dateEnd.toISOString().split('T')[0],
        });
      }
    }
  }, [show]);

  const maxDateStart = PickSmallestDate([
    watchDateEnd,
    selectedObjective?.dateEnd,
  ]);

  const minDateEnd = PickBiggestDate([
    watchDateStart,
    selectedObjective?.dateStart,
  ]);

  return (
    <>
      {show && (
        <Modal
          show={show}
          onHide={() => {
            handleClose();
          }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedActivity ? 'Actualizar ' : 'Crear '}Actividad
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id="ActivityForm" onSubmit={handleSubmit(onSubmit)}>
              <FormCampInput
                formGroupClassName="mb-3"
                name="name"
                displayName="Nombre Actividad"
                inputProps={{
                  ...register('name'),
                  disabled: isClosed,
                }}
                errors={errors}
                touchedFields={touchedFields}
              />
              <div className="d-flex justify-content-between">
                <FormCampInput
                  name="dateStart"
                  displayName="Fecha Inicio"
                  inputProps={{
                    type: 'date',
                    ...register('dateStart'),
                    disabled: isClosed,
                  }}
                  min={
                    new Date(selectedObjective?.dateStart ?? '')
                      .toISOString()
                      .split('T')[0]
                  }
                  max={new Date(maxDateStart ?? '').toISOString().split('T')[0]}
                  errors={errors}
                  touchedFields={touchedFields}
                />

                <FormCampInput
                  name="dateEnd"
                  displayName="Fecha Final"
                  inputProps={{
                    type: 'date',
                    ...register('dateEnd'),

                    disabled: isClosed,
                  }}
                  min={new Date(minDateEnd ?? '').toISOString().split('T')[0]}
                  max={
                    new Date(selectedObjective?.dateEnd ?? '')
                      .toISOString()
                      .split('T')[0]
                  }
                  errors={errors}
                  touchedFields={touchedFields}
                />
              </div>
              <FormCampInput
                formGroupClassName="w-100"
                name="description"
                displayName="Descripcion de la actividad"
                inputProps={{
                  as: 'textarea',
                  ...register('description'),
                  disabled: isClosed,
                }}
                errors={errors}
                touchedFields={touchedFields}
              />
              {/*  */}
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              disabled={isCreateObjectiveLoading || isUpdateObjectiveLoading}
              variant="secondary"
              onClick={() => {
                handleClose();
              }}>
              Cancelar
            </Button>
            <Button
              disabled={
                isCreateObjectiveLoading ||
                isUpdateObjectiveLoading ||
                !isDirty ||
                isClosed
              }
              className="ms-auto"
              form="ActivityForm"
              variant="success"
              type="submit">
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const ActivityModalSchema = z.object({
  name: z.string().trim().min(1, {message: 'Campo Obligatorio'}).max(150, {
    message: 'Nombre no puede superar 150 caracteres',
  }),
  dateStart: z.coerce.date().or(z.string()),
  dateEnd: z.coerce.date().or(z.string()),
  description: z
    .string()
    .trim()
    .min(1, {message: 'Campo Obligatorio'})
    .max(3000, {
      message: 'Descripcion no puede ser mas de 3000 caracteres.',
    }),
});

type ActivityModalInfo = z.infer<typeof ActivityModalSchema>;

type ActivityModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedObjective?: Objective;
  selectedActivity?: Activity;
};

export default ActivityModal;

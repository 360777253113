import React from 'react';
import {Objective} from '../../../types/Objective';
import {faFilePen, faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import './Objectives.scss';
import {useSelector} from 'react-redux';
import {selectConvocation, selectProject} from '../../../redux/slice/inv.slice';
import ButtonIcon from '../../../components/ButtonIcon';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const RowObjective = ({
  objective,
  ShowUpdateObjectiveModal,
  ShowCreateActivityModal,
  ShowDeleteObjectiveModal,
}: RowObjectiveProps) => {
  const currentProject = useSelector(selectProject);
  const isClosed = currentProject?.isClosed;
  const currentConvocation = useSelector(selectConvocation);

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  return (
    <>
      <tr className="row-objective">
        <th>{objective.name}</th>
        <th>
          {objective.dateStart
            ? objective.dateStart.toString().split('T')[0]
            : 'Fecha no definida'}
        </th>
        <th>
          {objective.dateEnd
            ? objective.dateEnd.toString().split('T')[0]
            : 'Fecha no definida'}
        </th>
        <th style={{width: 'fit-content'}}>
          <div className="d-flex flex-row p-1">
            <ButtonIcon
              icon={faPlus}
              toolTip="Agregar Actividad"
              buttonProps={{
                className: 'ms-auto',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowCreateActivityModal(objective),
              }}
            />
            {/* <OverlayTrigger
              placement="right"
              delay={{show: 250, hide: 400}}
              overlay={renderTooltipPlus}>
              <Button
                className="ms-auto"
                size="sm"
                disabled={isClosed}
                onClick={() => ShowCreateActivityModal(objective)}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger> */}

            <ButtonIcon
              icon={faFilePen}
              toolTip="Modificar objetivo"
              buttonProps={{
                className: 'ms-1',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowUpdateObjectiveModal(objective),
              }}
            />

            <ButtonIcon
              icon={faTrashCan}
              toolTip="Borrar objetivo"
              buttonProps={{
                className: 'ms-1',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowDeleteObjectiveModal(objective),
              }}
            />
          </div>
        </th>
      </tr>
    </>
  );
};

type RowObjectiveProps = {
  objective: Objective;
  ShowCreateActivityModal: (objective: Objective) => void;
  ShowUpdateObjectiveModal: (objective: Objective) => void;
  ShowDeleteObjectiveModal: (objective: Objective) => void;
};

export default RowObjective;

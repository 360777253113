import React from 'react';
import {Objective} from '../../../types/Objective';
import {Activity} from '../../../types/Activity';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePen, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';
import {selectConvocation, selectProject} from '../../../redux/slice/inv.slice';
import ButtonIcon from '../../../components/ButtonIcon';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const RowActivity = ({
  objective,
  activity,
  ShowUpdateActivityModal,
  ShowDeleteActivityModal,
}: RowActivityProps) => {
  const currentProject = useSelector(selectProject);
  const isClosed = currentProject?.isClosed;

  const currentConvocation = useSelector(selectConvocation);

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  return (
    <>
      <tr className="row-activity">
        <td>{activity.name}</td>
        <td>
          {activity.dateStart
            ? activity.dateStart.toString().split('T')[0]
            : 'Fecha no definida'}
        </td>
        <td>
          {activity.dateEnd
            ? activity.dateEnd.toString().split('T')[0]
            : 'Fecha no definida'}
        </td>
        <td style={{width: 'fit-content'}}>
          <div className="d-flex flex-row p-1">
            <ButtonIcon
              icon={faFilePen}
              toolTip="Modificar actividad"
              buttonProps={{
                className: 'ms-auto',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowUpdateActivityModal(objective, activity),
              }}
            />

            <ButtonIcon
              icon={faTrashCan}
              toolTip="Borrar actividad"
              buttonProps={{
                className: 'ms-1',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowDeleteActivityModal(objective, activity),
              }}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

type RowActivityProps = {
  objective: Objective;
  activity: Activity;
  ShowUpdateActivityModal: (objective: Objective, activity: Activity) => void;
  ShowDeleteActivityModal: (objective: Objective, activity: Activity) => void;
};

export default RowActivity;

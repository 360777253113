import React from 'react';
import {
  Form,
  FormLabelProps,
  FormSelectProps,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {FieldErrors, FieldValues} from 'react-hook-form';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function FormCampSelect<Type, ErrorType extends FieldValues, TouchedType>({
  name,
  displayName,
  displayIcon,
  displayIconTooltip,
  displayIconColor,
  displayIconSize,
  displayTooltipClassName = 'tooltip',
  values,
  defaultValue,
  inputProps,
  labelProps,
  controlId,
  errors,
  touchedFields,
}: FormCampSelectProp<Type, ErrorType, TouchedType>) {
  return (
    <Form.Group className="mb-3" controlId={controlId ?? `${name as string}Id`}>
      <Form.Label {...labelProps}>
        {displayName}
        {displayIcon && displayIconTooltip ? (
          <>
            &nbsp;
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className={displayTooltipClassName}>
                  {displayIconTooltip}
                </Tooltip>
              }>
              <FontAwesomeIcon
                color={displayIconColor}
                icon={displayIcon}
                size={displayIconSize ?? 'xs'}
              />
            </OverlayTrigger>
          </>
        ) : displayIcon ? (
          <>
            &nbsp;
            <FontAwesomeIcon
              color={displayIconColor}
              icon={displayIcon}
              size={displayIconSize ?? 'xs'}
            />
          </>
        ) : (
          <></>
        )}
      </Form.Label>

      <Form.Select
        defaultValue={defaultValue}
        isValid={
          touchedFields &&
          errors &&
          touchedFields[name as unknown as keyof TouchedType] &&
          !errors[name as keyof ErrorType]
        }
        isInvalid={
          touchedFields &&
          errors &&
          touchedFields[name as unknown as keyof TouchedType] &&
          !!errors[name as keyof ErrorType]
        }
        {...inputProps}>
        {values &&
          values.map(({value, label}, i) => (
            <option key={i} value={value}>
              {label}
            </option>
          ))}
      </Form.Select>
      {errors && errors[name as keyof ErrorType] && (
        <Form.Label className="text-danger" as="small" size="sm">
          {errors[name]?.message as string}
        </Form.Label>
      )}
      {errors && !errors[name as keyof ErrorType] && <br />}
    </Form.Group>
  );
}

type FormCampSelectProp<Type, ErrorType extends FieldValues, TouchedType> = {
  controlId?: string;
  name: keyof Type;
  displayName: string;
  displayIcon?: IconDefinition;
  displayIconColor?: string;
  displayIconTooltip?: string;
  displayIconSize?: SizeProp;
  displayTooltipClassName?: string;
  values: SelectItem[];
  defaultValue?: string;
  labelProps?: FormLabelProps;
  inputProps?: FormSelectProps;
  errors?: FieldErrors<ErrorType>;
  touchedFields?: Partial<Readonly<TouchedType>>;
};

type SelectItem = {
  value: string;
  label: string;
};

export default FormCampSelect;
export type {SelectItem};

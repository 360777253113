import React, {useEffect, useRef, useState} from 'react';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import './Sidebar.scss';
import {useLocation, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile} from '@fortawesome/free-regular-svg-icons';
import useBreakpoint from '../../../helpers/useBreakpoint';
import {ReactComponent as MiPerfil} from '../../../assets/MiPerfil.svg';
import {ReactComponent as ColaboracionInstituciones} from '../../../assets/ColaboracionInstituciones.svg';
import {ReactComponent as Estudiantes} from '../../../assets/Estudiantes.svg';
import {ReactComponent as Help} from '../../../assets/help.svg';
import {ReactComponent as Etapas} from '../../../assets/Etapas.svg';
import {ReactComponent as Finalizar} from '../../../assets/Finalizar.svg';
//import {ReactComponent as Inicio} from '../../../assets/Inicio.svg'; // This is the "home" button
import {ReactComponent as ObjetivosYActividades} from '../../../assets/ObjetivosYActividades.svg';
//import {ReactComponent as Proyecto2} from '../../../assets/Proyecto2.svg';
import {ReactComponent as RegistroProyecto} from '../../../assets/RegistroProyecto.svg';
import {ReactComponent as DesgloseFinanciero} from '../../../assets/DesgloseFinanciero.svg';
import {ReactComponent as Arrow} from '../../../assets/Arrow.svg';
import {faHouse} from '@fortawesome/free-solid-svg-icons';

const pathsCollapse: string[] = [
  Paths.investigator.REGISTERPROJECT,
  Paths.investigator.FINANCIALBREAKDOWN,
  Paths.investigator.INSTITUTESCOLLABS,
  Paths.investigator.OBJECTIVES,
  Paths.investigator.PARTICIPATIONSTAGE,
  Paths.investigator.STUDENTSINFORMATION,
  Paths.investigator.FINALIZEREGISTER,
];

const InvestigatorSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const size = useBreakpoint();
  const {pathname} = location;

  const [expandedSideMenu, setExpandedSideMenu] = useState(true);
  const [expandedSubmenu, setExpandedSubmenu] = useState(true);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const guideLinkRef = useRef<HTMLAnchorElement>(null);

  const menuToggleRef = useRef<HTMLButtonElement>(null);

  const toggleLogic = (path: string) => {
    if (pathname === path) {
      navigate(Paths.investigator.HUB);
      return;
    }

    navigate(path);
  };

  useEffect(() => {
    if (pathsCollapse.find(path => path === pathname)) setExpandedSubmenu(true);
  }, []);

  useEffect(() => {
    if (expandedSideMenu && (size === 'sm' || size === 'xs'))
      setExpandedSideMenu(false);

    if (!expandedSideMenu && size !== 'sm' && size !== 'xs')
      setExpandedSideMenu(true);
  }, [size]);

  return (
    <>
      {/*usar breakpoint para expandir con tama;o, usando setExpanded*/}
      <SidebarMenu
        className="sidebar-menu side-bar-container"
        variant="dark"
        expand={!expandedSideMenu}
        expanded={expandedSideMenu}
        onToggle={() => setExpandedSideMenu(!expandedSideMenu)}>
        <SidebarMenu.Collapse className="hide-box-shadow h-100 d-flex flex-row sidebar-body">
          <div className="d-flex flex-column h-100">
            <SidebarMenu.Header className="mb-0 pb-0 ps-3">
              <SidebarMenu.Brand
                href="https://investigacion.uanl.mx/proacti/"
                target="_blank"
                className="me-3 brand-text sidebar-brand-text">
                ProACTI
              </SidebarMenu.Brand>
            </SidebarMenu.Header>
            <SidebarMenu.Header className="mt-0 pt-0 ps-3 pb-3">
              <SidebarMenu.Brand
                href="https://investigacion.uanl.mx/proacti/"
                target="_blank"
                className="h-100 d-flex flex-row align-items-center mt-0 pt-0">
                <div className="d-flex flex-row my-0 py-0">
                  <div className="vr me-3 white-vertical-bar" />
                  <SidebarMenu.Text className="side-bar-subtitle-text my-0 py-0">
                    PROGRAMA DE APOYO A LA
                    <br />
                    CIENCIA, TECNOLOGÍA E INNOVACIÓN
                  </SidebarMenu.Text>
                </div>
              </SidebarMenu.Brand>
            </SidebarMenu.Header>

            <SidebarMenu.Body>
              {/* {(size === 'sm' || size === 'xs') && (
              <SidebarMenu.Toggle className="mb-2" />
            )} */}
              <SidebarMenu.Toggle className="mb-2 d-none" ref={menuToggleRef} />
              <SidebarMenu.Nav>
                {/*  */}
                <SidebarMenu.Nav>
                  <SidebarMenu.Nav.Link
                    className="d-flex flex-row"
                    onClick={() => toggleLogic(Paths.investigator.HUB)}
                    active={pathname === Paths.investigator.HUB}>
                    <SidebarMenu.Nav.Icon>
                      <FontAwesomeIcon icon={faHouse} size="lg" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title className="menu-title">
                      Inicio
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav>
                  <SidebarMenu.Nav.Link
                    className="d-flex flex-row"
                    onClick={() => toggleLogic(Paths.investigator.PROFILE)}
                    active={pathname === Paths.investigator.PROFILE}>
                    <SidebarMenu.Nav.Icon>
                      <MiPerfil className="menu_logo" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title className="menu-title">
                      Mi Perfil
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
                {/*  */}

                {/*  */}
                <SidebarMenu.Sub
                  expanded={expandedSubmenu}
                  onToggle={() => setExpandedSubmenu(!expandedSubmenu)}>
                  <SidebarMenu.Sub.Toggle className="d-flex flex-row">
                    <SidebarMenu.Nav.Icon />
                    <SidebarMenu.Nav.Title className="menu-title">
                      Proyecto
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Sub.Toggle>

                  <SidebarMenu.Sub.Collapse>
                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.REGISTERPROJECT)
                        }
                        active={
                          pathname === Paths.investigator.REGISTERPROJECT
                        }>
                        <SidebarMenu.Nav.Icon>
                          <RegistroProyecto className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Registro de Proyecto
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.OBJECTIVES)
                        }
                        active={pathname === Paths.investigator.OBJECTIVES}>
                        <SidebarMenu.Nav.Icon>
                          <ObjetivosYActividades className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Objetivos y Actividades
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.INSTITUTESCOLLABS)
                        }
                        active={
                          pathname === Paths.investigator.INSTITUTESCOLLABS
                        }>
                        <SidebarMenu.Nav.Icon>
                          <ColaboracionInstituciones className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Colaboración de Instituciones
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.STUDENTSINFORMATION)
                        }
                        active={
                          pathname === Paths.investigator.STUDENTSINFORMATION
                        }>
                        <SidebarMenu.Nav.Icon>
                          <Estudiantes className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Información de Estudiantes
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.PARTICIPATIONSTAGE)
                        }
                        active={
                          pathname === Paths.investigator.PARTICIPATIONSTAGE
                        }>
                        <SidebarMenu.Nav.Icon>
                          <Etapas className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Etapa de Participación
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.FINANCIALBREAKDOWN)
                        }
                        active={
                          pathname === Paths.investigator.FINANCIALBREAKDOWN
                        }>
                        <SidebarMenu.Nav.Icon>
                          <DesgloseFinanciero className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Desglose Financiero
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav>
                      <SidebarMenu.Nav.Link
                        className="d-flex flex-row"
                        onClick={() =>
                          toggleLogic(Paths.investigator.FINALIZEREGISTER)
                        }
                        active={
                          pathname === Paths.investigator.FINALIZEREGISTER
                        }>
                        <SidebarMenu.Nav.Icon>
                          <Finalizar className="menu_logo" />
                        </SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title className="sub-menu-title">
                          Finalizar Registro
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>
                  </SidebarMenu.Sub.Collapse>
                </SidebarMenu.Sub>
                <SidebarMenu.Nav>
                  <SidebarMenu.Nav.Link
                    className="d-flex flex-row"
                    onClick={() => {
                      linkRef.current?.click();
                    }}>
                    <SidebarMenu.Nav.Icon>
                      <Help className="help menu_logo" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title className="menu-title">
                      Guia Registro de Proyecto
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
                <SidebarMenu.Nav>
                  <SidebarMenu.Nav.Link
                    className="d-flex flex-row"
                    onClick={() => {
                      linkRef.current?.click();
                    }}>
                    <SidebarMenu.Nav.Icon>
                      <FontAwesomeIcon icon={faFile} />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title className="menu-title">
                      Aviso de Privacidad
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                {/*  */}
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
          </div>
          <div
            className="h-100 d-flex flew-row align-items-center"
            style={{width: 'fit-content'}}>
            <div style={{width: '25px'}}>
              <Arrow
                className="menu_logo text-clickable"
                transform={`rotate(${expandedSideMenu ? '90' : '270'})`}
                onClick={() => {
                  menuToggleRef.current?.click();
                }}
              />
            </div>
          </div>
        </SidebarMenu.Collapse>
      </SidebarMenu>
      <a
        href="/ProACTI-2.pdf"
        target="_blank"
        className="d-none"
        ref={guideLinkRef}>
        Guia Registro de Proyecto
      </a>
      <a href="/ProACTI-2.pdf" target="_blank" className="d-none" ref={linkRef}>
        Aviso de Privacidad
      </a>
    </>
  );
};

export default InvestigatorSidebar;

import {IconDefinition, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Button, ButtonProps} from 'react-bootstrap';
import {BsPrefixProps} from 'react-bootstrap/esm/helpers';
import {Placement} from 'react-bootstrap/esm/types';
import TooltipWrap from './TooltipWrap';

const ButtonIcon = ({
  icon = faPlus,
  toolTip,
  toolTipPlacement = 'top',
  buttonProps,
}: ButtonIconProps) => {
  return (
    <TooltipWrap toolTip={toolTip} toolTipPlacement={toolTipPlacement}>
      <Button {...buttonProps}>
        <FontAwesomeIcon icon={icon} />
      </Button>
    </TooltipWrap>
  );
};

type ButtonIconProps = {
  icon: IconDefinition;
  toolTip?: string;
  toolTipPlacement?: Placement;
  buttonProps?: ButtonProps | BsPrefixProps<'button'>;
};

export default ButtonIcon;

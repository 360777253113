import {
  Action,
  Dispatch,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import {ServiceResponse} from '../../types/Dtos/ServiceResponse';
import {logout} from '../slice/auth.slice';
import {toast} from 'react-toastify';
import {invLogout} from '../slice/inv.slice';

export const rtkQueryErrorLogger =
  (api: MiddlewareAPI) => (next: Dispatch<Action>) => (action: Action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      const payload = action.payload as {
        data: ServiceResponse<unknown>;
        status: number;
      };

      if (!payload.data.success) {
        if (payload.status == 401) {
          console.log('Failed to Authenticate');
          api.dispatch(logout());
          api.dispatch(invLogout());
          toast.error(
            payload.data?.message ??
              'Ha ocurrido un problema, favor de intentarlo de nuevo.',
            {},
          );
          window.location.href = '/';
        } else {
          console.log(payload.data.message);
        }
      }
    }

    return next(action);
  };

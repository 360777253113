import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import Login from './pages/login/Login';
import NavigationBar from './components/NavigationBars/NavigationBar/NavigationBar';
import InvestigatorHub from './pages/Investigator/InvestigatorHub';
import AdminHub from './pages/Admin/adminHub/AdminHub';

// All this is now imported in index.scss
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/scss/bootstrap.scss';
//import 'react-bootstrap-sidebar-menu/dist/sidebar-menu.scss';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import Signup from './pages/signup/Signup';
import Landing from './pages/landing/Landing';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import ProtectedRoutes from './components/ProtectedRoutes';
import {Role} from './constants/Role';
import BottomBar from './components/NavigationBars/BottomBar/BottomBar';
import InvestigationBar from './components/NavigationBars/NavigationBar/InvestigationBar';
import AdminBar from './components/NavigationBars/NavigationBar/AdminBar';
import {Flip, ToastContainer} from 'react-toastify';
import Paths from './constants/Paths';
import AdminSidebar from './components/NavigationBars/Sidebar/AdminSidebar';
import InvestigatorProfile from './pages/Investigator/investigatorProfile/InvestigatorProfile';
import RegisterProject from './pages/Investigator/registerProject/RegisterProject';
import InvestigatorSidebar from './components/NavigationBars/Sidebar/InvestigatorSidebar';
import FinancialBreakdown from './pages/Investigator/financialBreakdown/FinancialBreakdown';
import ConfirmRegister from './pages/Investigator/confirmRegister/ConfirmRegister';
import Objectives from './pages/Investigator/objectives/Objectives';
import StudentsInformation from './pages/Investigator/studentsInformation/StudentsInformation';
import InstitutesCollabs from './pages/Investigator/institutesCollabs/InstitutesCollabs';
import ParticipationStageScreen from './pages/Investigator/participationStage/ParticipationStage';
import ProjectList from './pages/Admin/projectList/ProjectList';
import FinancialBreakdownReport from './pages/Reports/FinancialBreakdownReport/FinancialBreakdown';
import ObjectivesReport from './pages/Reports/Objectives/Objectives';
import HelpRequestReport from './pages/Reports/HelpRequest/HelpRequest';

const env = process.env;

const routerOpts = env.REACT_APP_SUBDOMAIN
  ? {
      basename: env.REACT_APP_SUBDOMAIN,
    }
  : {};

const router = createBrowserRouter(
  [
    {
      element: <ProtectedRoutes authRole={undefined} />, // authRole undefined = only allow people that don't have an account
      children: [
        {
          path: Paths.LANDING,
          element: (
            <div className="main-wrapper">
              <NavigationBar />
              <Landing />
              <BottomBar />
            </div>
          ),
        },
        {
          path: Paths.LOGIN,
          element: (
            <div className="main-wrapper">
              <NavigationBar />
              <Login />
              <BottomBar />
            </div>
          ),
        },
        {
          path: Paths.admin.LOGIN,
          element: (
            <div className="main-wrapper">
              <NavigationBar />
              <Login /> {/* TODO: Change this with the admin login */}
              <BottomBar />
            </div>
          ),
        },
        {
          path: Paths.SIGNUP,
          element: (
            <div className="main-wrapper">
              <NavigationBar />
              <Signup />
              <BottomBar hub />
            </div>
          ),
        },
      ],
      errorElement: (
        <>
          {/* TODO: This might not work with the proxy stuff for the reports, investigate */}
          <Navigate to="/" replace />
        </>
      ),
    },

    {
      element: (
        <ProtectedRoutes
          authRole={Role.Investigator}
          // This should be de-coupled, the logic for all of this should be inside "Protected Routes"
          sideBar={<InvestigatorSidebar />}
          navBar={<InvestigationBar />}
          bottomBar={<BottomBar hub />}
          container
          wrapper
        />
      ),
      children: [
        {
          path: Paths.investigator.HUB,
          element: <InvestigatorHub />,
        },
        {
          path: Paths.investigator.PROFILE,
          element: <InvestigatorProfile />,
        },
        {
          path: Paths.investigator.REGISTERPROJECT,
          element: <RegisterProject />,
        },
        {
          path: Paths.investigator.FINANCIALBREAKDOWN,
          element: <FinancialBreakdown />,
        },
        {
          path: Paths.investigator.FINALIZEREGISTER,
          element: <ConfirmRegister />,
        },
        {
          path: Paths.investigator.OBJECTIVES,
          element: <Objectives />,
        },
        {
          path: Paths.investigator.STUDENTSINFORMATION,
          element: <StudentsInformation />,
        },
        {
          path: Paths.investigator.INSTITUTESCOLLABS,
          element: <InstitutesCollabs />,
        },
        {
          path: Paths.investigator.PARTICIPATIONSTAGE,
          element: <ParticipationStageScreen />,
        },
      ],
    },

    {
      element: (
        <ProtectedRoutes
          authRole={Role.Administrator}
          // This should be de-coupled, the logic for all of this should be inside "Protected Routes"
          sideBar={<AdminSidebar />}
          navBar={<AdminBar />}
          bottomBar={<BottomBar hub />}
          container
          wrapper
        />
      ),
      children: [
        {
          path: Paths.admin.HUB,
          element: <AdminHub />,
        },

        {
          path: Paths.admin.PROJECTLIST,
          element: <ProjectList />,
        },
      ],
    },

    {
      element: (
        <>
          <Outlet />
        </>
      ), // authRole undefined = only allow people that don't have an account
      children: [
        {
          path: Paths.report.FINANCIALBREAKDOWN,
          element: <FinancialBreakdownReport />,
        },
        {
          path: Paths.report.OBJECTIVES,
          element: <ObjectivesReport />,
        },
        {
          path: Paths.report.HELPREQUEST,
          element: <HelpRequestReport />,
        },
      ],
    },
  ],
  {...routerOpts},
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}
      />
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();

import React, {useEffect, useState} from 'react';
import './StudentsInformation.css';
import {Button, Form, Modal, Row, Spinner, Table} from 'react-bootstrap';
import FormCampInput from '../../../components/FormCampInput';
import {useDispatch, useSelector} from 'react-redux';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {
  useCreateStudentMutation,
  useDeleteStudentMutation,
  useGetUserCurrentStudentsMutation,
  useGetUserCurrentProjectMutation,
  useUpdateStudentMutation,
  useGetAcademicGradesMutation,
  useUpdateProjectHasStudentsMutation,
  useGetCurrentConvocationMutation,
} from '../../../redux/services/inv.api';
import {Student} from '../../../types/Student';
import RowStudent from './RowStudent';
import {AcademicGrade} from '../../../types/AcademicGrade';
import FormCampSelect, {SelectItem} from '../../../components/FormCampSelect';
import {toast} from 'react-toastify';
import {useBlocker, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import {regex} from '../../../constants/Regexs';
import {OutHasStudentsDto} from '../../../types/Dtos/InvDtos';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import {RequieredForFinalizing} from '../../../constants/RequieredForFinalizing';

const StudentsInformation = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectProject);
  const isClosed = currentProject?.isClosed;
  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
    setValue,
  } = useForm<StudentInfo>({
    resolver: zodResolver(studentSchema),
    mode: 'onSubmit',
    defaultValues: {},
  });

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [loading, setLoading] = useState(true);
  const [hasStudents, setHasStudents] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(
    undefined,
  );
  const [selectedStudentTemp, setSelectedStudentTemp] = useState<
    Student | undefined
  >(undefined);
  const [students, setStudents] = useState<Student[] | undefined>(undefined);
  const [academicGrades, setAcademicGrades] = useState<
    AcademicGrade[] | undefined
  >(undefined);
  const [academicGradesValues, setAcademicGradesValues] = useState<
    SelectItem[] | undefined
  >(undefined);
  const [showUpdateStudent, setShowUpdateStudent] = useState(false);
  const [showStudentDelete, setShowStudentDelete] = useState(false);

  const [
    updateProjectHasStudents,
    {isLoading: isUpdateProjectHasStudentsLoading},
  ] = useUpdateProjectHasStudentsMutation();

  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();

  const [getUserCurrentStudents, {isLoading: isGetUserCurrentStudentsLoading}] =
    useGetUserCurrentStudentsMutation();

  const [getAcademicGrades, {isLoading: isGetAcademicGradesLoading}] =
    useGetAcademicGradesMutation();

  const [registerStudent, {isLoading: isRegisterStudentLoading}] =
    useCreateStudentMutation();

  const [sendUpdateStudent, {isLoading: isSendUpdateStudentLoading}] =
    useUpdateStudentMutation();

  const [deleteStudent, {isLoading: isDeleteStudentLoading}] =
    useDeleteStudentMutation();

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      reset();
      setHasStudents(currentProject.hasStudents || false);
      await handleGetAcademicGrades();
      await handleGetStudents();
      setLoading(false);
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));
        reset();
        setHasStudents(data.hasStudents || false);
        await handleGetAcademicGrades();
        await handleGetStudents();
        setLoading(false);
        return;
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al intentar conseguir el proyecto.',
      );
    }
    //TODO: Warn user to register a project first to precede.
    toast.error(
      'Es necesario registrar el Nombre del Proyecto para continuar.',
    );
    navigate(Paths.investigator.REGISTERPROJECT);
    return;
  };

  const onSubmit: SubmitHandler<StudentInfo> = data => {
    if (selectedStudent) {
      handleUpdateStudentSubmit(data);
    } else handleRegisterStudentSubmit(data);
  };

  useEffect(() => {
    checkConvocation();
    checkProject();
  }, []);

  const handleUpdateProjectHasStudents = async (
    sendData: OutHasStudentsDto,
  ) => {
    if (isClosed) return;
    if (currentProject == undefined || currentProject.projectId == undefined)
      return;

    const responseData = await updateProjectHasStudents({
      ...sendData,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        //TODO: Success petition
        //dispatch(updateProject(data));
        dispatch(updateProject(data));

        if (!data.hasStudents) {
          await handleGetStudents();
        }
      }
      //reset({}, {keepValues: true});
      //toast.info('Estado de estudiantes actualizado!');
      toast.success('Su información se guardo correctamente');
    } else {
      toast.error(
        resultData?.message ?? 'Error al actualizar estado de estudiantes.',
      );
      setHasStudents(!sendData.hasStudents);
    }
  };

  const handleGetStudents = async () => {
    const responseData = await getUserCurrentStudents(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        setStudents(data);
        return;
      }
    }
  };

  const handleGetAcademicGrades = async () => {
    const responseData = await getAcademicGrades(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        setAcademicGrades(data);

        const aux: SelectItem[] = data.map(({academicGradeId, name}) => {
          const temp: SelectItem = {
            value: academicGradeId.toString(),
            label: name,
          };
          return temp;
        });

        setAcademicGradesValues(aux);
        return;
      }
    }
  };

  const handleRegisterStudentSubmit = async (sendData: StudentInfo) => {
    if (isClosed) return;
    if (currentProject == undefined || currentProject.projectId == undefined)
      return;

    const responseData = await registerStudent({
      ...sendData,
      projectId: currentProject?.projectId ?? 0,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        if (students) setStudents([...students, data]);
        else setStudents([data]);

        reset();
        //toast.info('Estudiante registrado!');
        toast.success('Su información se guardo correctamente');
      }
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleUpdateStudentSubmit = async (sendData: StudentInfo) => {
    if (isClosed) return;
    if (
      !currentProject ||
      !currentProject.projectId ||
      !selectedStudent ||
      !selectedStudent.studentId
    )
      return;

    const responseData = await sendUpdateStudent({
      ...sendData,
      studentId: selectedStudent.studentId,
      projectId: currentProject.projectId,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        //TODO: Success petition
        // Update the students array
        let aux: Student[];
        if (students) {
          aux = students?.map(stu => {
            if (stu.studentId !== data.studentId) {
              return stu;
            }
            return data;
          });
          setStudents(aux);
        }
        //Finish this

        //setSelectedStudent(data);
        reset(undefined);
        setSelectedStudent(undefined);
        //toast.info('Estudiante actualizado!');
        toast.success('Su información se guardo correctamente');
      }
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const ShowDeleteStudentModal = (student: Student) => {
    setSelectedStudentTemp(student);
    setShowStudentDelete(true);
  };

  const ShowUpdateStudentModal = (student: Student) => {
    if (isDirty) {
      setSelectedStudentTemp(student);
      setShowUpdateStudent(true);
    } else {
      handleShowUpdateStudentConfirm(student);
    }
  };

  const handleShowUpdateStudentConfirm = (student: Student) => {
    setSelectedStudent(student);
    //reset({...student});
    setValue('studentId', student.studentId);
    setValue('matricula', student.matricula);
    setValue('name', student.name);
    setValue('degree', student.degree);
    setValue('academicGradeId', student.academicGradeId);
    reset({}, {keepValues: true});
    setShowUpdateStudent(false);
  };

  const handleDeleteStudent = async (sendData: Student) => {
    if (isClosed) return;
    const responseData = await deleteStudent(sendData);
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    let studentData = undefined;
    if ('data' in responseData) {
      studentData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (studentData?.success) {
      // TODO: Handle Data
      let functionStudents: Student[];

      if (students) {
        functionStudents = students.filter(
          stu => stu.studentId !== sendData.studentId,
        );
        setStudents(functionStudents);
      }

      //toast.info('Estudiante borrado!');
      toast.success('Su información se guardo correctamente');

      setSelectedStudent(undefined);
      setShowStudentDelete(false);
      reset();
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <div className="d-flex flex-column h-100 w-100 p-2">
            <Row xs={12} className="px-5">
              <p className="text-center mb-3 login-text">
                Información de Estudiantes
              </p>
            </Row>
            <Row xs={12} className="px-5">
              <Form>
                <div className="d-flex flex-row mb-3">
                  <label htmlFor="groupCollaboration">
                    ¿Tendrá participación de estudiantes en el desarrollo del
                    proyecto?
                  </label>
                  &nbsp;
                  <Form.Check
                    type="radio"
                    name="groupCollaboration"
                    label="No"
                    value={0}
                    onClick={() => {
                      if (hasStudents) {
                        setHasStudents(false);
                        handleUpdateProjectHasStudents({
                          projectId: currentProject?.projectId || 0,
                          hasStudents: false,
                        });
                      }
                    }}
                    checked={!hasStudents} /* {...register('')} */
                    disabled={isClosed || !isRegistrationActive}
                  />
                  &nbsp;&nbsp;
                  <Form.Check
                    type="radio"
                    name="groupCollaboration"
                    label="Si"
                    value={1}
                    onClick={() => {
                      if (!hasStudents) {
                        setHasStudents(true);
                        handleUpdateProjectHasStudents({
                          projectId: currentProject?.projectId || 0,
                          hasStudents: true,
                        });
                      }
                    }}
                    checked={hasStudents}
                    disabled={isClosed || !isRegistrationActive}
                  />
                </div>
              </Form>
            </Row>
            <Row xs={12} className="px-5" hidden={!hasStudents}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormCampInput
                  formGroupClassName="w-100"
                  name="name"
                  displayName="Nombre completo"
                  {...RequieredForFinalizing}
                  inputProps={{
                    ...register('name'),
                    disabled:
                      isClosed ||
                      !isRegistrationActive ||
                      (students && students?.length >= 3 && !selectedStudent),
                  }}
                  errors={errors}
                  // touchedFields={touchedFields}
                />
                <FormCampInput
                  formGroupClassName="w-100"
                  name="matricula"
                  displayName="Matrícula"
                  {...RequieredForFinalizing}
                  inputProps={{
                    type: 'number',
                    ...register('matricula'),
                    disabled:
                      isClosed ||
                      !isRegistrationActive ||
                      (students && students?.length >= 3 && !selectedStudent),
                  }}
                  errors={errors}
                  // touchedFields={touchedFields}
                />
                <FormCampInput
                  formGroupClassName="w-100"
                  name="degree"
                  displayName="Carrera"
                  {...RequieredForFinalizing}
                  inputProps={{
                    ...register('degree'),
                    disabled:
                      isClosed ||
                      !isRegistrationActive ||
                      (students && students?.length >= 3 && !selectedStudent),
                  }}
                  errors={errors}
                  // touchedFields={touchedFields}
                />
                <FormCampSelect
                  name="academicGradeId"
                  displayName="Nivel Académico"
                  {...RequieredForFinalizing}
                  inputProps={{
                    ...register('academicGradeId'),
                    disabled:
                      isClosed ||
                      !isRegistrationActive ||
                      (students && students?.length >= 3 && !selectedStudent),
                  }}
                  values={academicGradesValues || []}
                  errors={errors}
                />

                <div className="d-flex flex-row mb-1">
                  <Button
                    disabled={isClosed || !isRegistrationActive}
                    variant="danger"
                    type="reset"
                    onClick={() => {
                      setSelectedStudent(undefined);
                      reset();
                    }}>
                    Cancelar
                  </Button>
                  <Button
                    className="ms-auto"
                    type="submit"
                    disabled={
                      isRegisterStudentLoading ||
                      isSendUpdateStudentLoading ||
                      !isDirty ||
                      isClosed ||
                      !isRegistrationActive ||
                      (students && students?.length >= 3 && !selectedStudent)
                    }>
                    {selectedStudent ? 'Actualizar' : 'Agregar'}
                  </Button>
                </div>
              </Form>

              <Table striped bordered hover className="mt-5">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Matrícula</th>
                    <th>Nivel Académico</th>
                    <th>Carrera</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {students &&
                    academicGrades &&
                    academicGrades.length > 0 &&
                    students.map(student => {
                      return (
                        <RowStudent
                          student={student}
                          academicGrades={academicGrades}
                          ShowUpdateStudentModal={ShowUpdateStudentModal}
                          ShowDeleteStudentModal={ShowDeleteStudentModal}
                        />
                      );
                    })}
                </tbody>
              </Table>
            </Row>
            <div className="text-center pb-3">
              <Button
                className="ms-3"
                onClick={() => {
                  navigate(Paths.investigator.PARTICIPATIONSTAGE);
                }}>
                Siguiente
              </Button>
            </div>
          </div>
        )}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>

      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estas seguro que deseas salir y perder los cambios que han hechos?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {/*Modal de borrar estudiante*/}
      <Modal
        show={showStudentDelete}
        onHide={() => setShowStudentDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>¿Eliminar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estas seguro que deseas eliminar a este estudante?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowStudentDelete(false)}>
            Cerrar
          </Button>
          <Button
            disabled={isDeleteStudentLoading}
            variant="danger"
            onClick={() => {
              if (selectedStudentTemp) handleDeleteStudent(selectedStudentTemp);
            }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/*Modal de actualiar estudiante si aun la forma aun tiene cambios no guardados*/}
      <Modal
        show={showUpdateStudent}
        onHide={() => setShowUpdateStudent(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cambios no guardados</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Estas seguro que deseas descartar los cambios?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowUpdateStudent(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (selectedStudentTemp)
                handleShowUpdateStudentConfirm(selectedStudentTemp);
              else {
                toast.error('No existo', {});
              }
            }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const studentSchema = z.object({
  studentId: z.number().optional(),
  name: z
    .string()
    .trim()
    .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
      message: 'Caracteres no validos',
    })
    .min(1, {message: 'Campo Obligatorio'})
    .max(300, {
      message: 'El límite del titulo es 500 carácteres',
    }),
  matricula: z
    .string()
    .toUpperCase()
    .trim()
    .min(1, {message: 'Campo Obligatorio'})
    .max(9, {message: 'Matricula no puede tener más de 9 carácteres'})
    .refine(value => regex.onlyNumbers.test(value), 'Solo se aceptan numeros'),
  degree: z
    .string()
    .trim()
    .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
      message: 'Caracteres no validos',
    })
    .min(1, {message: 'Campo Obligatorio'})
    .max(300, {
      message: 'El límite del titulo es 500 carácteres',
    }),
  academicGradeId: z.coerce.number().default(1),
  projectId: z.number().optional(),
});

type StudentInfo = z.infer<typeof studentSchema>;

export default StudentsInformation;

export const PickSmallestDate = (dates: PossibleDate[]) => {
  let result: PossibleDate = undefined;

  dates.map(date => {
    if (!result) {
      result = date;
      return;
    }
    if (!date) return;

    result = new Date(result) > new Date(date) ? date : result;
  });

  return result;
};

export const PickBiggestDate = (dates: PossibleDate[]) => {
  let result: PossibleDate = undefined;

  dates.map(date => {
    if (!result) {
      result = date;
      return;
    }
    if (!date) return;

    result = new Date(result) < new Date(date) ? date : result;
  });

  return result;
};

type PossibleDate = Date | string | undefined;

import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import './RegisterProject.css';
import Panel from '../../../components/Panel';
import FormCampInput from '../../../components/FormCampInput';
import FormCampSelect, {SelectItem} from '../../../components/FormCampSelect';
import FormCampFile from '../../../components/FormCampFile';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {
  useGetCurrentConvocationMutation,
  useGetInvestigationAreasMutation,
  useGetUserCurrentProjectMutation,
  useRegisterProjectMutation,
  useUpdateProjectMutation,
} from '../../../redux/services/inv.api';
import {useDispatch, useSelector} from 'react-redux';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {toast} from 'react-toastify';
import {
  InRegisterProjectDto,
  InUpdateProjectDto,
} from '../../../types/Dtos/InvDtos';
import {useBlocker, useNavigate} from 'react-router-dom';
import {validateFile} from '../../../helpers/ValidateFile';
import Paths from '../../../constants/Paths';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {regex} from '../../../constants/Regexs';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import {RequieredForFinalizing} from '../../../constants/RequieredForFinalizing';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const RegisterProject = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectProject);
  const isClosed = currentProject?.isClosed;
  const currentConvocation = useSelector(selectConvocation);

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
    watch,
    setValue,
  } = useForm<RegisterProjectInfo>({
    resolver: zodResolver(registerProjectSchema),
    mode: 'onSubmit',
  });
  const watchInputFileList = watch('inputFileList');
  const watchDocumentFile = watch('documentFile');
  const watchDocumentName = watch('documentName');

  const [investigationAreaValues, setInvestigationAreaValues] = useState<
    SelectItem[]
  >([]);

  const [registerProject, {isLoading: isRegisterProjectLoading}] =
    useRegisterProjectMutation();
  const [getInvestigationAreas, {isLoading: isGetInvestigationAreasLoading}] =
    useGetInvestigationAreasMutation();
  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();
  const [sendUpdateProject, {isLoading: isSendUpdateProjectLoading}] =
    useUpdateProjectMutation();

  const onSubmit: SubmitHandler<RegisterProjectInfo> = data => {
    if (currentProject) {
      handleUpdateProjectSubmit({
        ...data,
        projectId: currentProject.projectId,
        investigationAreaId:
          data.investigationAreaId != '-1'
            ? parseInt(data.investigationAreaId)
            : undefined,
      });
    } else
      handleRegisterProjectSubmit({
        ...data,
        investigationAreaId:
          data.investigationAreaId != '-1'
            ? parseInt(data.investigationAreaId)
            : undefined,
      });
  };

  const handleRegisterProjectSubmit = async (
    sendData: InRegisterProjectDto,
  ) => {
    const responseData = await registerProject(sendData);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));
        reset({...data, investigationAreaId: `${data.investigationAreaId}`});
      }
      reset({}, {keepValues: true});
      //toast.info('Proyecto registrado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleUpdateProjectSubmit = async (sendData: InUpdateProjectDto) => {
    if (currentProject == undefined || currentProject.projectId == undefined)
      return;

    const responseData = await sendUpdateProject({
      ...sendData,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));
        reset({
          ...data,
          investigationAreaId: `${data.investigationAreaId}`,
          documentFile: data.documentFile,
        });
      }
      reset({}, {keepValues: true});
      //toast.info('Proyecto actualizado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const cancelProgress = () => {
    // TODO: show a modal asking if they are sure
    // If they are, do the following
    if (currentProject) {
      reset({
        ...currentProject,
        investigationAreaId: `${currentProject.investigationAreaId}`,
      });
    } else {
      reset();
    }
  };

  const getInvestigationAreaAsync = async () => {
    const responseData = await getInvestigationAreas(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        const temporal: SelectItem = {
          value: '-1',
          label: 'Selecciona...',
        };
        const aux: SelectItem[] = data.map(({investigationAreaId, name}) => {
          const temp: SelectItem = {
            value: investigationAreaId.toString(),
            label: name,
          };
          return temp;
        });

        setInvestigationAreaValues([temporal, ...aux]);
      }
    } else {
      toast.error(
        resultData?.message ??
          'Error al intentar conseguir areas de investigacion.',
      );
    }
  };

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      reset({
        ...currentProject,
        investigationAreaId: `${currentProject.investigationAreaId}`,
      });
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));
        reset({...data, investigationAreaId: `${data.investigationAreaId}`});
        return;
      }
    }

    reset();
    return;
  };

  useEffect(() => {
    if (watchInputFileList && watchInputFileList[0] !== undefined) {
      try {
        const file: File = watchInputFileList[0];

        const handlValidateFile = async (file: File) => {
          const result = await validateFile({file});

          if (result.error || !result.textFile || result.textFile === '') {
            toast.warning(result.error ?? 'Error desconocido con el pdf.');
            setValue('documentFile', undefined);
            setValue('inputFileList', undefined);
            return;
          }

          //toast.success('Archivo valido!');
          setValue('documentName', result.fileName);
          setValue('documentFile', result.textFile);
        };

        handlValidateFile(file);
      } catch (e) {
        const ex = (e as Error) ?? undefined;

        if (ex) {
          toast.error(
            'Ha ocurrido un problema, favor de intentarlo de nuevo.',
            {},
          );
        }

        console.log(ex);
      }
    }
  }, [watchInputFileList]);

  useEffect(() => {
    checkConvocation();
    const effect = async () => {
      await getInvestigationAreaAsync();
      checkProject();
    };

    effect();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!isGetInvestigationAreasLoading && !isGetUserCurrentProjectLoading && (
          <div className="d-flex flex-column h-100 w-100 p-2">
            <Row xs={12} className="px-5">
              <p className="text-center mb-3 login-text">
                Registro de Proyecto
              </p>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormCampInput
                name="title"
                {...RequieredForFinalizing}
                displayName="Nombre de Proyecto"
                max="3000"
                displayCount
                inputProps={{
                  as: 'textarea',
                  style: {resize: 'none', height: '4em'},

                  ...register('title'),
                  disabled: isClosed || !isRegistrationActive,
                }}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampInput
                name="projectKey"
                displayName="Clave de Proyecto"
                inputProps={{
                  ...register('projectKey'),
                  disabled: true,
                }}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampSelect
                name="investigationAreaId"
                {...RequieredForFinalizing}
                displayName="Área de conocimiento"
                inputProps={{
                  ...register('investigationAreaId'),
                  disabled: isClosed || !isRegistrationActive,
                }}
                values={investigationAreaValues}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampInput
                name="descriptionGeneralProblem"
                {...RequieredForFinalizing}
                displayName="Descripción general del problema"
                max="3000"
                displayCount
                inputProps={{
                  as: 'textarea',
                  style: {resize: 'none', height: '7em'},
                  ...register('descriptionGeneralProblem'),
                  disabled: isClosed || !isRegistrationActive,
                }}
                toggleFloat={false}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampInput
                name="descriptionSocietyProblem"
                {...RequieredForFinalizing}
                displayName="Descripción del problema establecido por el sector de la sociedad"
                max="3000"
                displayCount
                inputProps={{
                  as: 'textarea',
                  style: {resize: 'none', height: '7em'},
                  ...register('descriptionSocietyProblem'),
                  disabled: isClosed || !isRegistrationActive,
                }}
                toggleFloat={false}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampInput
                name="descriptionProposal"
                {...RequieredForFinalizing}
                displayName="Propuesta de solución/modelo de negocio/modelo de innovación social"
                max="3000"
                displayCount
                inputProps={{
                  as: 'textarea',
                  style: {resize: 'none', height: '7em'},
                  ...register('descriptionProposal'),
                  disabled: isClosed || !isRegistrationActive,
                }}
                toggleFloat={false}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampInput
                name="departmentName"
                {...RequieredForFinalizing}
                displayName="Nombre del laboratorio/departamento"
                inputProps={{
                  ...register('departmentName'),
                  disabled: isClosed || !isRegistrationActive,
                }}
                errors={errors}
                // touchedFields={touchedFields}
              />

              <FormCampFile
                file={{
                  documentFile: watchDocumentFile,
                  documentName: watchDocumentName,
                }}
                {...RequieredForFinalizing}
                displayName="Subir propuesta documento PDF"
                size="sm"
                name={'inputFileList'}
                inputProps={{
                  ...register('inputFileList'),
                  disabled: isClosed || !isRegistrationActive,
                }}
              />

              {/* Botones */}
              <div className="d-flex flex-row pb-3">
                <Button
                  className="ms-auto"
                  variant="danger"
                  disabled={
                    // TODO: check if this works correctly
                    !isDirty ||
                    isSendUpdateProjectLoading ||
                    isRegisterProjectLoading
                  }
                  onClick={() => cancelProgress()}>
                  Cancelar
                </Button>
                <Button
                  variant="success"
                  disabled={
                    isSendUpdateProjectLoading ||
                    isRegisterProjectLoading ||
                    !isDirty
                  }
                  //type="submit"
                  className="ms-3"
                  type="submit">
                  {'Guardar'}
                </Button>
              </div>
              {/*  */}
            </Form>
            <div className="text-center pb-3">
              <Button
                className="ms-3"
                onClick={() => {
                  navigate(Paths.investigator.OBJECTIVES);
                }}>
                Siguiente
              </Button>
            </div>
          </div>
        )}
      </div>
      {/* <LoadingSpinner show /> */}
      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estás seguro de que deseas salir y perder los cambios hechos?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

const registerProjectSchema = z.object({
  title: z
    .string()
    .trim()
    // .regex(regex.AlphaNumericValuesAndSpaceAndSpecial, {
    //   message: 'Caracteres no validos',
    // })
    .min(1, {message: 'Campo Obligatorio'})
    .max(3000, {
      message: 'El límite del titulo es 3000 carácteres',
    }),
  projectKey: z.string().optional(),
  descriptionGeneralProblem: z
    .string()
    .trim()
    .max(3000, {
      message: 'El límite del titulo es 3000 carácteres',
    })
    .optional(),
  descriptionSocietyProblem: z
    .string()
    .trim()
    .max(3000, {
      message: 'El límite del titulo es 3000 carácteres',
    })
    .optional(),
  descriptionProposal: z
    .string()
    .trim()
    .max(3000, {
      message: 'El límite del titulo es 3000 carácteres',
    })
    .optional(),
  departmentName: z
    .string()
    .trim()
    .max(3000, {
      message: 'El límite del titulo es 3000 carácteres',
    })
    .optional(),
  documentFile: z.string().max(31457280).optional(),
  documentName: z.string().max(500).optional(),
  inputFileList: z.any().optional(),
  investigationAreaId: z
    .string()
    .regex(regex.onlyNumbers, {message: 'Obligatorio'}),
});

type RegisterProjectInfo = z.infer<typeof registerProjectSchema>;

export default RegisterProject;

import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import './../Report.scss';
import './Objectives.scss';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGetObjectivesReportInformationMutation} from '../../../redux/services/inv.api';
import {OutObjectivesReportDto} from '../../../types/Dtos/InvDtos';
import Paths from '../../../constants/Paths';

const HelpRequestReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');

  const refHeader = useRef<HTMLElement>(null);
  const refFooter = useRef<HTMLElement>(null);
  const refPDF = useRef<HTMLDivElement>(null);
  const refSignatures = useRef<HTMLDivElement>(null);

  const [
    getObjectivesReportInformation,
    {isLoading: isGetObjectivesReportInformationLoading},
  ] = useGetObjectivesReportInformationMutation();

  const [reportInfo, setObjectivesReportInfo] = useState<
    OutObjectivesReportDto | undefined
  >(undefined);

  const [headerSize, setHeaderSize] = useState<number>(0);
  const [footerSize, setFooterSize] = useState<number>(0);

  const getHelpRequestAsync = async () => {
    if (!projectId) return false;
    const responseData = await getObjectivesReportInformation(
      Number.parseInt(projectId),
    );

    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data; //TODO: Es esto correcto?

        setObjectivesReportInfo({
          ...data,
          date: new Date(data.date),
          director: {
            ...data.director,
            name: data.director.name.toLowerCase(),
            firstSurname: data.director.firstSurname.toLowerCase(),
            secondSurname: data.director.secondSurname.toLowerCase(),
          },
          user: {
            ...data.user,
            name: data.user.name.toLowerCase(),
            firstSurname: data.user.firstSurname.toLowerCase(),
            secondSurname: data.user.secondSurname.toLowerCase(),
          },
        });
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const load = async () => {
      if (!(await getHelpRequestAsync())) {
        navigate(Paths.LOGIN);
      }
    };
    load();
  }, []);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);

  useEffect(() => {
    //fetchData();
    console.log(headerSize);
    forceUpdate();
  }, [headerSize]);

  useEffect(() => {
    //fetchData();
    console.log(footerSize);
    forceUpdate();
  }, [footerSize]);

  return (
    <>
      <div className="pdf d-flex flex-column" ref={refPDF}>
        {!isGetObjectivesReportInformationLoading && reportInfo && (
          <div
            className="px-4 d-flex flex-column"
            style={{
              //padding: 0,
              margin: 0,
              paddingBottom: refFooter.current?.clientHeight || 0,
            }}>
            <table className="tablePDF">
              <thead>
                <div
                  style={{
                    padding: 0,
                    margin: 0,
                    height: refHeader.current?.clientHeight || 0,
                  }}>
                  &nbsp;
                </div>
              </thead>
              <tbody>
                <div className="w-100">
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="objective-table report-table-container">
                    <thead className="report-head">
                      <tr>
                        <th colSpan={4}>OBJETIVO GENERAL</th>
                      </tr>
                    </thead>
                    <tbody className="report-table">
                      <tr>
                        <td colSpan={4} className="text-center">
                          {reportInfo?.project.generalObjective}
                        </td>
                      </tr>
                      <tr className="report-head">
                        <th colSpan={2}>OBJETIVOS</th>
                        <th colSpan={2}>ACTIVIDADES</th>
                      </tr>
                      {reportInfo?.objectives &&
                        reportInfo.objectives.map(objective => {
                          if (
                            objective.activities &&
                            objective.activities.length > 0
                          ) {
                            return objective.activities.map(
                              (activity, index) => {
                                console.log(`activities index:${index}`);
                                if (index === 0) {
                                  return (
                                    <>
                                      <tr className="objectiveRow">
                                        <td
                                          className="align-content-center"
                                          rowSpan={
                                            objective.activities?.length
                                          }>
                                          {objective.name}
                                        </td>
                                        <td
                                          rowSpan={
                                            objective.activities?.length
                                          }>
                                          <div className="objectivesDateCell d-flex flex-column align-items-center justify-content-around">
                                            <p className="my-0">
                                              Inicio: &nbsp;
                                              {new Date(
                                                objective.dateStart,
                                              ).toLocaleDateString('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </p>
                                            <p className="my-0">
                                              Fin: &nbsp;
                                              {new Date(
                                                objective.dateEnd,
                                              ).toLocaleDateString('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </p>
                                          </div>
                                        </td>
                                        <td className="align-content-center">
                                          {activity.name}
                                        </td>
                                        <td>
                                          <div className="d-flex flex-column align-items-center justify-content-around">
                                            <p className="my-0">
                                              Inicio: &nbsp;
                                              {new Date(
                                                activity.dateStart,
                                              ).toLocaleDateString('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </p>
                                            <div className="p-1" />
                                            <p className="my-0">
                                              Fin: &nbsp;
                                              {new Date(
                                                activity.dateEnd,
                                              ).toLocaleDateString('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <tr>
                                        <td className="align-content-center">
                                          {activity.name}
                                        </td>
                                        <td>
                                          <div className="d-flex flex-column align-items-center justify-content-around">
                                            <p className="my-0">
                                              Inicio: &nbsp;
                                              {new Date(
                                                activity.dateStart,
                                              ).toLocaleDateString('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </p>
                                            <div className="p-1" />
                                            <p className="my-0">
                                              Fin: &nbsp;
                                              {new Date(
                                                activity.dateEnd,
                                              ).toLocaleDateString('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              },
                            );
                          }
                          return (
                            <>
                              <tr>
                                <td rowSpan={1}>{objective.name}</td>
                                <td rowSpan={1}>
                                  <div className="d-flex flex-column align-items-center justify-content-around">
                                    <p>
                                      Inicio: &nbsp;
                                      {new Date(
                                        objective.dateStart,
                                      ).toLocaleDateString('es-MX', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                      })}
                                    </p>
                                    <p>
                                      Fin: &nbsp;
                                      {new Date(
                                        objective.dateEnd,
                                      ).toLocaleDateString('es-MX', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                      })}
                                    </p>
                                  </div>
                                </td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>

                {/* {cal > 0 && (
                  <div
                    className="w-100"
                    style={{
                      height: cal,
                    }}
                  />
                )} */}

                <Row className="firma-container" ref={refSignatures}>
                  <Col xs={6}>
                    <div className="Firma">
                      <p>__________________________________________</p>
                      <p className="text-capitalize texto-firma">
                        {`${reportInfo?.director.title} ${reportInfo?.director.name} ${reportInfo?.director.firstSurname} ${reportInfo?.director.secondSurname}`}
                      </p>
                      <p className="texto-firma">DIRECTOR (A)</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="Firma">
                      <p>__________________________________________</p>
                      <p className="text-capitalize texto-firma">
                        {`${reportInfo?.investigator.title} ${reportInfo?.user.name} ${reportInfo?.user.firstSurname} ${reportInfo?.user.secondSurname}`}
                      </p>
                      <p className="texto-firma">RESPONSABLE DEL PROYECTO</p>
                    </div>
                  </Col>
                </Row>
              </tbody>

              <tfoot>
                <div style={{height: refFooter.current?.clientHeight || 0}}>
                  &nbsp;
                </div>
              </tfoot>
            </table>

            <header
              className="px-3 pt-3"
              ref={refHeader}
              onLoad={() => {
                setHeaderSize(refHeader.current?.clientHeight || 0);
              }}>
              <div className="tablaTitulo pb-5 w-100 d-flex flex-row" id="test">
                <div className="mx-2">
                  <img width="125" height="62.5" src="\UANL_Logo.png"></img>
                </div>
                <div className="mx-2">
                  <p>
                    SECRETARÍA DE INVESTIGACIÓN CIENTÍFICA Y DESARROLLO
                    TECNOLÓGICO
                  </p>

                  <p>Dirección de Investigación</p>
                  <p>Programa de Apoyo a la Ciencia, Tecnología e Innovación</p>
                  <br />
                  <p className="desgloseTitulo">Objetivos y Actividades</p>
                  <p className="letrasChicas">{`Fecha de finalizacion de registro del proyecto: ${reportInfo?.date.toLocaleDateString(
                    'es-MX',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    },
                  )}`}</p>
                </div>
                <div className="image-header-right mx-2">
                  <img width="75" height="75" src="\UANL90.png"></img>
                </div>
              </div>
            </header>

            <footer
              className="px-3 pb-4 true-footer"
              ref={refFooter}
              onLoad={() => {
                setFooterSize(refFooter.current?.clientHeight || 0);
              }}>
              <p className="letrasChicas">
                *Este documento debe ser entregado con firmas originales y con
                el sello de la dependencia.
              </p>
            </footer>
          </div>
        )}
      </div>
    </>
  );
};

export default HelpRequestReport;

import React from 'react';
import './BottomBar.scss';

import {Container, Navbar, Nav, Image} from 'react-bootstrap';

const BottomBar = ({hub}: BottomBarProps) => {
  return (
    <Navbar
      expand="lg"
      variant={hub ? 'dark' : 'light'}
      bg={hub ? 'primary' : undefined}
      className="bottom-bar">
      <Container
        className="h-75 mx-3 d-flex flex-row justify-content-between"
        fluid>
        <Navbar.Brand
          href="https://www.uanl.mx/"
          target="_blank"
          className="h-100 d-flex flex-row align-items-center">
          {/* Either figure out a way to invert the colors, or just make them in gimp */}
          <Image
            src={hub ? '/LogoUANLWhite.png' : '/LogoUANL.png'}
            fluid
            style={{height: '40px'}}
            //className="h-100"
          />
          <div
            className={`vr mx-3 ${hub ? 'white-vertical-bar' : 'vertical-bar'}`}
          />
          <Image
            src={hub ? '/loginLogoWhite.png' : '/loginLogo.png'}
            fluid
            style={{height: '40px'}}
            //className="h-100"
          />
        </Navbar.Brand>

        <Navbar.Brand
          href="https://investigacion.uanl.mx/"
          target="_blank"
          className="h-100 d-flex flex-row align-items-center">
          <Image
            src={hub ? '/2030LogoWhite.png' : '/2030LogoBlack.png'}
            fluid
            style={{height: '40px'}}
            //className="h-100"
          />
          <div className="d-flex flex-row d-none d-sm-flex">
            <div
              className={`vr mx-3 ${
                hub ? 'white-vertical-bar' : 'vertical-bar'
              }`}
            />
            <Navbar.Text
              className={`bottom-bar-subtitle-text bottom-bar-sicdt p-0 ${
                hub ? 'text-white' : 'text-black'
              }`}>
              SECRETARIA DE INVESTIGACION CIENTIFICA Y DESAROLLO TECNOLOGICO
            </Navbar.Text>
          </div>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

type BottomBarProps = {
  hub?: boolean;
};

export default BottomBar;

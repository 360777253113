export const regex = {
  emailEdu: new RegExp('^[\\w.+\\-]+@uanl?\\.mx|[\\w.+\\-]+@uanl.edu?\\.mx|$'),
  emailUanl: new RegExp('^[\\w.+\\-]+@uanl?\\.mx|[\\w.+\\-]+@uanl?\\.mx|$'),
  email: new RegExp(
    '^[_a-zA-Z0-9-]+(\\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,3})$',
  ),
  miniMaxChars: new RegExp('.{8,15}$'),
  onlyUpperChar: new RegExp('^[A-Z]+$'),
  onlyLowerChar: new RegExp('^[a-z]+$'),
  onlyNumbers: new RegExp('^[0-9]+$'),
  onlyNumbersOptional: new RegExp('^[ 0-9]+$'),
  onlyAlphaNumericValues: new RegExp('^[a-zA-Z0-9]*$'),
  onlyAlphaNumericValuesAndSpace: new RegExp('^[ a-zA-Z0-9]*$'),
  symbols: new RegExp('[!@#$%^&*()_+=[{]};:<>|./?,-]$'),
  includesUpperChar: new RegExp('[A-Z]+'),
  includesLowerChar: new RegExp('[a-z]+'),
  includesNumbers: new RegExp('[0-9]+'),
  includesNewSiaseSymbols: new RegExp('[-@#$_*;:=+.,&()]+'),
  cellphone: new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'),
  AlphaNumericValuesAndSpaceAndSpecial: new RegExp(
    '^[-@#$_*;:=+.,&() a-zá-úüñA-ZÁ-ÚÜÑ0-9]*$',
  ),
  mexicanRegex: new RegExp('^-_/.,A-Za-zÀ-ÿ &&[^×÷]+', 'u'),
};

import React from 'react';
import {Student} from '../../../types/Student';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChartColumn,
  faFilePen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import {AcademicGrade} from '../../../types/AcademicGrade';
import {useSelector} from 'react-redux';
import {selectConvocation, selectProject} from '../../../redux/slice/inv.slice';
import ButtonIcon from '../../../components/ButtonIcon';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const RowStudent = ({
  student,
  academicGrades,
  ShowUpdateStudentModal,
  ShowDeleteStudentModal,
}: RowStudentProps) => {
  const currentProject = useSelector(selectProject);
  const isClosed = currentProject?.isClosed;
  const currentConvocation = useSelector(selectConvocation);

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const academicGrade = academicGrades.find(
    ({academicGradeId}) => academicGradeId === student.academicGradeId,
  );
  return (
    <>
      <tr>
        <td>{student.name}</td>
        <td>{student.matricula}</td>
        <td>{academicGrade?.name || ''}</td>
        <td>{student.degree}</td>

        <td>
          <div className="d-flex flex-row p-1">
            <ButtonIcon
              icon={faFilePen}
              toolTip="Modificar Alumno"
              buttonProps={{
                className: 'ms-1',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowUpdateStudentModal(student),
              }}
            />

            <ButtonIcon
              icon={faTrashCan}
              toolTip="Borrar alumno"
              buttonProps={{
                className: 'ms-1',
                size: 'sm',
                disabled: isClosed || !isRegistrationActive,
                onClick: () => ShowDeleteStudentModal(student),
              }}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

type RowStudentProps = {
  student: Student;
  academicGrades: AcademicGrade[];
  ShowUpdateStudentModal: (student: Student) => void;
  ShowDeleteStudentModal: (student: Student) => void;
};

export default RowStudent;

import React, {useEffect, useState} from 'react';
import './InstitutesCollabs.css';
import {Button, Form, Modal, Row, Spinner} from 'react-bootstrap';
import FormCampInput from '../../../components/FormCampInput';
import FormCampFile from '../../../components/FormCampFile';
import {SectorTypeEnum} from '../../../constants/SectorTypeEnum';
import {SubmitHandler, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {
  useCreateCollaborationInstitutionMutation,
  useDeleteCollaborationInstitutionMutation,
  useGetCurrentConvocationMutation,
  useGetUserCurrentCollaborationInstitutionMutation,
  useGetUserCurrentProjectMutation,
  useUpdateCollaborationInstitutionMutation,
} from '../../../redux/services/inv.api';
import {useDispatch, useSelector} from 'react-redux';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {CollaborationInstitution} from '../../../types/CollaborationInstitution';
import {toast} from 'react-toastify';
import {validateFile} from '../../../helpers/ValidateFile';
import {
  InCreateCollaborationInstitutionDto,
  InUpdateCollaborationInstitutionDto,
} from '../../../types/Dtos/InvDtos';
import {useBlocker, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import {RequieredForFinalizing} from '../../../constants/RequieredForFinalizing';

// TODO: This page is not up to the date with the way we are going to manage forms
// using zod and react form hook

const InstitutesCollabs = () => {
  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      (collab != originalCollab || isDirty) &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const currentProject = useSelector(selectProject);
  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  const isClosed = currentProject?.isClosed;
  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields, isDirty},
    reset,
    watch,
    setValue,
  } = useForm<InstitutesCollabsInfo>({
    resolver: zodResolver(institutesCollabSchema),
    mode: 'onSubmit',
  });

  const watchInputFileList = watch('inputFileList');
  const watchDocumentFile = watch('documentFile');
  const watchDocumentName = watch('documentName');
  const watchSectorType = watch('sectorType');

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [loading, setLoading] = useState(true);
  const [collab, setCollab] = useState(false);
  const [originalCollab, setOriginalCollab] = useState(false);

  const [collabInstitution, setCollabInstitution] = useState<
    CollaborationInstitution | undefined
  >(undefined);
  const [getCollab, {isLoading: isGetCollabLoading}] =
    useGetUserCurrentCollaborationInstitutionMutation();

  const [registerCollab, {isLoading: isRegisterCollabLoading}] =
    useCreateCollaborationInstitutionMutation();

  const [sendUpdateCollab, {isLoading: isSendUpdateProjectLoading}] =
    useUpdateCollaborationInstitutionMutation();

  const [deleteCollab, {isLoading: isDeleteCollabLoading}] =
    useDeleteCollaborationInstitutionMutation();

  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();

  const onSubmit: SubmitHandler<InstitutesCollabsInfo> = data => {
    console.log(data);
    if (collabInstitution) {
      handleUpdateCollabSubmit({
        ...data,
        sectorType: data.sectorType == SectorTypeEnum.Public,
        projectId: currentProject?.projectId ?? 0,
        collaborationInstitutionId: data.collaborationInstitutionId ?? 0,
      });
    } else {
      handleRegisterCollabSubmit({
        ...data,
        projectId: currentProject?.projectId ?? 0,
        sectorType: data.sectorType == SectorTypeEnum.Public,
      });
    }
  };

  const handleGetCollab = async () => {
    const responseData = await getCollab(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;

        let sector: number;
        if (data.sectorType == true) sector = SectorTypeEnum.Public;
        else sector = SectorTypeEnum.Private;
        setCollabInstitution(data);
        setCollab(true);
        setOriginalCollab(true);

        reset({...data, sectorType: sector});
        //TODO: esto no arregla que se desmarque, solo parpadea y deja de estarlo.
        //setValue('sectorType', 1);
        return;
      }
    } else
      toast.error(resultData?.message ?? 'Error al cargar la colaboración.');
  };

  const handleRegisterCollabSubmit = async (
    sendData: InCreateCollaborationInstitutionDto,
  ) => {
    if (isClosed) return;

    const responseData = await registerCollab({
      ...sendData,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        //TODO: Success petition
        setCollabInstitution(data);
      }
      setOriginalCollab(true);
      reset({}, {keepValues: true});
      //toast.info('Instituto registrado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleUpdateCollabSubmit = async (
    sendData: InUpdateCollaborationInstitutionDto,
  ) => {
    if (isClosed) return;
    if (currentProject == undefined || currentProject.projectId == undefined)
      return;

    const responseData = await sendUpdateCollab({
      ...sendData,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        //TODO: Success petition
        //dispatch(updateProject(data));
        setCollabInstitution(data);
      }
      setOriginalCollab(true);
      reset({}, {keepValues: true});
      //toast.info('Instituto actualizado!');
      toast.success('Su información se guardo correctamente');
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  const handleDeleteCollab = async (sendData: CollaborationInstitution) => {
    if (isClosed) return;
    const responseData = await deleteCollab(sendData);
    let activityData = undefined;
    if ('data' in responseData) {
      activityData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error.data as ServiceResponse<undefined>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (activityData?.success) {
      // TODO: Handle Data
      setOriginalCollab(false);
      setCollabInstitution(undefined);
      reset();
      setValue('sectorType', SectorTypeEnum.Public);
      //toast.info('Se ha actualizado a ninguna colaboración.');
      toast.success('Su información se guardo correctamente');
      //navigate(Paths.investigator.STUDENTSINFORMATION);
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      } else {
        toast.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
      }
    }
  };

  useEffect(() => {
    if (watchInputFileList && watchInputFileList.length >> 0) {
      const handleValidateFile = async (file: File) => {
        //console.log(file.name);
        const result = await validateFile({file});

        if (result.error || !result.textFile || result.textFile === '') {
          toast.warning(result.error);
          setValue('documentFile', undefined);
          setValue('documentName', undefined);
          setValue('inputFileList', undefined);
          return;
        }

        //toast.success('Archivo valido!');
        setValue('documentFile', result.textFile);
        setValue('documentName', result.fileName);
      };

      handleValidateFile(watchInputFileList[0]);
    }
  }, [watchInputFileList]);

  useEffect(() => {
    checkConvocation();
    checkProject();
    //Si el boolean es verdadero o falso cambiar el sectortype

    if (collabInstitution?.sectorType === false)
      reset({sectorType: SectorTypeEnum.Private});
    else reset({sectorType: SectorTypeEnum.Public});
  }, []);

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      await handleGetCollab();
      setLoading(false);
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));

        await handleGetCollab();
        setLoading(false);
        return;
      }
    } else {
      console.error(resultData?.message ?? 'No funciono la peticion.');
      return;
    }

    //TODO: Warn user to register a project first to precede.
    toast.error(
      'Es necesario registrar el Nombre del Proyecto para continuar.',
    );
    navigate(Paths.investigator.REGISTERPROJECT);
    return;
  };

  useEffect(() => {
    console.log(watchSectorType);
  }, [watchSectorType]);

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <div className="d-flex flex-column h-100 w-100 p-2 ">
            <Row xs={12} className="px-5">
              <p className="text-center mb-3 login-text ">
                Colaboración de Instituciones
              </p>
            </Row>
            <Row xs={12} className="px-5">
              <Form>
                <div className="d-flex flex-row mb-3">
                  <label htmlFor="groupCollaboration">
                    ¿Existe colaboración con algún sector de la sociedad?
                  </label>
                  &nbsp;
                  <Form.Check
                    type="radio"
                    name="groupCollaboration"
                    label="No"
                    value={0}
                    onClick={() => {
                      setCollab(false);
                      reset();
                      setValue('sectorType', SectorTypeEnum.Public);
                    }}
                    checked={!collab} /* {...register('')} */
                    disabled={isClosed || !isRegistrationActive}
                  />
                  &nbsp;&nbsp;
                  <Form.Check
                    type="radio"
                    name="groupCollaboration"
                    label="Si"
                    value={1}
                    onClick={() => {
                      setCollab(true);
                    }}
                    checked={collab} /* {...register('')} */
                    disabled={isClosed || !isRegistrationActive}
                  />
                </div>
                <div className="d-flex flex-row">
                  <Button
                    disabled={
                      isRegisterCollabLoading ||
                      isSendUpdateProjectLoading ||
                      isDeleteCollabLoading ||
                      isClosed ||
                      !isRegistrationActive
                    }
                    variant="success"
                    className="ms-auto mt-auto"
                    //type="submit"
                    hidden={collab}
                    onClick={() => {
                      if (collabInstitution) {
                        handleDeleteCollab(collabInstitution);
                      } else {
                        reset();
                        navigate(Paths.investigator.STUDENTSINFORMATION);
                      }
                    }}>
                    Guardar
                  </Button>
                </div>
              </Form>
            </Row>
            <Row xs={12} className="px-5">
              {/* Form de envio de Institucion*/}
              <Form onSubmit={handleSubmit(onSubmit)} hidden={!collab}>
                <div className="d-flex flex-row mb-3">
                  <label htmlFor="groupCollaboration">Tipo de Sector</label>
                  &nbsp; &nbsp; &nbsp;
                  <Form.Check
                    type="radio"
                    label="Público"
                    value={SectorTypeEnum.Public}
                    {...register('sectorType')}
                    checked={watchSectorType == SectorTypeEnum.Public}
                    disabled={isClosed || !isRegistrationActive}
                  />
                  &nbsp;&nbsp;
                  <Form.Check
                    type="radio"
                    label="Privado"
                    value={SectorTypeEnum.Private}
                    {...register('sectorType')}
                    checked={watchSectorType == SectorTypeEnum.Private}
                    disabled={isClosed || !isRegistrationActive}
                  />
                </div>
                <br />
                <FormCampInput
                  name="institutionName"
                  {...RequieredForFinalizing}
                  displayName="Nombre de la institución interesada"
                  max="300"
                  displayCount
                  inputProps={{
                    as: 'textarea',
                    style: {resize: 'none', height: '5em'},
                    ...register('institutionName'),
                    disabled: isClosed || !isRegistrationActive,
                  }}
                  errors={errors}
                  // touchedFields={touchedFields}
                />
                <FormCampInput
                  name="institutionResponsible"
                  {...RequieredForFinalizing}
                  displayName="Responsable de la institución"
                  max="300"
                  displayCount
                  inputProps={{
                    as: 'textarea',
                    style: {resize: 'none', height: '5em'},
                    ...register('institutionResponsible'),
                    disabled: isClosed || !isRegistrationActive,
                  }}
                  errors={errors}
                  // touchedFields={touchedFields}
                />

                <FormCampFile
                  file={{
                    documentFile: watchDocumentFile,
                    documentName: watchDocumentName,
                  }}
                  {...RequieredForFinalizing}
                  name="inputFileList"
                  displayName="Cargar comprobante de colaboración (PDF)"
                  inputProps={{
                    ...register('inputFileList'),
                    disabled: isClosed || !isRegistrationActive,
                  }}
                />

                <div className="d-flex flex-row mb-3 pb-3">
                  <Button
                    disabled={
                      isRegisterCollabLoading ||
                      isSendUpdateProjectLoading ||
                      isDeleteCollabLoading ||
                      !isDirty ||
                      isClosed ||
                      !isRegistrationActive
                    }
                    variant="success"
                    className="mt-auto ms-auto"
                    type="submit">
                    Guardar
                  </Button>
                </div>
              </Form>
            </Row>
            <div className="text-center pb-3">
              <Button
                className="ms-3"
                onClick={() => {
                  navigate(Paths.investigator.STUDENTSINFORMATION);
                }}>
                Siguiente
              </Button>
            </div>
          </div>
        )}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>

      {blocker.state === 'blocked' ? (
        <Modal show={true} onHide={() => blocker.reset()}>
          <Modal.Header closeButton>
            <Modal.Title>¿Salir?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estas seguro que deseas salir y perder los cambios que han hechos?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => blocker.reset()}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => blocker.proceed()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

const institutesCollabSchema = z.object({
  collaborationInstitutionId: z.number().default(0).optional(), //TODO: esta es necesaria?
  sectorType: z.coerce.number().default(SectorTypeEnum.Private).optional(),
  institutionName: z
    .string()
    .trim()
    .max(300, {
      message: 'El límite del titulo es 300 carácteres',
    })
    .optional(),
  institutionResponsible: z
    .string()
    .trim()
    .max(300, {
      message: 'El límite del titulo es 300 carácteres',
    })
    .optional(),
  projectId: z.number().optional(),
  documentFile: z.string().max(31457280).optional(),
  documentName: z.string().max(500).optional(),
  inputFileList: z.any().optional(),
});

type InstitutesCollabsInfo = z.infer<typeof institutesCollabSchema>;

export default InstitutesCollabs;

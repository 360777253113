import React from 'react';
import './NavigationBar.scss';

import {Container, Navbar, Nav} from 'react-bootstrap';

const AdminBar = () => {
  return (
    <Navbar bg="light" expand="lg" className="main-header top-bar">
      <Container className="h-75" fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex flex-row ms-auto">
            {/* TODO: Logout */}
            <Nav.Link
              href="/"
              className="text-end align-self-end py-0 d-block d-sm-none">
              Cerrar Sesion
            </Nav.Link>
            <Nav.Item className="mx-2 d-none d-sm-block ms-auto py-2">
              <div className="d-flex flex-column d-none d-sm-flex my-0 py-0 ms-auto">
                <span className="top-bar-welcome-text top-bar-welcome-text-user">
                  LIC. Usuario Temporal
                </span>
                <a
                  href="/"
                  className="top-bar-welcome-text text-end nav-link p-0 text-logout">
                  Cerrar Sesion
                </a>
              </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminBar;

import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons';

export const RequieredForFinalizing = {
  displayIcon: faAsterisk,
  displayIconColor: 'red',
  displayTooltipClassName: 'tooltip tooltip-warning',
  displayIconTooltip: 'Campo obligatorio para finalizar el registro',
  displayIconSize: '2xs' as SizeProp,
};

import React, {useState, useEffect} from 'react';
import {Form, Table} from 'react-bootstrap';
import Panel from '../../../components/Panel';
import './ProjectList.css';
import {useNavigate} from 'react-router-dom';

const ProjectRow = ({
  project: {id, name, investigator, dependency, state, completeDocumentation},
  downloadFile,
}: ProjectRowProp) => {
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{name}</td>
      <td>{investigator}</td>
      <td>{dependency}</td>
      <td>{state}</td>
      <td>
        <Form.Check
          checked={completeDocumentation}
          type={'checkbox'}
          id={`complete-documentation-${id}`}
        />
      </td>
    </tr>
  );
};

const ProjectList = () => {
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState<Project[]>([]);

  const openProjectsList = async () => {
    setLoading(true);

    setProjectList([]);

    // TODO: Redux async loading
    const loadInfoList = async () => {
      setProjectList([
        {
          id: 1,
          name: 'project1',
          investigator: 'investigator1',
          dependency: 'dependency1',
          state: 'Nuevo',
          completeDocumentation: false,
        },
        {
          id: 2,
          name: 'project2',
          investigator: 'investigator2',
          dependency: 'dependency2',
          state: 'Completado',
          completeDocumentation: true,
        },
      ]);
    };

    await loadInfoList();

    setLoading(false);
  };

  useEffect(() => {
    openProjectsList();
  }, []);

  return (
    <>
      <Panel
        overrideColProps={{
          className: 'd-flex align-items-center h-100',
        }}
        overrideContainerProps={{fluid: true, className: 'generic-panel'}}>
        {!loading && (
          <div className="d-flex flex-column justify-content-center p-4">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="financialBreakdownTableId">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Clave del Proyecto</th>
                      <th>Nombre del Proyecto</th>
                      <th>Nombre del Investigador</th>
                      <th>Dependencia</th>
                      <th>Estado</th>
                      <th>Documentación completa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectList.map(project => {
                      return <ProjectRow project={project} />;
                    })}
                  </tbody>
                </Table>
              </Form.Group>
            </Form>
          </div>
        )}
      </Panel>
    </>
  );
};
type Project = {
  id: number;
  name: string;
  investigator: string;
  dependency: string;
  state: string;
  completeDocumentation: boolean;
};

type ProjectRowProp = {
  project: Project;
  downloadFile?: () => void;
};

export default ProjectList;

import React from 'react';
import {Container, Row} from 'react-bootstrap';
import './Landing.css';
import Panel from '../../components/Panel';
import {Navigate} from 'react-router-dom';

// TODO: The entire page is missing, use Jean's design
// TODO: Look for a good carusel

// Currently redirecting to Login
const Landing = () => {
  // return (
  //   <>
  //     <div style={{height: '10%'}}></div>
  //     <Container fluid className="p-0 landing-container main-container">
  //       <Container fluid className="panel-container h-100 d-flex flex-column">
  //         <Row className="h-100">
  //           {/*
  //         <Panel xs={12} lg={4}>
  //           <div className="d-flex flex-column justify-content-center h-100 p-3">
  //             <p className="text-secondary mb3">Landing</p>
  //           </div>
  //         </Panel>
  //         */}
  //         </Row>
  //       </Container>
  //     </Container>
  //   </>
  // );

  return <Navigate to="/login" replace />;
};

export default Landing;

import React, {useEffect} from 'react';
import './NavigationBar.scss';

import {Container, Navbar, Nav} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectUser, logout} from '../../../redux/slice/auth.slice';
import {
  invLogout,
  selectConvocation,
  selectInvestigator,
  selectProject,
  updateConvocation,
  updateInvestigator,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {
  useGetCurrentConvocationMutation,
  useGetInvestigatorProfileMutation,
  useGetUserCurrentProjectMutation,
} from '../../../redux/services/inv.api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';

const InvestigationBar = () => {
  const curretUser = useSelector(selectUser);
  const currentInvestigator = useSelector(selectInvestigator);
  const currentProject = useSelector(selectProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocationLoading}] =
    useGetCurrentConvocationMutation();

  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();

  const [getInvetigatorProfile, {isLoading: isInvestigationProfileLoading}] =
    useGetInvestigatorProfileMutation();

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      // toast.error(
      //   resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      // );
    }
  };

  const checkProject = async () => {
    if (currentProject) return;

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    if (resultData?.success && resultData.data) {
      dispatch(updateProject(resultData.data));
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (!resultData?.success) {
      // TODO: Error, no project?
    }
  };

  const checkInvestigator = async () => {
    if (currentInvestigator) return;

    const responseData = await getInvetigatorProfile(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }

    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateInvestigator(data.inv));
      }
    }

    return undefined;
  };

  useEffect(() => {
    checkConvocation();
    checkProject();
    checkInvestigator();
  }, []);

  return (
    <Navbar bg="light" expand="false" className="main-header top-bar hub">
      <Container className="d-flex flex-row ms-auto justify-content-end" fluid>
        {!isGetCurrentConvocationLoading &&
          !isInvestigationProfileLoading &&
          !isGetUserCurrentProjectLoading && (
            <>
              {currentProject && currentProject.isClosed && (
                <>
                  <Nav.Item className="finalized-text d-flex align-self-center align-items-center">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="finalized-text"
                    />
                    &nbsp; El registro ha sido finalizado por el investigador.
                  </Nav.Item>
                </>
              )}
              {currentProject &&
                !currentProject.isClosed &&
                !isRegistrationActive && (
                  <>
                    <Nav.Item className="registration-finalized-text d-flex align-self-center align-items-center">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="registration-finalized-text"
                      />
                      &nbsp; La fecha de registro de la convocatoria ha
                      terminado.
                    </Nav.Item>
                  </>
                )}
            </>
          )}

        <Nav.Item className="ms-auto py-0 d-block">
          <span className="d-flex flex-column top-bar-welcome-text top-bar-welcome-text-user text-capitalize">
            {curretUser &&
              `${
                (currentInvestigator &&
                  currentInvestigator?.title.toLowerCase()) ??
                ''
              } ${curretUser?.name.toLowerCase()} ${curretUser?.firstSurname.toLowerCase()} ${curretUser?.secondSurname.toLowerCase()}`}
          </span>

          <Nav.Link
            //href="/"
            onClick={() => {
              dispatch(logout());
              dispatch(invLogout());
              navigate('/');
            }}
            className="text-end align-self-end py-0 d-block text-clickable">
            Cerrar Sesión
          </Nav.Link>
        </Nav.Item>
      </Container>
    </Navbar>
  );
};

export default InvestigationBar;

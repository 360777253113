import React, {ReactElement} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/esm/types';

const TooltipWrap = ({
  toolTip,
  toolTipPlacement = 'top',
  children,
}: TooltipWrapProps) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {toolTip}
    </Tooltip>
  );
  return (
    <>
      <OverlayTrigger
        placement={toolTipPlacement}
        delay={{show: 250, hide: 400}}
        overlay={toolTip ? renderTooltip : <></>}>
        {children}
      </OverlayTrigger>
    </>
  );
};

type TooltipWrapProps = {
  toolTip?: string;
  toolTipPlacement?: Placement;
  children: ReactElement;
};

export default TooltipWrap;

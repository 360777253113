import React, {useEffect, useRef, useState} from 'react';
import {faCircleInfo, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Form,
  FormControlProps,
  FloatingLabel,
  FloatingLabelProps,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {BsPrefixProps} from 'react-bootstrap/esm/helpers';
import {FieldErrors, FieldValues} from 'react-hook-form';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

function FormCampInput<Type, ErrorType extends FieldValues, TouchedType>({
  name,
  displayName,
  inputProps,
  formGroupClassName = 'mb-3',
  labelProps,
  controlId,
  errors,
  touchedFields,
  autoComplete,
  size = undefined,
  tooltipText,
  tooltipClassName = 'inherit-background-color',
  toggleFloat = false,
  displayIcon,
  displayIconTooltip,
  displayIconColor,
  displayIconSize,
  displayTooltipClassName = 'tooltip',
  min = '',
  max = '',
  displayCount = false,
}: FormCampInputProp<Type, ErrorType, TouchedType>) {
  const controlRef = useRef<HTMLInputElement>((inputProps as any)?.ref ?? null);
  const [textLength, setTextLength] = useState(0);

  useEffect(() => {
    if (
      controlRef &&
      controlRef.current &&
      typeof controlRef.current.value === 'string'
    ) {
      setTextLength(controlRef.current.value.length);
    }
  }, [controlRef.current]);

  const inputControl = (
    <Form.Control
      size={size}
      //placeholder={displayName}
      autoComplete={autoComplete}
      isValid={
        touchedFields &&
        errors &&
        touchedFields[name as unknown as keyof TouchedType] &&
        !errors[name as keyof ErrorType]
      }
      isInvalid={
        touchedFields &&
        errors &&
        touchedFields[name as unknown as keyof TouchedType] &&
        !!errors[name as keyof ErrorType]
      }
      min={min}
      max={max}
      {...inputProps}
      onChange={e => {
        if (inputProps && (inputProps as any).onChange) {
          (inputProps as any).onChange(e);
        }
        if (controlRef && controlRef.current) {
          setTextLength(controlRef.current.value.length);
        }
      }}
      ref={(e: any) => {
        if (inputProps && (inputProps as any).ref) {
          (inputProps as any).ref(e);
          controlRef.current = e; // you can still assign to ref
        }
      }}
    />
  );
  return (
    <Form.Group
      className={formGroupClassName}
      controlId={controlId ?? `${name as string}Group`}>
      {toggleFloat ? (
        <></>
      ) : (
        <>
          <Form.Label {...labelProps}>
            {displayName}

            {displayIcon && displayIconTooltip ? (
              <>
                &nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className={displayTooltipClassName}>
                      {displayIconTooltip}
                    </Tooltip>
                  }>
                  <FontAwesomeIcon
                    color={displayIconColor}
                    icon={displayIcon}
                    size={displayIconSize ?? 'xs'}
                  />
                </OverlayTrigger>
              </>
            ) : displayIcon ? (
              <>
                &nbsp;
                <FontAwesomeIcon
                  color={displayIconColor}
                  icon={displayIcon}
                  size={displayIconSize ?? 'xs'}
                />
              </>
            ) : (
              <></>
            )}
          </Form.Label>
        </>
      )}
      <InputGroup>
        {toggleFloat ? (
          <>
            <FloatingLabel
              size={size}
              controlId={controlId ?? (name as string)}
              label={displayName}
              //placeholder={displayName}
              {...labelProps}>
              {inputControl}
            </FloatingLabel>
          </>
        ) : (
          <>{inputControl}</>
        )}

        {tooltipText && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip style={{position: 'fixed'}}>{tooltipText}</Tooltip>
            }>
            <InputGroup.Text className={tooltipClassName}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </InputGroup.Text>
          </OverlayTrigger>
        )}
      </InputGroup>
      {displayCount && controlRef && controlRef.current && (
        <>
          <Form.Label className="d-flex flex-row">
            <span className="ms-auto">{`${textLength}/${max.toString()}`}</span>
          </Form.Label>
        </>
      )}

      {errors && errors[name as keyof ErrorType] && (
        <Form.Label className="text-danger" as="small" size="sm">
          {errors[name]?.message as string}
        </Form.Label>
      )}
      {errors && !errors[name as keyof ErrorType] && <br />}
    </Form.Group>
  );
}

//TouchedType and Erros are required to be another Generic, because even if it has the same named
//properties, they are booleans instead of strings,
type FormCampInputProp<Type, ErrorType extends FieldValues, TouchedType> = {
  controlId?: string;
  name: keyof Type;
  size?: 'sm' | 'lg' | undefined;
  displayName: string;
  displayIcon?: IconDefinition;
  displayIconColor?: string;
  displayIconTooltip?: string;
  displayIconSize?: SizeProp;
  displayTooltipClassName?: string;
  labelProps?: FloatingLabelProps;
  inputProps?: FormControlProps | BsPrefixProps<'input'>;
  formGroupClassName?: string;
  autoComplete?: string;
  errors?: FieldErrors<ErrorType>;
  touchedFields?: Partial<Readonly<TouchedType>>;
  tooltipText?: string;
  tooltipClassName?: string;
  toggleFloat?: boolean;
  min?: string;
  max?: string;
  displayCount?: boolean;
};

export default FormCampInput;

import React, {CSSProperties, ReactNode} from 'react';
import {Col, ColProps, Container, ContainerProps} from 'react-bootstrap';

const Panel = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  children,
  overrideContainerProps,
  overrideColProps,
  panelClassName = 'h-100 overflow-auto',
  overridePanelStyle,
}: PanelProps) => {
  const colProps = overrideColProps ?? {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    className: 'd-flex align-items-center h-100',
  };

  const containerProps = overrideContainerProps ?? {
    fluid: true,
    className: 'h-75',
  };

  const panelStyle = overridePanelStyle ?? {
    background: 'white',
    boxShadow: '3px 6px 24px 0px rgba(0,0,0,0.75)',
    WebkitBoxShadow: '3px 6px 24px 0px rgba(0,0,0,0.75)',
  };

  return (
    <Col {...colProps}>
      <Container {...containerProps}>
        <div className={panelClassName} style={panelStyle}>
          {children}
        </div>
      </Container>
    </Col>
  );
};

type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type PanelProps = {
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
  xxl?: Size;
  children: ReactNode;
  overrideContainerProps?: ContainerProps;
  overrideColProps?: ColProps;
  panelClassName?: string;
  overridePanelStyle?: CSSProperties;
};

export default Panel;

import React from 'react';
import {Button, Col, Container, Form, Image, Row} from 'react-bootstrap';
import {useForm, SubmitHandler} from 'react-hook-form';
import z from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import './Login.scss';
import Panel from '../../components/Panel';
import FormCampInput from '../../components/FormCampInput';
import {regex} from '../../constants/Regexs';
import {useLoginMutation} from '../../redux/services/auth.api';
import {Role} from '../../constants/Role';
import {User} from '../../types/User';
import {Link, useNavigate} from 'react-router-dom';
import {loginAuth} from '../../redux/slice/auth.slice';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import Paths from '../../constants/Paths';
import LoadingSpinner from '../../components/LoadingSpinner';
import {ReactComponent as Atencion} from '../../assets/atencion.svg';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: {errors},
    //watch, //get the current value of the fields if needed
  } = useForm<LoginInfo>({
    resolver: zodResolver(loginSchema),
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<LoginInfo> = data => handleLoginSubmit(data);

  const [login, {isLoading: isLoginLoading}] = useLoginMutation();

  const handleLoginSubmit = async (sendData: LoginInfo) => {
    const responseData = await login(sendData);
    let loginData = undefined;

    if ('data' in responseData) {
      loginData = responseData.data;
    }

    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (loginData?.success) {
      if (loginData?.data) {
        const data = loginData?.data;
        dispatch(
          loginAuth({user: data.user, usu: data.usu, token: loginData.message}),
        );
        redirectUserOnLogin(loginData?.data.user ?? undefined);
      }
    } else {
      toast.error(
        loginData?.message ??
          'Número de empleado y/o contraseña incorrectos, favor de intentarlo de nuevo.',
        {},
      );
    }
  };

  const redirectUserOnLogin = (data: User | undefined) => {
    try {
      if (data) {
        let redirectUrl: string | null = '';

        switch (data.roleId) {
          case Role.Administrator: {
            redirectUrl = Paths.admin.HUB;
            break;
          }
          case Role.Investigator: {
            redirectUrl = Paths.investigator.HUB;
            break;
          }
          case Role.Assistant: {
            // TODO: Correct URL
            redirectUrl = Paths.admin.HUB;
            break;
          }
          case Role.Auditor: {
            // TODO: Correct URL
            redirectUrl = Paths.admin.HUB;
            break;
          }
          default:
            redirectUrl = null;
        }

        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          // No User Role Error
          throw new Error(
            'Ha ocurrido un problema, favor de intentarlo de nuevo.',
          );
        }
      } else {
        // Something's gone wrong to get all the way over here and not be catched before hand
        throw new Error(
          'Ha ocurrido un problema, favor de intentarlo de nuevo.',
        );
      }
    } catch (e) {
      const ex = (e as Error) ?? undefined;

      if (ex) {
        toast.error(
          ex.message ??
            'Ha ocurrido un problema, favor de intentarlo de nuevo.',
          {},
        );
      }

      console.log(ex);
    }
  };

  return (
    <>
      <Container fluid className="p-0 login-container main-container">
        <Container fluid className="panel-container h-100 d-flex flex-column">
          <Row className="h-100">
            <Panel
              xs={12}
              overrideContainerProps={{fluid: true, className: 'panel-login'}}
              overridePanelStyle={{background: '#FFFFFFEE'}}>
              <div className="d-flex flex-column justify-content-center h-100 px-3 py-4">
                <Row xs={12} className="px-5">
                  <p className="text-center mb-3 login-text">Iniciar Sesión</p>
                </Row>

                <Row xs={12}>
                  <Col
                    xs={4}
                    className="px-lg-5 d-flex flex-row align-items-center">
                    <Image src="/loginLogo.png" fluid />
                  </Col>
                  <Col xs={8} className="d-flex flex-row align-items-end px-3">
                    <Form
                      id="LoginForm"
                      onSubmit={handleSubmit(onSubmit)}
                      className="w-100 col-12">
                      <div
                        className="texto-info mb-3 text-center d-flex flex-row align-items-center"
                        style={{height: '100px'}}>
                        <Atencion className="atencion" />
                        <p>
                          {
                            'Si ya creaste una cuenta en ProACTI, inicia sesión con tu número de empleado y contraseña de SIASE '
                          }
                          <a href="/PROACTI.pdf" target="_blank">
                            Convocatoria ProACTI 2024
                          </a>
                        </p>
                      </div>

                      <FormCampInput
                        name="employeeNumber"
                        displayName="Número de Empleado"
                        inputProps={{
                          type: 'text',
                          ...register('employeeNumber'),
                        }}
                        autoComplete="username"
                        errors={errors}
                        tooltipText="Si olvidaste tu cuenta y contraseña, puedes solicitarla al departamento de Escolar y Archivo de tu Dependencia."
                        // touchedFields={touchedFields}
                      />
                      <FormCampInput
                        name="password"
                        displayName="Contraseña"
                        formGroupClassName=""
                        inputProps={{
                          type: 'password',
                          ...register('password'),
                        }}
                        autoComplete="current-password"
                        errors={errors}
                        // touchedFields={touchedFields}
                      />
                    </Form>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col
                    sm={12}
                    md={6}
                    className="d-flex flex-row justify-content-center aviso-privacidad-col">
                    <a
                      className="my-auto"
                      href="/aviso_privacidad.pdf"
                      target="_blank">
                      Aviso de Privacidad
                    </a>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="d-flex flex-row justify-content-end">
                    <div className="texto-info inicio-sesion-abajo d-flex flex-row my-auto mx-auto">
                      <p className="m-0">Si aún no tienes cuenta&nbsp;</p>
                      <Link to={Paths.SIGNUP}>Registrate</Link>
                    </div>

                    <Button
                      form="LoginForm"
                      className="me-4"
                      variant="primary"
                      type="submit"
                      disabled={isLoginLoading}>
                      Entrar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Row>
        </Container>
      </Container>
      <LoadingSpinner show={isLoginLoading} />
    </>
  );
};

const loginSchema = z.object({
  employeeNumber: z
    .string()
    .toUpperCase()
    .trim()
    .min(1, {message: 'Campo Obligatorio'})
    .max(9, {message: 'Número de empleado no puede tener más de 9 carácteres'})
    .refine(
      value => regex.onlyAlphaNumericValues.test(value),
      'Solo se aceptan carácteres alfanuméricos',
    ),
  // Por el momento estos son los requerimientos de las contraseñas
  // TODO: Talvez no se debe de poner estas validaciones EN EL FRONT END durante el LOG IN
  // Si se tiene que crear una contraseña, entonces si.
  password: z
    .string()
    .max(16, {message: 'Contraseña debe de tener un maximo de 16 carácteres'})
    .min(7, {message: 'Contraseña debe de tener un minimo de 7 carácteres'}),
  // TODO: Temporary password strenght check disabled for testing
  // .refine(
  //   value => regex.includesUpperChar.test(value),
  //   'Contraseña debe incluir mayusculas',
  // )
  // .refine(
  //   value => regex.includesLowerChar.test(value),
  //   'Contraseña debe incluir minusculas',
  // )
  // .refine(
  //   value => regex.includesNumbers.test(value),
  //   'Contraseña debe incluir números',
  // )
  // .refine(
  //   value => regex.includesNewSiaseSymbols.test(value),
  //   'Contraseña debe incluir carácteres especiales validos',
  // ),
});

type LoginInfo = z.infer<typeof loginSchema>;

export default Login;

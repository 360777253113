import React from 'react';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import './Sidebar.scss';
import Paths from '../../../constants/Paths';
import {useLocation, useNavigate} from 'react-router-dom';

const AdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;

  const toggleLogic = (path: string) => {
    if (pathname === path) {
      navigate(Paths.admin.HUB);
      return;
    }

    navigate(path);
  };

  return (
    <>
      <SidebarMenu
        className="sidebar-menu side-bar-container"
        variant="light"
        expand="sm">
        <SidebarMenu.Collapse className="hide-box-shadow">
          <SidebarMenu.Header className="mb-0 pb-0 ps-3">
            <SidebarMenu.Brand
              href="/"
              className="me-3 brand-text sidebar-brand-text">
              ProACTI
            </SidebarMenu.Brand>
          </SidebarMenu.Header>
          <SidebarMenu.Header className="mt-0 pt-0 ps-3">
            <SidebarMenu.Brand
              href="/"
              className="h-100 d-flex flex-row align-items-center mt-0 pt-0">
              <div className="d-flex flex-row d-none d-sm-flex my-0 py-0">
                <div className="vr me-3 black-vertical-bar" />
                <SidebarMenu.Text className="side-bar-subtitle-text my-0 py-0">
                  PROGRAMA DE APOYO A LA
                  <br />
                  CIENCIA, TECNOLOGÍA E INNOVACIÓN
                </SidebarMenu.Text>
              </div>
            </SidebarMenu.Brand>
            {/* <SidebarMenu.Toggle /> */}
          </SidebarMenu.Header>
          <SidebarMenu.Body>
            <SidebarMenu.Nav>
              {/*  */}
              <SidebarMenu.Nav>
                <SidebarMenu.Nav.Link
                  className="d-flex flex-row"
                  onClick={() => toggleLogic(Paths.admin.PROJECTLIST)}
                  active={pathname === Paths.admin.PROJECTLIST}>
                  <SidebarMenu.Nav.Icon>1</SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    Listado de Proyectos
                  </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>
              {/*  */}
            </SidebarMenu.Nav>
          </SidebarMenu.Body>
        </SidebarMenu.Collapse>
      </SidebarMenu>
    </>
  );
};

export default AdminSidebar;

import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import './ConfirmRegister.css';
import {delay} from '../../../helpers/Delay';
import {redirect, useNavigate} from 'react-router-dom';
import Paths from '../../../constants/Paths';
import {faFloppyDisk} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {downloadFileLink} from '../../../helpers/ValidateFile';
import {toast} from 'react-toastify';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {
  invalidateConvocation,
  selectConvocation,
  selectProject,
  updateConvocation,
  updateProject,
} from '../../../redux/slice/inv.slice';
import {
  useCloseProjectMutation,
  useGetCurrentConvocationMutation,
  useGetUserCurrentProjectMutation,
} from '../../../redux/services/inv.api';
import {OutCloseProjectDto} from '../../../types/Dtos/InvDtos';
import {ServiceResponse} from '../../../types/Dtos/ServiceResponse';
import {getId, getToken, getUser, getUsu} from '../../../helpers/authTools';

//TODO: Still needs all the api work to be done

const ConfirmRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();

  const currentProject = useSelector(selectProject);
  const currentConvocation = useSelector(selectConvocation);

  const [getCurrentConvocation, {isLoading: isGetCurrentConvocation}] =
    useGetCurrentConvocationMutation();

  // TODO: Replace this loading with the api "isLoading" equivelant
  // And all the logic of fake loading that we have

  const isRegistrationActive =
    currentConvocation && !currentConvocation.hasRegistrationEnded;

  const [loadingFinalize, setLoadingFinalize] = useState(false);

  const [showCloseProject, setShowCloseProject] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);

  const [error, setError] = useState<string | undefined>(undefined);
  const [redirect, setRedirect] = useState<string | undefined>(undefined);

  const [waitingDownload, setWaitingDownload] = useState(false);

  const [getUserCurrentProject, {isLoading: isGetUserCurrentProjectLoading}] =
    useGetUserCurrentProjectMutation();

  const [closeProject, {isLoading: isCloseProjectLoading}] =
    useCloseProjectMutation();

  const handleCancelRegister = () => {
    if (!loadingFinalize) {
      setShowCloseProject(false);
      //navigate(Paths.investigator.HUB);
    }
  };

  const handleConfirmRegister = () => {
    setShowCloseProject(true);
  };

  const handleConfirmCloseProject = async () => {
    setLoadingFinalize(true);
    try {
      await handleCloseProject();
    } finally {
      setLoadingFinalize(false);
    }
  };

  const handleShowFinalize = () => {
    setShowFinalize(true);
  };

  const handleCloseFinalize = () => {
    setShowFinalize(false);
  };

  const handleFileDownload = async (url: string) => {
    setWaitingDownload(true);

    try {
      const user = getUser(store.getState());
      const id = getId(store.getState());
      const usu = getUsu(store.getState());
      const token = getToken(store.getState());

      await downloadFileLink(url, currentProject?.projectId ?? 0, {
        user,
        id,
        usu,
        token,
      });
    } catch (e) {
      toast.error('Error de descarga, intente más tarde');
    } finally {
      setWaitingDownload(false);
    }
  };

  const handleCloseProject = async () => {
    if (!currentProject) return;
    const responseData = await closeProject({
      projectId: currentProject.projectId,
    });
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    let resultError = undefined;
    if ('error' in responseData && 'data' in responseData.error) {
      resultError = responseData.error
        .data as ServiceResponse<OutCloseProjectDto>;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        //TODO: Success petition
        if (data.project) dispatch(updateProject(data.project));
      }
      setShowCloseProject(false);
      handleShowFinalize();
    } else {
      if (resultError?.message) {
        const message = resultError.message;
        toast.error(message);
        if (
          message ===
          'Ya no se pueden realizar cambios en el proyecto, la convocatoria ha finalizado'
        ) {
          dispatch(invalidateConvocation());
          await checkConvocation();
          await checkProject();
        }
      }

      if (resultError?.data) {
        const data = resultError?.data;
        setError(data.error);
        if (data.redirect) {
          switch (data.redirect) {
            case 'PROFILE':
              setRedirect(Paths.investigator.PROFILE);
              break;
            case 'REGISTERPROJECT':
              setRedirect(Paths.investigator.REGISTERPROJECT);
              break;
            case 'OBJECTIVES':
              setRedirect(Paths.investigator.OBJECTIVES);
              break;
            case 'INSTITUTESCOLLABS':
              setRedirect(Paths.investigator.INSTITUTESCOLLABS);
              break;
            case 'FINANCIALBREAKDOWN':
              setRedirect(Paths.investigator.FINANCIALBREAKDOWN);
              break;
            case 'PARTICIPATIONSTAGE':
              setRedirect(Paths.investigator.PARTICIPATIONSTAGE);
              break;
            case 'STUDENTSINFORMATION':
              setRedirect(Paths.investigator.STUDENTSINFORMATION);
              break;
            default:
              break;
          }
        }
      } else {
        toast.error('Error inesperado.');
      }
    }
  };

  const checkConvocation = async () => {
    if (currentConvocation) {
      return;
    }

    const responseData = await getCurrentConvocation(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateConvocation(data));
      }
    } else {
      toast.error(
        resultData?.message ?? 'Error al tratar de conseguir la convocacion.',
      );
    }
  };

  const checkProject = async () => {
    if (currentProject) {
      return;
    }

    const responseData = await getUserCurrentProject(undefined);
    let resultData = undefined;
    if ('data' in responseData) {
      resultData = responseData.data;
    }
    // TODO: CHECK FOR ERRORS, currently only checking for success
    if (resultData?.success) {
      if (resultData?.data) {
        const data = resultData?.data;
        dispatch(updateProject(data));
        return;
      }
    }
    toast.error('Proyecto no encontrado, favor de crear uno.');
    navigate(Paths.investigator.REGISTERPROJECT);
    return;
  };

  useEffect(() => {
    checkConvocation();
    checkProject();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        <div className="d-flex flex-column h-100 w-100 p-2">
          <div className="d-flex flex-row w-100 justify-content-center">
            <p className="text-center mb-3 title-text"> Finalizar Registro</p>
          </div>

          <Row>
            <p>
              Al <span className="boldSpan">Finalizar registro</span>, deberá
              descargar los siguientes documentos:
              <ul className="mt-3">
                <li>Solicitud de apoyo</li>
                <li>Desglose financiero </li>
                <li>Objetivos y actividades </li>
              </ul>
              Los cuales serán entregados en la Dirección de Investigación,
              según la fecha establecida en la convocatoria.
            </p>
          </Row>
          {!currentProject?.isClosed &&
            !isGetUserCurrentProjectLoading &&
            isRegistrationActive && (
              <>
                <Row className="mt-4">
                  <Col
                    xs={12}
                    className="d-flex flex-column align-center align-items-center">
                    <Button variant="primary" onClick={handleConfirmRegister}>
                      Finalizar registro
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          {currentProject?.isClosed && !isGetUserCurrentProjectLoading && (
            <Row className="mt-5">
              <Col
                xs={4}
                className="d-flex flex-column align-center align-items-center">
                <FontAwesomeIcon
                  className=""
                  icon={faDownload}
                  size="4x"
                  color={waitingDownload ? 'gray' : 'blue'}
                  cursor={waitingDownload ? '' : 'pointer'}
                  onClick={() => {
                    if (!waitingDownload)
                      handleFileDownload('api/report/ReportHelpRequest');
                  }}
                />
                <p>Solicitud de apoyo</p>
              </Col>
              <Col
                className="d-flex flex-column align-center align-items-center"
                xs={4}>
                <FontAwesomeIcon
                  icon={faDownload}
                  size="4x"
                  color={waitingDownload ? 'gray' : 'blue'}
                  cursor={waitingDownload ? '' : 'pointer'}
                  onClick={() => {
                    if (!waitingDownload)
                      handleFileDownload('api/report/ReportFinancialBreakdown');
                  }}
                />
                <p>Desglose financiero</p>
              </Col>
              <Col
                className="d-flex flex-column align-center align-items-center"
                xs={4}>
                <FontAwesomeIcon
                  icon={faDownload}
                  size="4x"
                  color={waitingDownload ? 'gray' : 'blue'}
                  cursor={waitingDownload ? '' : 'pointer'}
                  onClick={() => {
                    if (!waitingDownload)
                      handleFileDownload('api/report/ReportObjectives');
                  }}
                />
                <p>Objetivos y actividades</p>
              </Col>
            </Row>
          )}
        </div>
      </div>

      <Modal show={showCloseProject} onHide={handleCancelRegister}>
        <Modal.Body>
          Al finalizar el registro ya no se podrán realizar cambios. <br />
          ¿Desea Continuar? <br />
          {error && (
            <>
              <a
                href={redirect ?? undefined}
                className={
                  redirect ? 'pe-auto error-link' : 'pe-none error-link'
                }
                aria-disabled={redirect ? 'false' : 'true'}>
                {error}
              </a>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleCancelRegister}
            disabled={loadingFinalize}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleConfirmCloseProject}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFinalize} onHide={handleCloseFinalize}>
        <Modal.Header closeButton>
          <Modal.Title>Finalizado</Modal.Title>
        </Modal.Header>
        <Modal.Body>Su registro ha sido confirmado correctamente</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFinalize}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmRegister;

import React, {useState} from 'react';
import './InvestigatorHub.css';
import {Col, Container, Row} from 'react-bootstrap';

const InvestigatorHub = () => {
  const [loading, setLoading] = useState(false);
  // Here you can add things like a landing page, but for the investigators
  return (
    <>
      <div className="d-flex align-items-center h-100 w-100 flex-column justify-content-center p-4 pt-2">
        {!loading && (
          <Container fluid className="h-100 w-100 p-2">
            <Row xs={12} className="px-5">
              <Col xs={{span: 8, offset: 2}}>
                <div className="panel">
                  <span className="bold-text">¡Bienvenidos!</span>
                  <br />
                  <br />
                  <p id="texto-principal">
                    <span className="bold-text">
                      La Secretaría de Investigación Científica y Desarrollo
                      Tecnológico
                    </span>
                    , a través de la Dirección de Investigación, le da la
                    bienvenida a la nueva plataforma para registro de proyectos
                    a participar en la convocatoria
                    <span className="bold-text"> ProACTI 2024 </span>, en donde
                    encontrará herramientas y funciones que le permitan realizar
                    la inscripción de su proyecto de una manera rápida y
                    amigable.
                  </p>
                  <br />
                  <p>
                    Además de realizar el registro, podrá llevar un control
                    histórico de proyectos y etapas en las que haya participado,
                    así como el manejo de objetivos propios de su protocolo y
                    los propuestos en los lineamientos de la convocatoria.
                  </p>
                  <div id="firma">
                    <p className="atentamente">Atentamente</p>
                    <br />
                    <span>
                      Dr. Guillermo Elizondo Riojas <br />
                      Director de Investigación de la UANL.
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

export default InvestigatorHub;

import {User} from '../types/User';

export const validateFile = async ({
  file,
  type = 'application/pdf',
  size = 31457280,
  typeError = 'Solo se permiten archivos con formato PDF',
  sizeError = 'Este archivo supera los 30 MB!',
}: {
  file: File;
  type?: string;
  size?: number;
  typeError?: string;
  sizeError?: string;
}): Promise<ValidateFileResult> => {
  try {
    let result: ValidateFileResult = {};

    if (file.type !== type) {
      result = {error: typeError};
      return result;
    }

    if (file.size > size) {
      result = {error: sizeError};
      return result;
    }

    const textedFile: string = (await toBase64(file)) as string;

    result = {textFile: textedFile, fileName: file.name};

    return result;
  } catch (e) {
    const ex = (e as Error) ?? undefined;

    if (ex) {
      console.error('Ha ocurrido un problema, favor de intentarlo de nuevo.');
    }
    return {error: ex.message};
  }
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const toBlob = async (file: string) => {
  const base64Response = await fetch(`${file}`);
  const blob = await base64Response.blob();
  return blob;
};

export const toFile = (blob: Blob, name: string) => new File([blob], name);

export const downloadPDF = async (file: string, fileName = 'Document.pdf') => {
  const fileURL = window.URL.createObjectURL(await toBlob(file));
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = fileName;
  alink.click();
};

export const downloadFileLink = async (
  url: string,
  projectId: number,
  {
    user,
    id,
    usu,
    token,
  }: {user?: User; id?: number; usu?: string; token?: string},
) => {
  //TODO: header
  let myHeaders = new Headers();
  if (user && token) {
    myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      employeeNumber: user.employeeNumber,
      usu: usu ?? '',
      id: id ? id.toString() : '',
    });
  }

  const resp = await fetch(
    url +
      '?' +
      new URLSearchParams({
        projectId: projectId.toString(),
      }),
    {
      method: 'get',
      headers: myHeaders,
    },
  );

  const header = resp.headers.get('Content-Disposition');
  const parts = header?.split(';');

  const fileName = parts
    ? parts[1].split('=')[1].replaceAll('"', '')
    : undefined;
  const blob = await resp.blob();

  const fileURL = window.URL.createObjectURL(blob);
  const alink = document.createElement('a');
  alink.href = fileURL;
  if (fileName) alink.download = fileName;

  alink.click();
};

export type ValidateFileResult = {
  error?: string;
  textFile?: string;
  fileName?: string;
};
